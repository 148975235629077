
import API_URLS from '../../config/BackendConfig';
import { getToken } from '../../utils/tokenUtils'; // Updated import to get token from 
import { getRequest, postRequest } from '../../config/AxiosConfig';

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
  const token = getToken();
  // console.log('Token:', token);
  return token ? { Authorization: `Bearer ${token}` } : {};
};

//  Retrieve wallet Report Data 
export const WalletReportService = async (params) => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "wallet/report",
      headers: getAuthHeaders(),
      data: params
    });
    return response;
  } catch (error) {
    console.error('Error Fetch wallet Data :', error.response?.data || error.message);
    throw error;
  }
};




