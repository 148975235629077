import React, { useState } from 'react';
import './Notification.css';

const Notification = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const handleIconClick = (event) => {
    // const iconPosition = event.target.getBoundingClientRect();
    setPopupPosition({ x: -100, y: 45});
    setShowPopup(true);
  };

  const closePopup = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  return (
    
    <div onClick={handleIconClick} className="notification relative">
    <div className="bell-container">
        <div className="bell"></div>
    </div>
      {showPopup && (
        <div
          style={{
            position: 'absolute',
            top: popupPosition.y,
            left: popupPosition.x,
            backgroundColor: 'white',
            boxShadow:'0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07)',
            padding: '10px',
            zIndex: 9454599,
            borderRadius:'5px',
            width:'250px',
            transition: 'all 0.3s',
            animation: 'fadeIn 0.3s',
            margin:'0px 5px',
          }}
        >
          <div className="p-1 top_after_rotate">
            <div className="flex justify-between items-center my-2">
              <strong>5 Notification</strong>
              {/* <div  onClick={closePopup} className="close">X</div> */}
              <div   onClick={closePopup}
  className="flex cursor-pointer items-center justify-center text-3xl text-white caret-transparent relative"
>
  <div
    className="group relative inline-flex items-center justify-center overflow-hidden rounded-full border-2 font-medium shadow-md size-8"
  >
    <span
      className="ease absolute z-10 flex h-full w-full translate-y-full items-center justify-center rounded-full bg-[#ff1131] text-white duration-300 group-hover:translate-y-0"
    ></span>
    <div
      className="absolute z-50 flex h-full w-full items-center justify-center text-[#f6f2f2] group-hover:text-white"
    >
      <svg
        height="25px"
        width="25px"
        viewBox="0 0 72 72"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="black"
          d="M 19 15 C 17.977 15 16.951875 15.390875 16.171875 16.171875 C 14.609875 17.733875 14.609875 20.266125 16.171875 21.828125 L 30.34375 36 L 16.171875 50.171875 C 14.609875 51.733875 14.609875 54.266125 16.171875 55.828125 C 16.951875 56.608125 17.977 57 19 57 C 20.023 57 21.048125 56.609125 21.828125 55.828125 L 36 41.65625 L 50.171875 55.828125 C 51.731875 57.390125 54.267125 57.390125 55.828125 55.828125 C 57.391125 54.265125 57.391125 51.734875 55.828125 50.171875 L 41.65625 36 L 55.828125 21.828125 C 57.390125 20.266125 57.390125 17.733875 55.828125 16.171875 C 54.268125 14.610875 51.731875 14.609875 50.171875 16.171875 L 36 30.34375 L 21.828125 16.171875 C 21.048125 15.391875 20.023 15 19 15 z"
        ></path>
      </svg>
    </div>
  </div>
</div>

            </div>
            <hr />
            <ul style={{listStylePosition:'outside',paddingLeft:'20px'}} className='list-disc'>
              <li>2 New Order placed<br/>
              <span className='text-sm text-gray-500'>2s ago</span></li>
              <li>2 New Order placed<br/>
              <span className='text-sm text-gray-500'>2s ago</span></li>
              <li>2 New Order placed<br/>
              <span className='text-sm text-gray-500'>2s ago</span></li>
              <li>2 New Order placed<br/>
              <span className='text-sm text-gray-500'>2s ago</span></li>
              <li>2 New Order placed<br/>
              <span className='text-sm text-gray-500'>2s ago</span></li>
            </ul>

          </div>
        </div>
      )}
</div>
  );
};

export default Notification;