import React, { useState, useRef } from "react";
import Header from "../../Components/Header";
import { DivTag } from "../../Components/Tags/Tags";
import './Dis.css';

const VideoCard = ({ videos }) => {
  const [currentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  // Function to skip ahead/back in video
  const skipVideo = (seconds) => {
    videoRef.current.currentTime += seconds;
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full">
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        controls
        src={videos[currentVideoIndex].url} // Get video URL from the array
      ></video>
      <div className="p-1">
        <div className="flex justify-center space-x-1">
          {/* Skip controls */}
          <button
            className="bg-blue-500 text-white p-1 rounded text-xs"
            onClick={() => skipVideo(-10)}
          >
            Skip -10s
          </button>
          <button
            className="bg-blue-500 text-white p-1 rounded text-xs"
            onClick={() => skipVideo(10)}
          >
            Skip +10s
          </button>
        </div>
        <div className="mt-4">
          {/* <h2 className="text-lg font-bold">Description:</h2> */}
          <p>{videos[currentVideoIndex].description}</p>
        </div>
      </div>
    </div>
  );
};

const DiscriptionLink = () => {
  // Example data from backend (URL + Description)
  const videos = [
    { url: "https://www.w3schools.com/html/mov_bbb.mp4", description: "First video" },
    { url: "https://www.w3schools.com/html/movie.mp4", description: "Second video" },
    { url: "https://www.w3schools.com/html/movie.mp4", description: "Third video" },
    // Add more videos here from your backend
  ];

  return (
    <DivTag className="text-center">
      <Header heading='Training Sample' />

    <div className="min-h-screen bg-gray-100 p-2">
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-2">
        {/* Pass videos data to the VideoCard */}
        <VideoCard videos={videos} />
        <VideoCard videos={videos} />
        <VideoCard videos={videos} />
        <VideoCard videos={videos} />
        <style jsx>

        </style>
      </div>
    </div>
    </DivTag>
   
  );
};

export default DiscriptionLink;
