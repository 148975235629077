import React, { useState, useEffect, useCallback } from 'react';
import { DivTag, InputWithLabel, SelectWithLabelOption, Button } from '../../Components/Tags/Tags';
import FormValidation from "../../Components/FormValidation/FormValidation";
import Header from '../../Components/Header';
import Seo from '../../Components/SeoComponent/Seo';
import { WalletReportService } from '../../Services/WalletReport/walletReport';
import { FaInbox } from 'react-icons/fa';

const BusinessWalletStructure = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [walletData, setWalletData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 1,
    current_page: 1,
    last_page: 1,
  });

  const initialState = {
    from_date: "",
    to_date: "",
    total_data: "10",
    status: ""
  };

  const validate = () => {
    let errors = {};
    return errors;
  };

  const { values, errors, handleChange, handleBlur } = FormValidation(
    initialState,
    validate
  );

  const fetchWalletData = useCallback(async (page = 1) => {
    try {
      const params = {
        from_date: values.from_date,
        to_date: values.to_date,
        status: values.status,
        page,
        total_data: itemsPerPage,
      };
      const response = await WalletReportService(params);

      if (response.data && Array.isArray(response.data.data)) {
        setWalletData(response.data.data);
        setPagination(response.pagination || {});
      } else {
        console.error("Data format error:", response.data.data);
        setWalletData([]);
        setPagination({});
      }
    } catch (error) {
      console.error("Error fetching Wallet Report data:", error);
      setWalletData([]);
      setPagination({});
    }
  }, [values, itemsPerPage]);

  useEffect(() => {
    fetchWalletData(currentPage);
  }, [fetchWalletData, currentPage]);

  const handleSearchClick = () => {
    setCurrentPage(1);
    fetchWalletData(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== currentPage) setCurrentPage(newPage);
  };

  return (
    <DivTag className="container mx-auto">
      <Seo />
      <Header heading='Wallet Report' />
      <DivTag className="flex sm:justify-center flex-wrap gap-4">
        <InputWithLabel
          DivclassName="w-full md:w-1/6 px-2"
          label="From Date"
          type="date"
          name="from_date"
          id="from_date"
          value={values.from_date}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`border rounded-md py-2 px-3 ${errors.from_date ? "border-red-500" : "border-blue-600"}`}
          error={errors.from_date}
        />
        <InputWithLabel
          DivclassName="w-full md:w-1/6 px-2"
          label="To Date"
          type="date"
          name="to_date"
          id="to_date"
          value={values.to_date}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`border rounded-md py-2 px-3 ${errors.to_date ? "border-red-500" : "border-blue-600"}`}
          error={errors.to_date}
        />
        <SelectWithLabelOption
          DivclassName="w-full md:w-1/4 px-2"
          label="Records Per Page"
          name="total_data"
          id="total_data"
          value={values.total_data}
          onChange={(e) => {
            handleChange(e);
            setItemsPerPage(Number(e.target.value));
          }}
          className="border rounded-md py-2 px-3 border-blue-600"
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </SelectWithLabelOption>

        <DivTag className="flex justify-center mt-1">
          <Button
            type="button"
            onClick={handleSearchClick}
            className="bg-gradient-to-r from-cyan-300 to-blue-900 text-white px-2 py-1 rounded disabled:opacity-50 border-sky-300 !h-8 !mt-4 shadow-lg shadow-blue-500/50"
          >
            Search
          </Button>
        </DivTag>
      </DivTag>

      {walletData && walletData.length > 0 ? (
        <div className="overflow-x-auto my-1 p-2">
          <table id="table" className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-blue-700 text-white">
                <th className="border-2 p-2">SNo.</th>
                <th className="border-2">User ID</th>
                <th className="border-2">Name</th>
                <th className="border-2">Amount</th>
                <th className="border-2">Type</th>
                <th className="border-2">Available Balance</th>
                <th className="border-2">Remarks</th>
                <th className="border-2">Date</th>
                <th className="border-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {walletData?.map((item, index) => (
                <tr key={(currentPage - 1) * itemsPerPage + index + 1} className="text-center">
                  <td className="border shadow-inner shadow-blue-600 p-1">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.user_id}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.user.name}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.amount}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.factor}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.user.balance}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.remarks}</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">{item?.transaction_date}</td>
                  <td className='border shadow-inner shadow-blue-400 p-1'>
                  <button
                   className={`px-1 py-1 text-white rounded ${
                   item.status.value === 'approved'
                   ? 'bg-green-600 first-letter:uppercase text-xs'
                    : item?.status?.value === 'pending'
                     ? 'bg-yellow-500 first-letter:uppercase text-xs'
                     : 'bg-red-500 first-letter:uppercase text-xs'
                        }`}
                       >
                     {item?.status?.value}
                      </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='flex items-center mt-4 gap-4 text-xl text-neutral-600 justify-center'>
          <FaInbox className='text-blue-700' />
          <p className='flex justify-center items-center'>Data not found</p>
        </div>
      )}
      
      <DivTag className="flex p-4 justify-center mt-4">
        <Button
          type="button"
          className="bg-blue-700 text-white rounded px-2 py-1 mr-2"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="text-lg">
          {currentPage} / {pagination.last_page || 1}
        </span>
        <Button
          type="button"
          className="bg-blue-700 text-white rounded px-2 py-1 ml-2"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === pagination.last_page}
        >
          Next
        </Button>
      </DivTag>
    </DivTag>
  );
};

export default BusinessWalletStructure;
