import React, {useState} from "react";
import { updatePin } from "../Services/profile/profileservice";
import 'react-toastify/dist/ReactToastify.css';
import {
    showSuccessToast,
    showErrorToast,
  } from "../utils/toastUtils";

export default function ChangePin(props) {
  const { showPinModal, setShowPinModal } = props;
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newConfirm = () => {
    const pinRegex = /^\d{6}$/;
    setError('');
  
    // const newPin = document.getElementById('new_pin').value;
    // const confirmPin = document.getElementById('confirm_pin').value;

    if (!newPin) {
      setError("New password is required");
      return false;
    }
    if (!pinRegex.test(newPin)) {
      setError("New PIN must be exactly 6 digits and contain only numbers");
      return false;
    }
    if (newPin !== confirmPin) {
      setError("New PIN and confirm PIN must match");
      return false;
    }
    return true;
  }

  const handleSaveChanges = async () => {
    if (!newConfirm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await updatePin({
        current_pin: oldPin,
        new_pin: newPin,
        new_pin_confirmation: confirmPin
      });

      if (response.status === "success") {
        // alert(response.message);
        setOldPin('');
        setNewPin('');
        setConfirmPin('');
        setShowPinModal(false);
        setIsSubmitting(false);
        showSuccessToast("Pin has been Changed");
        return

      } else {
        setIsSubmitting(false);
        showErrorToast('Failed to update pin. Please try again.');
        return
      }
    } catch (error) {
      setIsSubmitting(false);
      showErrorToast('Failed to update pin. Please try again.');
      return
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {showPinModal && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-96 max-w-96">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Change PIN
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowPinModal(false)}>
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-col">
                    <label htmlFor="old_pin" className="flex">Enter Old PIN<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-star-fill" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg></label>
                    <input maxLength={6}
                      minLength={6}
                      type="password"
                      id="old_pin" 
                      name="old_pin"
                       placeholder="Enter Old PIN"
                        className="w-full py-1 px-2 border-gray-500 rounded border outline-none min-w-80" 
                        autoComplete="off"
                        value={oldPin} 
                        onChange={(e) => setOldPin(e.target.value)}
                        />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="new_pin" className="flex">Enter New PIN<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-star-fill" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg></label>
                    <input
                      maxLength={6} 
                      minLength={6}
                       type="password"
                        id="new_pin"
                        name="new_pin"
                         placeholder="Enter New PIN"
                          className="w-full py-1 px-2 border-gray-500 rounded border outline-none min-w-80"
                          autoComplete="off"
                          onKeyUp={newConfirm}
                          value={newPin}
                          onChange={(e) => setNewPin(e.target.value)}
                          />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="confirm_pin" className="flex">Enter Confirm PIN<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-star-fill" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg></label>
                    <input 
                    maxLength={6} 
                    minLength={6} 
                    type="text"
                     id="confirm_pin" 
                     name="confirm_pin"
                      placeholder="Enter Confirm PIN"
                       className="w-full py-1 px-2 border-gray-500 rounded border outline-none min-w-80" 
                       
                       value={confirmPin}
                       onChange={(e) => setConfirmPin(e.target.value)}
                       onKeyUp={newConfirm} 
                       autoComplete="off" />
                  </div>
                  {error && (
                    <div className="text-red-500 mt-2" id="error_new_confirm">
                      {error}
                    </div>
                  )}
                </div>
                <p id="error_new_confirm_pin" className='text-red-500 px-3'></p>
                <div className="flex items-center justify-end p-6 border-solid border-blueGray-200 rounded-b">
                <button
                    className={`px-4 py-2 bg-blue-500 text-white rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleSaveChanges}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                  </button>
                  <button 
                    className="px-4 py-2 bg-red-500 text-white rounded ml-2"
                    type="button"
                    onClick={() => setShowPinModal(false)}
                   >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}