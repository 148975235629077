import React from 'react'
import { LuEyeOff } from "react-icons/lu";

const AnimateCard = ({title,text,buttonview,checkbal,text2}) => {
  return (
    <>
	<div class="w-full group overflow-hidden bg-black rounded-xl bg-gradient-to-tr from-blue-500 via-blue-900 to-blue-700 text-gray-50 relative">
  <div class="before:duration-700 before:absolute before:w-28 before:h-28 before:bg-transparent before:blur-none before:border-8 before:opacity-50 before:rounded-full before:-left-4 before:-top-12 w-full h-48 flex flex-col justify-between relative z-10 group-hover:before:top-28 group-hover:before:left-44 group-hover:before:scale-125 group-hover:before:blur">
    <div class="p-3 flex flex-col justify-evenly">
      <span class="font-bold text-xl">{title}</span>
      <p class="subtitle mt-2 text-center text-xl">{text}</p>
      <p class="subtitle mt-2 text-center text-xl">{text2}</p>
    </div>
    <div class="w-full flex flex-row justify-between z-10 p-2">
        <div className='flex items-center cursor-pointer border-b font-semibold shadow-md'>
        <LuEyeOff />
        <button className='rounded-md p-1 text-white hover:text-white text-sm'>{buttonview}</button>
        </div>
        <div className='flex items-center cursor-pointer border-b font-semibold shadow-md'>
        <LuEyeOff />
        <button className=' rounded-md p-1 text-white hover:text-white text-sm' >{checkbal}</button>
     </div>
    </div>
  </div>
</div>

    </>
  )
}

export default AnimateCard
