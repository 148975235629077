import React, { useState } from "react";
import { updatePassword } from "../Services/profile/profileservice";
import 'react-toastify/dist/ReactToastify.css';
import {
  showSuccessToast,
  showErrorToast,
} from "../utils/toastUtils";


export default function Popup(props) {
  const { showModal, setShowModal } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newConfirm = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    setError('');

    if (!newPassword) {
      setError("New password is required");
      return false;
    }
    if (!passwordRegex.test(newPassword)) {
      setError("New password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password must match");
      return false;
    }
    return true;
    
  }
  
  const handleSaveChanges = async () => {
    if (!newConfirm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await updatePassword({
        current_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword
      });

      if (response.status === "success") {
        // alert(response.message);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setShowModal(false);
        setIsSubmitting(false);
        showSuccessToast("Password has been Changed");
        return
        
      } else {
        setIsSubmitting(false);
        showErrorToast('Failed to update password. Please try again.');
        return
      }
    } catch (error) {
      setIsSubmitting(false);
      showErrorToast('Failed to update password. Please try again.');
      return
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-96 max-w-96">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Change Password</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-col">
                    <label htmlFor="old_password" className="flex">
                      Enter Old Password
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                      </svg>
                    </label>
                    <input
                      type="password"
                      id="old_password"
                      name="old_password"
                      placeholder="Enter Old Password"
                      className="w-full py-1 px-2 border-gray-500 rounded border outline-none min-w-80"
                      autoComplete="off"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col mt-2">
                    <label htmlFor="new_password" className="flex">
                      Enter New Password
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                      </svg>
                    </label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      placeholder="Enter New Password"
                      className="w-full py-1 px-2 border-gray-500 rounded border outline-none"
                      autoComplete="off"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      onKeyUp={newConfirm}
                    />
                  </div>
                  <div className="flex flex-col mt-2">
                    <label htmlFor="confirm_password" className="flex">
                      Enter Confirm Password
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-star-fill" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                      </svg>
                    </label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Enter Confirm Password"
                      className="w-full py-1 px-2 border-gray-500 rounded border outline-none"
                      autoComplete="off"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onKeyUp={newConfirm}
                    />
                  </div>
                  {error && (
                    <div className="text-red-500 mt-2" id="error_new_confirm">
                      {error}
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className={`px-4 py-2 bg-blue-500 text-white rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleSaveChanges}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded ml-2"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
