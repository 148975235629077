import axios from 'axios';
import API_URLS from './BackendConfig';

// Helper function to create an Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: API_URLS.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Function for making POST requests
export const postRequest = async ({ url, data, headers }) => {
  try {
    const response = await axiosInstance.post(url, data, {
      headers: headers || {},  // Use an empty object if no headers are provided
    });
    // console.log(response)
    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};


// Function for making GET requests
export const getRequest = async ({ url, headers }) => {
  try {
    const response = await axiosInstance.get(url, {
      headers: headers || {},  // Use an empty object if no headers are provided
    });
    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

// Function for making PUT requests
export const putRequest = async ({ url, data, headers }) => {
  try {
    const response = await axiosInstance.put(url, data, {
      headers: headers || {},  // Use an empty object if no headers are provided
    });
    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

// Function for making DELETE requests
export const deleteRequest = async ({ url, headers }) => {
  try {
    const response = await axiosInstance.delete(url, {
      headers: headers || {},  // Use an empty object if no headers are provided
    });
    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

const handleRequestError = (error) => {
  if (error.response) {   
    return {
      success: false,
      status: error.response.status,
      data: error.response.data,
    };
  } else if (error.request) {
    return {
      success: false,
      status: null,
      data: { message: 'No response received from server' },
    };
  } else {
    return {
      success: false,
      status: null,
      data: { message: error.message },
    };
  }
};
