import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import './Profile.css';
import ChangePasswordPopup from '../../Components/ChangePasswordPopup';
import ChangePinPopup from '../../Components/ChangePinPopup';
import SimplePopup from '../../Components/SimplePopup';
import Header from '../../Components/Header';
import { fetchProfile, profileImgPreview, updatePassword, updatePin, retriveLoginActivityService } from '../../Services/profile/profileservice';
import Seo from '../../Components/SeoComponent/Seo';
import { FaInbox } from 'react-icons/fa';

export default function Profile() {
  const [profile, setProfile] = useState({});
  const [isChecked, setIsChecked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showSamplePopup1, setshowSamplePopup1] = useState(false);
  const [showSamplePopup2, setshowSamplePopup2] = useState(false);
  const [showSamplePopup3, setshowSamplePopup3] = useState(false);
  const [showSamplePopup4, setshowSamplePopup4] = useState(false);
  const [profileImg, setProfileImg] = useState('');
  const [loginActivityData, setLoginActivityData] = useState([]);
  const [filename, setFilename]  = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const loadProfile = async () => {
  //     try {
  //       const data = await fetchProfile();
    
  //       setLoading(false);
  //       setFilename(data.profile);
  //       if (data && (data.company_details || profile)) {
  //         setProfile(data.data);
  //       } else {
  //         console.error("Company details are missing in the profile data");
  //       }
  //     } catch (error) {
  //       console.error('Failed to load profile:', error);
  //     }
  //   };
  
  //   loadProfile();
  // }, []);



  useEffect(() => {
    const loadProfile = async () => {
      setLoading(true); // Set loading true at the start of the request
      try {
        const data = await fetchProfile();
        
        // Handle default values if certain fields are missing or null
        const profileData = {
          ...data.data, // Ensure profile data is spread
          companyType: data.data?.companyType || "Default Company Type", // Fallback value
          // Add other fields with fallback values if needed
        };

        // Check if profile and company_details are valid
        if (data && (data.company_details || profileData)) {
          setProfile(profileData);
          setFilename(data.profile || "Default Filename"); // Handle null profile filename
        } else {
          console.error("Company details are missing in the profile data");
          setError("Company details are missing in the profile data");
        }
      } catch (error) {
        console.error("Failed to load profile:", error.message);
        setError('Failed to load profile');
      } finally {
        setLoading(false); // Set loading false when the request is finished
      }
    };

    loadProfile();
  }, []);




  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleUpdatePassword = async (newPassword) => {
    try {
      await updatePassword(newPassword);
      setShowModal(false);
    } catch (error) {
      console.error('Failed to update password:', error);
    }
  };

  const handleUpdatePin = async (newPin) => {
    try {
      
      console.log(newPin);
      await updatePin(newPin);
      setShowPinModal(false);
    } catch (error) {
      console.error('Failed to update PIN:', error);
    }
  };

 //Preview the image on component load
 useEffect(() => {
  const PreviewImg = async () => {
    // if (!filename) {
    //   console.warn('Filename is undefined or empty');
    //   return;
    // }
    try {
      const response = await profileImgPreview({ filename });
      setProfileImg(response.dataUri); 
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };
  PreviewImg();  
}, [filename]);
  //ENd



  // Retrive Login Activity Data Function
  const RetriveLoginActivityData = async () => {
    try {
      const response = await retriveLoginActivityService();
      // console.log("LoginData",response);
      setLoginActivityData(response.data.data);
    } catch (error) {
      console.error("Error fetching Login data:", error);
    } 
  };

  useEffect(() => {
    RetriveLoginActivityData();
  }, []);
  // ENd


  // Date Time Formate Show
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  // ENd


  return (
    <>
     <Seo />
      <ChangePasswordPopup 
        showModal={showModal} 
        setShowModal={setShowModal} 
        onUpdatePassword={handleUpdatePassword} 
      />
      <ChangePinPopup 
        showPinModal={showPinModal} 
        setShowPinModal={setShowPinModal} 
        onUpdatePin={handleUpdatePin} 
      />

      <Header heading='Profile' />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-3">
        <div className="standard-box-shadow rounded-md p-5">
          <div className="flex flex-col">
          
          {loading ? (
        <p className='text-black'>Loading...</p>
      ) : (
        <>
          <img className="w-40 rounded-full h-40 m-auto text-black" src={profileImg} alt="Profile" />
          {!profileImg && <p className='text-black'>Loading....</p>}
        </>
      )}

            <h1 className="text-center  mt-2 font-bold text-black">{profile.name}</h1>
            <p className="text-center text-gray-500 font-medium">Pan&nbsp;&nbsp;:&nbsp;&nbsp;{profile.pan_number}</p>
            <p className="text-center text-gray-500 font-medium">Aadhar&nbsp;&nbsp;:&nbsp;&nbsp;{profile.aadhar_number}</p>
            <p className="text-center text-gray-500 font-medium">Email&nbsp;&nbsp;:&nbsp;&nbsp;{profile.email_address}</p>
            <hr className="my-2" />
            <div className="grid grid-cols-5">
              <p className="col-span-2 text-gray-400 font-bold">Joining Date</p>
              <p className="col-span-3 text-gray-600">:&nbsp;&nbsp;{moment(profile.doc).format('YYYY-MM-DD')}</p>
              <p className="col-span-2 text-gray-400 font-bold">Updated Date</p>
              <p className="col-span-3 text-gray-600">:&nbsp;&nbsp;{moment(profile.updated_at).format('YYYY-MM-DD')}</p>
              <p className="text-gray-400 font-bold">Address</p>
              <p className="col-span-4 text-gray-600">:&nbsp;&nbsp;{profile.landmark_address}</p>
            </div>
            <hr className="my-2" />
            <h1 className="text-gray-400 font-bold">KYC Details</h1>
            <div className="flex gap-5">
              <span onClick={() => setshowSamplePopup1(true)} className="text-gray-700 underline cursor-pointer">Pan</span>
              <span onClick={() => setshowSamplePopup2(true)} className="text-gray-700 underline cursor-pointer">Aadhar</span>
              <span onClick={() => setshowSamplePopup3(true)} className="text-gray-700 underline cursor-pointer">Photo</span>
              <span onClick={() => setshowSamplePopup4(true)} className="text-gray-700 underline cursor-pointer">Bank Cheque</span>
            </div>
          </div>
        </div>

        {profile && profile.company_details && profile.company_details.company_type &&   (
          <div className="col-span-1 md:col-span-2 standard-box-shadow rounded-md p-5">
            <h1 className="font-bold text-xl text-gray-500">Company Name:  -  {profile.company_details.company_name}</h1>
            <hr className="my-2" />
            <div className="grid grid-cols-1 sm:grid-cols-4 justify-center items-center">
              <p className="text-xl font-bold text-gray-500">CIN</p>
              <p className="col-span-3 text-gray-600">:&nbsp;&nbsp;{profile.company_details.company_cin}</p>
              <p className="text-xl font-bold text-gray-500">GST No</p>
              <p className="col-span-3 text-gray-600">:&nbsp;&nbsp;{profile.company_details.company_gst_no}</p>
              <p className="text-xl font-bold text-gray-500">Company Category</p>
              <p className="col-span-3 text-gray-600">:&nbsp;&nbsp;{profile.company_details.company_type.name}</p>
              <p className="text-xl font-bold text-gray-500">Company Address</p>
              <p className="col-span-3 text-gray-600">:&nbsp;&nbsp;{profile.company_details.company_address}</p>
            </div>
            <hr className="my-2" />
            <div className="flex flex-col gap-3 mt-8 items-between justify-center">
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-xl font-bold text-gray-600">Login Password</h1>
                  <p className='italic'>{moment(profile.updated_at).format('YYYY-MM-DD')}</p>
                </div>
                <button onClick={() => setShowModal(true)} className="btn py-1 px-3 border transition-all rounded hover:bg-blue-400 hover:text-white border-blue-400">Change</button>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-xl font-bold text-gray-600">iPIN</h1>
                  <p className='italic'>{moment(profile.updated_at).format('YYYY-MM-DD')}</p>
                </div>
                <button onClick={() => setShowPinModal(true)} className="btn py-1 px-3 border transition-all rounded hover:bg-blue-400 hover:text-white border-blue-400">Change</button>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-xl font-bold text-gray-600">Lock Screen</h1>
                  <p className='italic'>Never Changed</p>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" checked={isChecked} onChange={handleCheckboxChange} />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      {loginActivityData && loginActivityData.length > 0 ? (
      
<div className="overflow-x-auto my-1 p-2">
<table id="table" className="min-w-full divide-y divide-gray-200">
<thead className='text-lg text-white uppercase  dark:bg-gray-700 dark:text-gray-400'>
     <tr>
      <th scope="col" className="px-2 py-1">Activity ID</th>
      <th scope="col" className="px-2 py-1">Timestamp</th>
      <th scope="col" className="px-2 py-1">Activity</th>
      <th scope="col" className="px-2 py-1">Device</th>
      <th scope="col" className="px-2 py-1">Source IP</th>
     </tr>
  </thead>
   <tbody className='text-center two_tbody'>
    {loginActivityData.map((item, index) => (
      <tr key={index} className='border border-b'>
        <td className='px-2 py-1 font-medium border '>{index + 1}</td>
        <td className='px-2 py-1 font-medium border '> {formatDate(item.updated_at)}</td>
        <td className='px-2 py-1 font-medium border '>{item.loggedin}</td>
        <td className='px-2 py-1 font-medium border '>{item.device_id}</td>
        <td className='px-2 py-1 font-medium border '>{item.ip_address}</td>
      </tr>
    ))}
  </tbody>
                </table>
               </div>
              ) : (
                <div className='flex items-center mt-4 gap-4 text-xl text-neutral-600 justify-center'>
                  <FaInbox className='text-blue-700' />
                  <p className='flex justify-center items-center'>Data not found</p>
                </div>
              )}

       <SimplePopup  
       showSamplePopup={showSamplePopup1} 
        setshowSamplePopup={setshowSamplePopup1}
       title="Pan"
         body={<img className='mx-auto' src={profile.pan_image} alt='pan' />}
       />
       <SimplePopup
        showSamplePopup={showSamplePopup2}
        setshowSamplePopup={setshowSamplePopup2}
        title="Adhar"
        body={<img className='mx-auto' src={profile.aadhar_image} alt='adhar' />}
      />
      <SimplePopup
        showSamplePopup={showSamplePopup3}
        setshowSamplePopup={setshowSamplePopup3}
        title="Photo"
        body={<img className='mx-auto' src={profile.image} alt='user pic' />}/>
      <SimplePopup
        showSamplePopup={showSamplePopup4}  
        setshowSamplePopup={setshowSamplePopup4}
        title="Bank Cheque"
        body={<img className='mx-auto' src='' alt='bank cheque' />}
      />
    </>
  );
}

