import React, { useState } from 'react';

const ReportModal = ({ isOpen, onClose,equifaxResponseData }) => {
  const [isPrinting, setIsPrinting] = useState(false);

  if (!isOpen) return null; // Do not render anything if not open

  const printDiv = () => {
    setIsPrinting(true);
    
    // Create a temporary window for printing
    const printWindow = window.open('', '', 'height=600,width=800');
    
    const printContents = document.getElementById("invoicedata").innerHTML;

    // Write the content to the new window
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">');
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    
    printWindow.document.close(); // Close the document for rendering
    printWindow.focus(); // Focus on the new window
    
    setTimeout(() => {
        printWindow.print(); // Trigger print
       // printWindow.close(); // Close the print window after printing
        
        // Close the modal after 3 seconds
       // onClose();
        setIsPrinting(false);
    }, 100); // Slight delay before printing to ensure the content is rendered
};


  const getPaymentStatusColor = (status) => {
      switch (status) {
          case "000":
              return "bg-green-500"; // Green for success
          case "*":
              return "bg-red-500";   // Red for failure
          case "NEW":
              return "bg-yellow-500"; // Yellow for new
          default:
              return "bg-gray-500";  // Default for other cases
      }
  };
    return (
        <div className="fixed inset-0 flex  flex-col items-center justify-center z-50 backdrop-blur-sm bg-white bg-opacity-50 md:p-0 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full md:max-w-3xl overflow-y-auto max-h-[100vh]" id="invoicedata">
                <div className="a4-page">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                        <div className="col-span-2">
                            <img 
                                src="https://imgs.search.brave.com/6iV5QNxEpERp5OAG16hROi1fGXw0TI7pe8Po1HzXnjI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9kMXB0/NncybXQyeHFzby5j/bG91ZGZyb250Lm5l/dC9BY3VDdXN0b20v/U2l0ZW5hbWUvREFN/LzAxOS9FcXVpZmF4/LUxvZ28uanBn"
                                className="w-20"
                                alt="Company Logo"
                            />
                        </div>
                        <div className="col-span-8 md:text-center text-center">
                            <h4 className="text-2xl font-bold">Credit Report</h4>
                        </div>
                        <div className="col-span-2 md:text-end text-end">
                        Consumer Credit Score: <strong className="text-xl">
                        {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.ScoreDetails[0]?.Value}
                        </strong>
                        </div>
                    </div>

                    <div className="flex justify-between items-start flex-wrap">
  {/* Start section */}
  <div className="">
    <p><i>Client ID:</i> <span className="client_id">{equifaxResponseData?.InquiryResponseHeader?.ClientID}</span></p>
    <p><i>Report Order No:</i> <span className="report_order_no">{equifaxResponseData?.InquiryResponseHeader?.ReportOrderNO}</span></p>
  </div>

  {/* End section */}
  <div className="text-right">
    <p><i>Date:</i> <span className="report_date">{equifaxResponseData?.InquiryResponseHeader?.Date}</span></p>
    <p><i>Time:</i> <span className="report_time">{equifaxResponseData?.InquiryResponseHeader?.Time}</span></p>
  </div>
</div>

<div className='flex justify-center'>
        <p className='font-bold text-white'>Personal Details</p>
    </div>
<div className="border border-blue-500 p-2 rounded-md">
   
  <div className="grid grid-cols-2 md:grid-cols-2  gap-1">
    {/* Consumer Name */}
    <div>
      <p><i>Consumer Name</i><span> : {equifaxResponseData?.InquiryRequestInfo?.FirstName}</span></p>
    </div>
    <div>
      <p><i>D.o.b</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.InquiryRequestInfo?.DOB}</span></p>
    </div>
    <div>
      <p><i>Age</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.PersonalInfo?.Age?.Age}</span></p>
    </div>
    <div>
      <p><i>Gender</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.PersonalInfo?.Gender}</span></p>
    </div>
    <div>
      <p><i>Mob</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.InquiryRequestInfo?.InquiryPhones[0]?.Number}</span></p>
    </div>
  </div>
</div>

                    <hr className="my-2" />
                    <div className="  bg-blue-800 md:text-white text-white p-2 mt-2">Address</div>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-2 py-1">Sn</th>
      <th className="border border-black px-2 py-1">Address</th>
    </tr>
  </thead>
  <tbody className="address-info">
  {Array.isArray(equifaxResponseData?.CCRResponse?.CIRReportDataLst) && equifaxResponseData.CCRResponse.CIRReportDataLst.length > 0 ? (
  equifaxResponseData.CCRResponse.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.AddressInfo?.map((address, index) => (
    <tr key={address.Seq}>
      <td className="border border-black px-2 py-1 font-bold">Address: {address.Seq}</td>
      <td className="border border-black px-2 py-1">{address.Address}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="2" className="border border-black px-2 py-1 text-center">
      No address information available.
    </td>
  </tr>
)}

  </tbody>
</table>

                       {/* Credit Score Section */}
                    <div className=" bg-blue-800 md:text-white p-2 text-white mt-2">Equifax Score</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr className='text-left'>
      <th className="border border-black px-2 py-1">Score Name</th>
      <th className="border border-black px-2 py-1">Score</th>
      <th className="border border-black px-2 py-1">Scoring Elements</th>
    </tr>
  </thead>
  <tbody className="scoreDetailsInfo">
  {Array.isArray(equifaxResponseData?.CCRResponse?.CIRReportDataLst) && equifaxResponseData.CCRResponse.CIRReportDataLst.length > 0 && Array.isArray(equifaxResponseData.CCRResponse.CIRReportDataLst[0]?.CIRReportData?.ScoreDetails) ? (
  equifaxResponseData.CCRResponse.CIRReportDataLst[0].CIRReportData.ScoreDetails.map((scoreDetail, index) => (
    <tr key={index} className=''>
      <td className="border border-black px-2 py-1">{scoreDetail.Name}</td>
      <td className="border border-black px-2 py-1">{scoreDetail.Value}</td>
      <td className="border border-black px-2 py-1">
        <ul>
          {Array.isArray(scoreDetail.ScoringElements) ? (
            scoreDetail.ScoringElements.map((element, idx) => (
              <li key={idx}>
                <p>{element.Description}</p>
              </li>
            ))
          ) : (
            <li>No scoring elements available.</li>
          )}
        </ul>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="3" className="border border-black px-2 py-1 text-center">
      No score details available.
    </td>
  </tr>
)}

  </tbody>
</table>
                         {/* Accounts Section */}
                    <div className=" bg-blue-800 md:text-white text-white p-2 mt-4 rounded-tl-xl rounded-tr-xl">Account Details</div>
                    <table className="min-w-full table-auto text-left mt-2 border-collapse">
                    <tbody className="mb-3">
  {Array.isArray(equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountDetails) && equifaxResponseData.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.length > 0 ? (
  equifaxResponseData.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((account, index) => (
    <React.Fragment key={index}>
      <tr className="bg-blue-800 md:text-white text-white">
        <td colSpan={6} className="px-2 py-1 border rounded-tr-md rounded-tl-md">
          RETAIL : {account.AccountType}
        </td>
      </tr>

      {/* This row will have borders around it */}
      <tr className="border">
        <td colSpan={6} className="px-2 py-1 border ">
          <div className="grid grid-cols-2 gap-2">
            <div><span className="font-semibold">Sanction Amount :</span> {account.SanctionAmount}</div>
            <div><span className="font-semibold">Balance :</span> {account.Balance}</div>
            <div><span className="font-semibold">OverDue :</span> {account.PastDueAmount}</div>
            <div><span className="font-semibold">Account Status :</span> {account.AccountStatus}</div>
            <div><span className="font-semibold">Date Opened :</span> {account.DateOpened}</div>
          </div>
        </td>
      </tr>

      {/* Additional rows with border */}
      <tr>
        <td colSpan={6} className="px-2 py-1 border">
          <div className="grid grid-cols-2 gap-2">
            <div><span className="font-semibold">Account Number:</span> {account?.AccountNumber}</div>
            <div><span className="font-semibold">Institution:</span> {account?.Institution}</div>
            <div><span className="font-semibold">Account Type:</span> {account?.AccountType}</div>
            <div><span className="font-semibold">Ownership Type:</span> {account?.OwnershipType}</div>
            <div><span className="font-semibold">Balance:</span> {account?.Balance}</div>
            <div><span className="font-semibold">Open:</span> {account?.Open}</div>
            <div><span className="font-semibold">Sanction Amount:</span> {account?.SanctionAmount}</div>
            <div><span className="font-semibold">Source:</span> {account?.source}</div>
            <div><span className="font-semibold">Interest Rate:</span> {account?.InterestRate}</div>
            <div><span className="font-semibold">Repayment Tenure:</span> {account?.RepaymentTenure}</div>
            <div><span className="font-semibold">Installment Amount:</span> {account?.InstallmentAmount}</div>
            <div><span className="font-semibold">Term Frequency:</span> {account?.TermFrequency}</div>
            <div><span className="font-semibold">Date Reported:</span> {account?.DateReported}</div>
            <div><span className="font-semibold">Date Opened:</span> {account?.DateOpened}</div>
            <div><span className="font-semibold">Dispute Code:</span> N/A</div>
            <div><span className="font-semibold">Account Status:</span> {account?.AccountStatus}</div>
            <div><span className="font-semibold">Asset Classification:</span> {account?.AssetClassification}</div>
          </div>
        </td>
      </tr>

      <tr className='my-2'>
        <td colSpan={6} className="px-1 py-0 border border-t-none">
          <h2 className="font-semibold">Payment history</h2>
          <div className="flex flex-wrap mb-4 mt-2">
            {Array.isArray(account.History48Months) && account.History48Months.length > 0 ? (
              account.History48Months.map((history, idx) => (
                <div key={idx} className="mx-1">
                  <span className={`flex items-center justify-center w-6 h-6 text-white text-xs rounded-full ${getPaymentStatusColor(history.PaymentStatus)}`}>
                    <p className='mt-[-12px]'>{idx + 1}</p>
                  </span>
                </div>
              ))
            ) : (
              <div>No payment history available.</div>
            )}
          </div>
        </td>
      </tr>
    </React.Fragment>
  ))
) : (
  <tr>
    <td colSpan={6} className="px-2 py-1 text-center border">
      No retail account details available.
    </td>
  </tr>
)}

  </tbody>
</table>

    <div className="flex flex-col space-y-2 p-4">
      <h2 className='font-bold'>Payment Status Legend</h2>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-green-500 mr-2"></div>
    <span>Current Account (000)</span>
  </div>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-yellow-500 mr-2"></div>
    <span>1-29/30-59 days past due (01+, 30+)</span>
  </div>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-orange-500 mr-2"></div>
    <span>60-89 days past due (60+)</span>
  </div>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-red-500 mr-2"></div>
    <span>90+ days past due (90+, 120+)</span>
  </div>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-gray-500 mr-2"></div>
    <span>Closed Account (CLSD)</span>
  </div>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-blue-500 mr-2"></div>
    <span>New Account (NEW), Settled (SET)</span>
  </div>
  <div className="flex items-center">
    <div className="w-4 h-4 bg-purple-500 mr-2"></div>
    <span>Restructured (RES, RNC)</span>
  </div>
</div>

                      {/* Recent Activity Section */}
                    <div className="  bg-blue-800 md:text-white text-white p-2 mt-2">Recent Activity (Last 90 Days)</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-4 py-2">Total Inquiries</th>
      <th className="border border-black px-4 py-2">Accounts Opened</th>
      <th className="border border-black px-4 py-2">Accounts Updated</th>
      <th className="border border-black px-4 py-2">Accounts Delinquent</th>
    </tr>
  </thead>
  <tbody className="recentActivity">
    <tr className="border border-black text-center">
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.TotalInquiries}</td>
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsOpened}</td>
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsUpdated}</td>
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsDeliquent}</td>
    </tr>
  </tbody>
</table>
                     </div>
                          {/* Summary Section */}
                     <div className="  bg-blue-800 md:text-white text-white p-2 mt-2">Summary</div>
                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className='border border-black px-2 py-1'>Number of Accounts</th>
                <th className='border border-black px-2 py-1'>Total Balance Amount</th>
                <th className='border border-black px-2 py-1'>Recent Account</th>
              </tr>
            </thead>
            <tbody class="summary1">
                <tr>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.NoOfAccounts}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalBalanceAmount}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.RecentAccount}</td>
            </tr>
            </tbody>
                    </table>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
                 <tr>
                <th className="border border-black px-2 py-1">Number of Open Accounts</th>
                <th className="border border-black px-2 py-1">Total Past Due Amount</th>
                <th className="border border-black px-2 py-1">Oldest Account</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.NoOfActiveAccounts}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalPastDue}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.OldestAccount}</td>
            </tr>
            </tbody>
                     </table>

                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className="border border-black px-2 py-1">Total High Credit</th>
                <th className="border border-black px-2 py-1">Total Sanction Amount</th>
                <th className="border border-black px-2 py-1">Single Highest Sanction</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalHighCredit}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalSanctionAmount}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.SingleHighestSanctionAmount}</td>
            </tr>
            </tbody>
                         </table>
                 </div>
                 <div className="flex justify-center mt-4">
                <button
                    className="bg-green-500 text-white p-2 rounded hover:bg-green-700"
                    onClick={printDiv}
                    disabled={isPrinting}
                >
                    Print
                </button>
                <button
                    className="bg-red-500 text-white p-2 rounded hover:bg-red-700"
                    onClick={onClose} // This should close the modal
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ReportModal;
