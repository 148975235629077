import React, { useState } from 'react';
import { DivTag, InputWithLabel, SelectWithLabelOption, Button } from '../../Components/Tags/Tags';
import Header from '../../Components/Header';
import { Table } from 'reactstrap';
import Seo from '../../Components/SeoComponent/Seo';

const RechargeAndBBPSReport = () => {
    const FormValidation = (initialState, validate) => {
        const [values, setValues] = useState(initialState);
        const [errors, setErrors] = useState({});

        const handleChange = (event) => {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        };
        
        const handleBlur = () => {
            const validationErrors = validate(values);
            setErrors(validationErrors);
        };

        return { values, errors, handleChange, handleBlur };
    };

    const initialState = {
        from_date: '',
        to_date: '',
    };

    const validate = (values) => {
        let errors = {};

        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }
        if (values.from_date && values.to_date) {
            const fromDate = new Date(values.from_date);
            const toDate = new Date(values.to_date);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            if (differenceInDays < 30) {
                errors.to_date = 'Date range must be within 30 days';
            }
        }

        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );

    return (
        
        <DivTag>
            <Seo title="Recharge & BBPS" description="This is the API User Panel" url="" image="" />

            <Header heading='RechargeAndBBPSReport' />
            <DivTag className="mx-3 md:mx-8 lg:mx-16 xl:mx-32 2xl:mx-40 mt-12 flex flex-wrap justify-between">
                <InputWithLabel
                    DivclassName="w-full md:w-2/5 lg:w-1/5"
                    label="From date"
                    type="date"
                    name="from_date"
                    id="from_date"
                    required={true}
                    value={values.from_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                    error={errors.from_date}
                />
                <InputWithLabel
                    DivclassName="w-full md:w-2/5 lg:w-1/5"
                    label="To date"
                    type="date"
                    name="to_date"
                    id="to_date"
                    required={true}
                    value={values.to_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                    error={errors.to_date}
                />
                <SelectWithLabelOption
                    DivclassName="w-full md:w-2/5 lg:w-1/5"
                    label="Select Status"
                    id="status"
                    name="status"
                >
                    <option value="failed">failed</option>
                    <option value="success">success</option>
                    <option value="processing">processing</option>
                </SelectWithLabelOption>
                <SelectWithLabelOption
                    DivclassName="w-full md:w-2/5 lg:w-1/5"
                    label="Select Service"
                    id="service"
                    name="service"
                >
                    <option value="Service">Service</option>
                    <option value="Recharge">Recharge</option>
                    <option value="Bill Payment">Bill Payment</option>
                </SelectWithLabelOption>
                <Button
                    type="button"
                    text="Search"
                    className="w-full sm:w-auto bg-gradient-to-r from-cyan-500 to-blue-800 text-white font-semibold rounded-md px-3 py-1 mt-6 shadow-lg hover:from-blue-900 hover:to-blue-400"
                    // onClick={handleSearch}
                />
            </DivTag>

            <DivTag className="mx-3 md:mx-8 lg:mx-16 xl:mx-32 2xl:mx-40 mt-8">
                <DivTag className="overflow-x-auto">
                    <Table className='border-gray-500 table-auto'>
                        <tbody className='bg-blue-800 text-center text-white'>
                            <tr>
                                <th className='px-1 py-1 border border-gray-300'>Sno</th>
                                <th className='px-1 py-1 border border-gray-300'>Service Type</th>
                                <th className='px-1 py-1 border border-gray-300'>Billers Vendor</th>
                                <th className='px-1 py-1 border border-gray-300'>Order Id</th>
                                <th className='px-1 py-1 border border-gray-300'>Transaction Id</th>
                                <th className='px-1 py-1 border border-gray-300'>Transaction Date</th>
                                <th className='px-1 py-1 border border-gray-300'>Transaction Amount</th>
                                <th className='px-1 py-1 border border-gray-300'>Charges</th>
                                <th className='px-1 py-1 border border-gray-300'>State</th>
                            </tr>
                            <tr className='bg-gray-200'>
                                <td className='text-black'>01</td>
                                <td className='text-black'>Service</td>
                                <td className='text-black'>Bill Vendor</td>
                                <td className='text-black'>Order ID</td>
                                <td className='text-black'>Transaction ID</td>
                                <td className='text-black'>2/05/2024</td>
                                <td className='text-black'>3432422</td>
                                <td className='text-black'>6345</td>
                                <td className='text-black'>Bihar</td>
                            </tr>
                        </tbody>
                    </Table>
                </DivTag>
            </DivTag>
        </DivTag>
    );
}

export default RechargeAndBBPSReport;
