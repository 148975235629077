import React from 'react';
import './BtnGradBlue.css';

function BtnGradBlue({input_class,btn_val,onClick,onChange}) {
  return (
    <button onChange={onChange} onClick={onClick}  class={`btn-grad mt-5 btn ${input_class}`}>{btn_val}</button>
  )
}
export default BtnGradBlue
BtnGradBlue.defaultProps = {
    input_class: "",
    btn_val: "Button",
  };