
import API_URLS from '../../config/BackendConfig';
import { getToken } from '../../utils/tokenUtils'; // Updated import to get token from 
import { getRequest, postRequest } from '../../config/AxiosConfig';

const apiBaseUrl = API_URLS.CUSTOMER; // Use API_URLS.CUSTOMER directly

const getAuthHeaders = () => {
  const token = getToken();
  // console.log('Token:', token); 
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Get List of Service Data
export const fetchListOfService = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "service/list",
      headers: getAuthHeaders(), // Ensure headers are correctly applied
    });
    // console.log(response);
    return response;
  } catch (error) {
    console.error('Error fetching List Of Service:', error.response?.data || error.message);
    throw error;
  }
};


// Fetching Service Documentation Data
export const fetchServiceDocumentation = async (serviceId) => {
    try {
        const response = await postRequest({
            url: `${apiBaseUrl}service/documentation`,
            headers: getAuthHeaders(),
            data: {
                service_id: serviceId,
            }
        });
        // console.log(response);
        return response;
    } catch (error) {
        console.error('Error in Fetching Service Documentation', error.response?.data || error.message);
        throw error;
    }
};


