
import API_URLS from '../../config/BackendConfig';
import { getToken } from '../../utils/tokenUtils'; // Updated import to get token from
import { getRequest, postRequest } from '../../config/AxiosConfig';

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
  const token = getToken();
  // console.log('Token:', token);
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Add Bank Data 
export const AddBankService = async ({ account_number, bank_name, account_type, ifsc_code, branch_name, account_holder_name }) => {
  try {
    const payload =
    {
      "account_number": account_number,
      "bank_name": bank_name,
      "account_type": account_type,
      "ifsc_code": ifsc_code,
      "branch_name": branch_name,
      "account_holder_name": account_holder_name
    }
    const response = await postRequest({
      url: apiBaseUrl + "bank-account/add",
      headers: getAuthHeaders(),
      data: payload,
    });
    return response;
  } catch (error) {
    console.log(error)
    console.error('Error adding BankAC data:', error.response?.data || error.message);
    throw error;
  }
};


// Retrive Bank Data from API
export const retriveBankService = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "bank-account/retrive",
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.error('Error fetching BankData data:', error.response?.data || error.message);
    throw error;
  }
};

// Wallet Statement


  export const toggleBankService = async ({account_id}) => {
    try {
        const payload = {
            "account_id": account_id,
        }
        // console.log(payload);
        const response = await postRequest({
            url: apiBaseUrl + "bank-account/toggle-status",
            headers: getAuthHeaders(),
            data: payload,
        });
        console.log("API Response:", response.data);
        
        return response;
    } catch (error) {
        console.error('Erro in Toggle', error.response?.data || error.message);
    }
};