
import API_URLS from '../../config/BackendConfig';
import { getToken } from '../../utils/tokenUtils'; // Updated import to get token from 
import { getRequest, postRequest } from '../../config/AxiosConfig';

const apiBaseUrl = API_URLS.CUSTOMER; // Use API_URLS.CUSTOMER directly

const getAuthHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

//Get Profile Data
export const fetchProfile = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "profile",
      headers: getAuthHeaders(), // Ensure headers are correctly applied
    });
   // console.log(response);
    return response;
  } catch (error) {
    console.error('Error fetching profile:', error.response?.data || error.message);
    throw error;
  }
};


// Update Password in Profile Page
export const updatePassword = async ({ current_password, new_password, new_password_confirmation }) => {
  try {
    const payload = {
      current_password,
      new_password,
      new_password_confirmation,
    };
    const response = await postRequest({
      url: apiBaseUrl + "update-password",
      headers: getAuthHeaders(), // Use the getAuthHeaders function
      data: payload,
    });
    return response;
  } catch (error) {
    console.error('Error updating password:', error.response?.data || error.message);
    throw error;
  }
};
// End Password

// Update PIN in Profile Page
export const updatePin = async ({ current_pin, new_pin, new_pin_confirmation }) => {
  try {
    const payload = {
      current_pin,
      new_pin,
      new_pin_confirmation,
    };
    const response = await postRequest({
      url: apiBaseUrl + "update-pin",
      headers: getAuthHeaders(), // Use the getAuthHeaders function
      data: payload,
    });
    return response;
  } catch (error) {
    console.error('Error updating PIN:', error.response?.data || error.message);
    throw error;
  }
};
// End Pin



// Verify Pan Service Digital KYC
export const verifyPanService = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "validate-pan",
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.error('Error Verify Pan:', error.response?.data || error.message);
    throw error;
  }

}

// Verify Aadhar Service Digital KYC
export const aadharverifyPService = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "validate-aadhar",
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.error('Error in Verify aadhar:', error.response?.data || error.message);
    throw error;
  }
};

// Verify Aadhar OTP Service Digital KYC
export const verifyaADHAROtp = async ({ clientId, otp }) => {
  try {
    const payload = {
      'client_id': clientId,
        'otp': otp
    };
    const response = await postRequest({
      url: apiBaseUrl + "validate-aadhar-auth",
      headers: getAuthHeaders(), // Use the getAuthHeaders function
      data: payload,
    });
    return response;
  } catch (error) {
    console.error('Error Verify otp:', error.response?.data || error.message);
    throw error;
  }
};


// verify Mobile No
export const verifyMobile = async () => {
  try {
    const response = await postRequest({
   url: apiBaseUrl + "validate-mobile",
   headers: getAuthHeaders()
    });
    // console.log("mobile data",response);
    return(response);
  } catch (error) {
    console.error('Error in Verify Mobile no', error.response?.data || error.message);
    throw error;
  }
};

//Verify Mobile OTP
export const verifyMobileOtp = async ({ identifier, otp}) => {
  try {
    const payload = {
      'identifier' : identifier,
      'otp' : otp
    };
    const response = await postRequest({
      url: apiBaseUrl + "validate-mobile-auth",
      headers: getAuthHeaders(),
      data: payload,
    });
    return(response);
  } catch (error) {
    console.error('Error in Verify  Otp:', error.response?.data || error.message);
    throw error;
  }
};

  // Verify Email ID
export const verifyEmail = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "validate-email",
      headers: getAuthHeaders()
    });
    return(response);
  } catch (error) {
    console.error('Error in Verify Email', error.response?.data || error.message);
    throw error;
  }
};


// Retrive Login Activity from API
export const retriveLoginActivityService = async () => {
  try {
    const response = await postRequest({
      url: apiBaseUrl + "login-activity",
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.error('Error fetching Login Activity data:', error.response?.data || error.message);
    throw error;
  }
};


     // Preview ProfileImg
export const profileImgPreview = async ({ filename }) => {
  try {
    // if (!filename) {
    //         throw new Error('Filename is required');
    //       }
    const response = await getRequest({
      url: apiBaseUrl + `user-kyc-files/${filename}`,
      headers: getAuthHeaders() 
    });
    return response;
  } catch (error) {
    console.error("Error fetching image:", error.response?.data || error.message);
    throw error;
  }
};















