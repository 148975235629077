import React from 'react';
import { Button, DivTag, HeadTag,FormTag } from '../../Components/Tags/Tags';
import PinInput from '../../Components/PinInput';
import { AiOutlineLine } from "react-icons/ai";
import { Link } from 'react-router-dom';

const LockScreen = ({ }) => {

  return (
    
    <FormTag method="">
    <DivTag className="flex flex-col justify-center items-center w-full p-1">

      <HeadTag className="text-4xl font-bold text-gray-700" text="IDsPay" />
      <AiOutlineLine color="white" />
      <HeadTag className="text-xl font-bold text-blue-700" text="Hey User!" />
      <HeadTag className="text-md  text-gray-700 font-thin" text="Your Screen was locked because of inactivity to protect your account" />
      <HeadTag className="text-3xl  font-bold text-blue-600" text="Enter Login PIN" />

      <DivTag className="mt-4">
         <PinInput />          {/*   PinComponent  */}
      </DivTag>
      <Button
      type="submit"
        text="Unlock"
        id="unlock"
        name="unlock"
      />
      <HeadTag className="text-lg font-bold text-gray-700 mt-2" text="Unlock Using Pin" />
        <button className='bg-blue-400'>Home</button>
      
    </DivTag>
    </FormTag>
  );
};

export default LockScreen;
































