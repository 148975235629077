import React from 'react'

function InputWithLabel({ outerClass,input_type, label, idName, placeholder, inputClass }) {
    return (
      <div className={`w-full sm:w-1/3 lg:w-1/4 mx-2 ${outerClass}`}>
        <label htmlFor={idName} className=''>{label}</label>
        <input id={idName} name={idName} type={input_type} className={`px-2 py-1 text-blue-700 border-blue-700 border w-full rounded ${inputClass}`} placeholder={placeholder} />
      </div>
    );
  }
export default InputWithLabel
InputWithLabel.defaultProps = {
    outer_class: "",
    label: "",
    id_name: "",
    placeholder: "Enter here",
    input_class: "",
    input_type: "text",
  };