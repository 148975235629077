import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Sidebar from './Header/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../src/Layouts/Main/customToastStyles.css'; // Import custom styles for centering
import { LuWifiOff } from "react-icons/lu";
import { BsWifi } from "react-icons/bs";

const MainLayout = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  // State to track online status
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  // Function to play notification sound for 1 second
  const playNotificationSound = () => {
    const audio = new Audio('/notification.mp3');
    audio.play();

    // Stop sound after 1 second
    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0; // Reset sound to the beginning
    }, 1000);
  };

  // Function to handle online status
  const handleOnline = () => {
    if (!isOnline) {
      // Vibrate for 1 second when connection is restored
      if (navigator.vibrate) {
        navigator.vibrate(1000);
      }
      playNotificationSound(); // Play notification sound
      // Custom toast for online status
      toast.success("Internet connection restored.", {
        position: "top-center",
        className: "center-toast large-toast md:text-2xl text-xl text-center", // Apply transparent background and blur
        icon: <BsWifi className='text-xl' color='blue' />,
        closeButton: true,
        autoClose: 3000,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: true
      });
      setIsOnline(true);
    }
  };

  // Function to handle offline status
  const handleOffline = () => {
    // Vibrate for 1 second when connection is lost
    if (navigator.vibrate) {
      navigator.vibrate(1000);
    }
    playNotificationSound(); // Play notification sound
    toast.error("Internet connection lost. Please check your connection.", {
      position: "top-center",
      className: "center-toast large-toast md:text-2xl text-xl text-center",
      icon: <LuWifiOff className='text-xl' color='red' />,
      closeButton: true,
      autoClose: 7000,
      hideProgressBar: false,
      draggable: true,
      pauseOnHover: true
    });
    setIsOnline(false);
  };

  useEffect(() => {
    if (!isOnline && window.navigator.onLine) {
      handleOnline();
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isOnline]);

  return (
    <>
      {!isLoginPage && <Sidebar />}
      <div id="main_component">
        <Outlet />
      </div>
      <ToastContainer />
    </>
  );
};

export default MainLayout;
