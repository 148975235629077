
import API_URLS from "../../config/BackendConfig";
import { getToken } from "../../utils/tokenUtils";
import { getRequest, postRequest } from "../../config/AxiosConfig";

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
    const token = getToken();
    // console.log('Token:', token);
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

// Add IP Service
export const AddIPService = async  ({ip_address}) => {
    try {
        const payload = 
        {
            "ip_address": ip_address,
        }
        const response = await postRequest({
            url: apiBaseUrl + "ip/add",
            headers: getAuthHeaders(),
            data: payload,
        });
        // console.log(response);
        return response;
    } catch (error) {
        console.log('Error In Adding IP', error.response?.data || error.message);   
        throw error;
    }
};
// ENd


// IPService Retrive
export const IPRetriveService = async (page = 1) => {
    try {
        const response = await postRequest({
            url: `${apiBaseUrl}ip/report?page=${page}`,
            headers: getAuthHeaders(),
        });
        // console.log(response);
        return response;
    } catch (error) {
        console.error('Error In Fetching IP Service Details', error.response?.data || error.message);
    }
};


    // Toggle 
export const ToggleStatusService = async ({ip_id}) => {
    try {
        const payload = {
            "ip_id": ip_id,
        }
        // console.log(payload);
        const response = await postRequest({
            url: apiBaseUrl + "ip/toggle-status",
            headers: getAuthHeaders(),
            data: payload,
        });
        // console.log("API Response:", response.data);
        
        return response;
    } catch (error) {
        console.error('Erro in Toggle', error.response?.data || error.message);
    }
};