import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import { fetchProfile } from '../Services/profile/profileservice';
import { removeToken } from '../utils/tokenUtils';
import {logout} from '../Services/authService';

const AuthWrapper = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Initially assume authenticated
  const location = useLocation(); // Get the current location

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = Cookies.get('authTokenUser');

      if (!token) {
        // No token found, user is not authenticated
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await fetchProfile();
        if (response?.data?.message === 'Unauthenticated.') {
          // Token is invalid, log out the user
          removeToken();
          setIsAuthenticated(false);
          logout();  
        }
      } catch (error) {
        console.error('Error during authentication check:', error);
        setIsAuthenticated(false);
        logout();
      }
    };

    checkAuthentication();
  }, [location.pathname]); // Re-run check whenever the pathname changes

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AuthWrapper;
