
import React from 'react';
import { DivTag, HeadTag, ImgTag, PTag } from '../../Components/Tags/Tags';
import SliderCrousel from '../../Components/SliderLoginPage/SliderCrousel';
import { Link } from 'react-router-dom';


const ConfirmAuth2Steps = () => {
 

    return (
        <>
            <DivTag className="mx-auto bg-slate-300 min-h-screen flex flex-col md:flex-row sm:p-0 p-5">
                {/* First Column: Image */}
                <SliderCrousel className="hidden md:block w-1/3" />

                {/* <DivTag className="flex flex-col items-center md:w-1/3 mt-[8rem] p-8"> */}
                <DivTag className="flex flex-col items-center w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-2 sm:mt-[8rem] md:mt-[8rem] lg:mt-[8rem] xl:mt-[8rem] 2xl:mt-[8rem]p-8">
                    <ImgTag
                        src="assets/images/IDSPayLogo.png"
                        alt="logo"
                        className=""
                    />
                    <DivTag className="text-center mt-12">
                        
                        <HeadTag
                            className="text-blue-800 text-2xl font-semibold underline animate-pulse"
                            text="Confirmation"
                        />
                    </DivTag>
                </DivTag>

                {/* <DivTag className="flex flex-col items-center md:w-1/3 mt-[14rem]"> */}
                <DivTag className="flex flex-col items-center sm:items-center w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-20 sm:mt-[14rem] md:mt-[14rem] xl:mt-[14rem] 2xl:mt-[14rem]">
                <ImgTag
                        src="assets/images/check.png"
                        alt="logo"
                        className="mb-3 animate-pulse size-24"
                    />

                   <PTag className="text-blue-500 text-xl sm:text-lg text-center" text="Your login password has been successfully changed. Now you can login with new password." />

                   <Link to="/LoginUserPage" className="mt-6 font-bold text-md text-dark underline" >Click Here For Login</Link>
                </DivTag>
            </DivTag>
        </>
    );
};

export default ConfirmAuth2Steps;

