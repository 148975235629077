import React, {useState} from 'react'
import './helpdesk.css';
import Header from '../../Components/Header';

const HelpDesk = () => {
    const [visibleSection, setVisibleSection] = useState('');
    const [openSection, setOpenSection] = useState(1);

    const showSection = (sectionId) => {
        setVisibleSection(sectionId); // Set the clicked section as visible
      };


      const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
      }


  return (
 
<>
  {/* Hero Section */}
  <Header heading='Help Desk' />
  <div
    className="hero"
    style={{
      background: 'url("assets/images/desk.jpg")',
      height: "20rem",
      width: "100%",
    }}
  >
    <h1 className="mt-2 text-blue-500">
      H<span className="text-yellow-500">e</span>lpd
      <span className="text-yellow-600">e</span>sk
    </h1>
    <p>Dashboard / Helpdesk</p>
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search here"
        className="form-control inline-block ml-16 shadow-inner border border-blue-600"
        style={{
          borderColor: "rgb(15, 15, 242)",
          boxShadow:
            "inset 0px 35px 25px #dddee1e0, inset 10px 0px 25px #0000004b, inset 40px 0px 20px #fff, inset -10px 0px 25px #0000004b, inset -40px 0px 20px #cbced7, inset 0px 10px 10px #000000e0, inset 0px -15px 25px #00000036, 10px 25px 40px -10px #00000060",
        }}
      />
      <button className="bg-blue-800 rounded-md text-white hover:text-black hover:bg-blue-500 py-2 px-3">Search</button>
    </div>
  </div>
  {/* Content Section */}
  <div className="container mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
      <div className="p-3 shadow-xl shadow-slate-400 rounded-xl">
        <h5>
        <button className='underline font-bold text-blue-700 text-xl' onClick={() => showSection('firstcontainer')}>
                Getting Started FAQs
              </button>
        </h5>
        <p className="p-4">(Frequently Asked Questions)</p>
      </div>
      <div className="p-3 shadow-xl shadow-slate-400 rounded-xl">
        <h5>
        <button className='underline font-bold text-blue-700 text-xl' onClick={() => showSection('secondcontainer')}>
                User Account Terms
              </button>
        </h5>
        <p className="p-4">Terms and Conditions agreement</p>
      </div>
      <div className="p-3 shadow-xl shadow-slate-400 rounded-xl">
        <h5>
        <button className='underline font-bold text-blue-700 text-xl' onClick={() => showSection('thirdcontainer')}>
                Product Features Support
              </button>
        </h5>
        <p className="p-4">Good Product Support Is Smart Marketing</p>
      </div>
    </div>

    {/* Sections to be shown/hidden */}

     {/* Sections to be shown based on state */}
     {visibleSection === 'firstcontainer' && (
          <div className="mt-4">
            <div className="accordion">
              {/* FAQ content here */}
              <div className="border border-gray-200 rounded">
        <h2 className="accordion-header">
          <button
            className="w-full text-left bg-blue-500 text-white px-4 py-2 rounded-t focus:outline-none"
            onClick={() => toggleSection(1)}
          >
            <h5>FAQs #1</h5>
          </button>
        </h2>
        {openSection === 1 && (
          <div className="accordion-collapse">
            <div className="accordion-body p-4 bg-gray-100">
              <p>
                A FAQ (Frequently Asked Questions) page is a valuable resource
                for customers and businesses alike. It answers common queries
                related to products, services, and business operations,
                providing customers with quick and easy access to information
                without needing to contact customer support directly.
              </p>
            </div>
          </div>
        )}
      </div>

      {/* FAQs #2 */}
      <div className="border border-gray-200 rounded">
        <h2 className="accordion-header">
          <button
            className="w-full text-left bg-blue-500 text-white px-4 py-2 rounded-t focus:outline-none"
            onClick={() => toggleSection(2)}
          >
            <h5>FAQs #2</h5>
          </button>
        </h2>
        {openSection === 2 && (
          <div className="accordion-collapse">
            <div className="accordion-body p-4 bg-gray-100">
              <p>
                A FAQ (Frequently Asked Questions) page is a valuable resource
                for customers and businesses alike. It answers common queries
                related to products, services, and business operations,
                providing customers with quick and easy access to information
                without needing to contact customer support directly.
              </p>
            </div>
          </div>
        )}
      </div>

      {/* FAQs #3 */}
      <div className="border border-gray-200 rounded">
        <h2 className="accordion-header">
          <button
            className="w-full text-left bg-blue-500 text-white px-4 py-2 rounded-t focus:outline-none"
            onClick={() => toggleSection(3)}
          >
            <h5>FAQs #3</h5>
          </button>
        </h2>
        {openSection === 3 && (
          <div className="accordion-collapse">
            <div className="accordion-body p-4 bg-gray-100">
              <p>
                A FAQ (Frequently Asked Questions) page is a valuable resource
                for customers and businesses alike. It answers common queries
                related to products, services, and business operations,
                providing customers with quick and easy access to information
                without needing to contact customer support directly.
              </p>
            </div>
          </div>
        )}
      </div>
              {/* More FAQs */}
            </div>
          </div>
        )}

        {visibleSection === 'secondcontainer' && (
          <div className="mt-4">
            <div className="p-12 shadow-inner">
              <h2 className="text-center text-blue-500 underline">
                User Account Terms
              </h2>
              <div>
                <h5>termsphrase [ plural ]Add to word list B2</h5>
                <div>
                  <span className="text-blue-500">*</span> the conditions that
                  control an agreement, arrangement, or activity:
                </div>
                <hr />
                <div>
            <span className="text-blue-500">*</span> terms and conditions This
            competition is only open to UK residents. Terms and conditions may
            apply.:
          </div>
          <hr />
          <div>
            <span className="text-blue-500">*</span> the conditions that control
            an agreement, arrangement, or activity:
          </div>
          <hr />
          <div>
            <span className="text-blue-500">*</span> terms of She broke her
            terms of employment by leaving without giving notice.:
          </div>
          <hr />
          <div>
            <span className="text-blue-500">*</span> They are claiming that he
            broke the terms of a confidentiality agreement that he signed with
            the couple.:
          </div>
          <hr />
          <div>
            <span className="text-blue-500">*</span> They had agreed to the
            terms of the previous contract.:
          </div>
          <hr />
          <div>
            <span className="text-blue-500">*</span> We will then be in a better
            position to dictate the terms of the sale.:
          </div>
              </div>
            </div>
          </div>
        )}

        {visibleSection === 'thirdcontainer' && (
          <div className="text-center">
            <div className="shadow-inner">
              <h5>Product Features Support</h5>
              <p className="p-4">Good Product Support Is Smart Marketing</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
  <div className="shadow-xl shadow-slate-400 rounded-xl p-4">
    <div className="card-body">
      <h5 className="text-blue-600 underline font-semibold text-lg mb-3">
        Discuss For New Projects
      </h5>
      <div className="contact-info">
        <p>
          <strong className="text-purple-700">IND</strong> - +91- 91539 88494
        </p>
        <p>
          <strong className="text-purple-700">Email ID</strong> - Sales@idspay.in
        </p>
      </div>
      <hr className="my-4" />
      <h5 className="text-blue-600 underline font-semibold text-lg mb-3">
        Discuss For New Projects
      </h5>
      <div className="contact-info">
        <p>
          <strong className="text-purple-700">IND</strong> - +91- 91539 88494
        </p>
        <p>
          <strong className="text-purple-700">Email ID</strong> - Sales@idspay.in
        </p>
      </div>
      <hr className="my-4" />
      <h5 className="text-blue-600 underline font-semibold text-lg mb-3">
        Planning to Visit Office?
      </h5>
      <p>
        <strong className="text-purple-700">Address</strong> - First Floor,
        Pathak House, B-Block, Brahampura, Muzaffarpur- 842001
      </p>
    </div>
  </div>

  <div className=" shadow-xl shadow-slate-400 rounded-xl p-4">
    <div className="card-body">
      <h5 className="text-blue-600 underline font-semibold text-lg mb-3">
        API Support Department
      </h5>
      <div className="contact-info">
        <p>
          <strong className="text-purple-700">IND</strong> - +91- 91539 88494
        </p>
        <p>
          <strong className="text-purple-700">Email ID</strong> - api.support@infocartgroup.com
        </p>
      </div>
      <hr className="my-4" />
      <h5 className="text-blue-600 underline font-semibold text-lg mb-3">
        Accounts Department
      </h5>
      <p>
        <strong className="text-purple-700">Email ID</strong> - accounts@infocartgroup.com
      </p>
      <hr className="my-4" />
      <h5 className="text-blue-600 underline font-semibold text-lg mb-3">
        HR Department
      </h5>
      <div className="contact-info">
        <p>
          <strong className="text-purple-700">IND</strong> - +91- 91137 22202
        </p>
        <p>
          <strong className="text-purple-700">IND</strong> - +91- 86036 93666
        </p>
      </div>
    </div>
  </div>
</div>

          </div>
        )}
  </div>
</>




  )
}

export default HelpDesk
