import React from 'react';

export default function QuickLinkTop({icon,text}) {
  return (
    <>
      <div className="none_1220 flex flex-wrap justify-center items-center mx-1 select-none gap-5">
          <label className="text-slate-400">
          <input type="checkbox" className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"/>
          <span  className="flex items-center justify-center px-2  rounded-lg shadow-lg transition-all duration-200 cursor-pointer relative border-slate-300 border-[3px] bg-white before:absolute before:block before:w-5 before:h-5 before:border-[3px]  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-white before:text-xs before:flex before:items-center before:justify-center">
              <span className="transition-all duration-100 mr-2">
              {icon}
              </span>
              <span className="transition-all duration-300 text-center">{text}</span>
          </span>
          </label>
      </div>
    </>
  )
}
