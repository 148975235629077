import React, {useState} from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Header from '../../Components/Header';
import './VanCollectionReport.css';
import  InputWithLabel  from '../../Components/InputWithLabel';
import BtnGradBlue from '../../Components/BtnGradBlue';
import { Button } from '../../Components/Tags/Tags';
import { styled } from '@mui/system';
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

function VanCollectionReport() {
    const [searchValue, setSearchValue] = useState('');
  
    const filteredRows = rows.filter((row) =>
        Object.values(row).some((value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
  
    const handleSearchChange = (event) => {
      setSearchValue(event.target.value);
    };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    return (
        <div>
            <Header heading='Van Collection Report' />
            <div className="mt-3 flex flex-wrap justify-center w-full">
                <InputWithLabel input_type='date' label='From Date' idName='from_date' placeholder='From Date' />
                <InputWithLabel input_type='date' label='To Date' idName='to_date' placeholder='To Date'/>
                 <BtnGradBlue input_class='h-fit' btn_val={`Search`} />
            </div>
          
            <div className="mt-5 flex justify-between items-center gap-2">
                <div className="flex gap-2">
                <BtnGradBlue onClick={handleDownloadExcel} input_class='h-fit' btn_val={`Excel`} />
                <BtnGradBlue onClick={handleDownloadPDF} input_class='h-fit' btn_val={`Pdf`} />
                <BtnGradBlue onClick={handlePrint} input_class='h-fit' btn_val={`Print`} />
                </div>
                <input value={searchValue} onChange={handleSearchChange}  type='search' placeholder='Search here..' className='px-2 py-1 rounded-md border border-blue-700 outline-none' />
            </div>
    <Root className='mt-1' sx={{ width: '100%'}}>
      <table   sx={{ width: '100%'}} style={{width:'100% !important;'}} aria-label="custom pagination table w-full">
        <thead style={{background:'black',color:'white'}}>
          <tr>
          {tableHeader.map((th_this) => (
            <th>{th_this}</th>
          ))}
            {/* <th>SN</th>
            <th>Status</th>
            <th>Date</th>
            <th>Order ID</th>
            <th>Transaction</th>
            <th>Description</th>
            <th>Order Value</th>
            <th>DR/CR</th>
            <th>Balance</th> */}
          </tr>
        </thead>
        <tbody className='light_two_tbody '>


        {/* {filteredRows.map((row) => (
          <tr key={row.id}>
          <td>{row.sn}</td>
          <td>
            {row.status}
          </td>
          <td>
            {row.timestamp}
          </td>
          <td>{row.order_id}</td>
          <td>{row.transaction}</td>
          <td>{row.description}</td>
          <td>{row.order_val}</td>
          <td>{row.dr_cr}</td>
          <td>{row.balance}</td>
          </tr>
        ))} */}


          {/* {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map.filteredRows.map(((row) => (

            <tr key={row.sn}>
              <td>{row.sn}</td>
              <td>
                {row.status}
              </td>
              <td>
                {row.timestamp}
              </td>
              <td>{row.order_id}</td>
              <td>{row.transaction}</td>
              <td>{row.description}</td>
              <td>{row.order_val}</td>
              <td>{row.dr_cr}</td>
              <td>{row.balance}</td>
            </tr>
        )



          ))}

          {emptyRows > 0 && (
            <tr style={{ height: 34 * emptyRows,background:'transparent' }}>
              <td colSpan={9} aria-hidden />
            </tr>
          )} */}

          {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
  <tr key={row.sn}>
    <td>{row.sn}</td>
    <td>{row.status}</td>
    <td>{row.timestamp}</td>
    <td>{row.order_id}</td>
    <td>{row.transaction}</td>
    <td>{row.description}</td>
    <td>{row.order_val}</td>
    <td>{row.dr_cr}</td>
    <td>{row.balance}</td>
  </tr>
))}


        </tbody>
        <tfoot>
          <tr>
            <CustomTablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={9}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  'aria-label': 'rows per page',
                },
                actions: {
                  showFirstButton: true,
                  showLastButton: true,
                  slots: {
                    firstPageIcon: FirstPageRoundedIcon,
                    lastPageIcon: LastPageRoundedIcon,
                    nextPageIcon: ChevronRightRoundedIcon,
                    backPageIcon: ChevronLeftRoundedIcon,
                  },
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </tr>
        </tfoot>
      </table>
    </Root>
    {/* <DownloadPdf /> */}
        </div>
    )
}
export default VanCollectionReport;


// function createData(name, calories, fat) {
//     return { name, calories, fat };
//   }
  
//   const rows = [
//     createData('Cupcake', 305, 3.7),
//     createData('Donut', 452, 25.0),
//     createData('Eclair', 262, 16.0),
//     createData('Frozen yoghurt', 159, 6.0),
//     createData('Gingerbread', 356, 16.0),
//     createData('Honeycomb', 408, 3.2),
//     createData('Ice cream sandwich', 237, 9.0),
//     createData('Jelly Bean', 375, 0.0),
//     createData('KitKat', 518, 26.0),
//     createData('Lollipop', 392, 0.2),
//     createData('Marshmallow', 318, 0),
//     createData('Nougat', 360, 19.0),
//     createData('Oreo', 437, 18.0),
//   ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

  var rows = [];
    for (let i = 1; i < 50; i++) {
        const sn = i + 0;
        const status = 'Success';
        const timestamp = '31/05/2024';
        // const timestamp = new Date().toISOString();
        const order_id = `${i + 0}`;
        const transaction = `1`;
        const description = `Other Transaction`;
        const order_val = `1`;
        const dr_cr = `DR`;
        const balance = `${200 - i}`;
        const obj = {
            sn,
            status,
            timestamp,
            order_id,
            transaction,
            description,
            order_val,
            dr_cr,
            balance,
        };
        rows.push(obj);
    };
    rows.sort((a, b) => (a.sn < b.sn ? -1 : 1));

    const tableHeader = ['SN','Status','Date','Order ID','Transaction','Description','Order Value','DR/CR','Balance'];

    
    var table = document.createElement('table');
  const thead = document.createElement('thead');
  const tbody = document.createElement('tbody');
  const headerRow = document.createElement('tr');
  tableHeader.forEach((table_header_) => {
    const th = document.createElement('th');
    th.textContent = table_header_;
    headerRow.appendChild(th);
  });
  thead.appendChild(headerRow);
  rows.forEach((item_tr) => {
    const tr = document.createElement('tr');
    const td_sn = document.createElement('td');
    td_sn.textContent = item_tr.sn;
    tr.appendChild(td_sn);
    const td_status = document.createElement('td');
    td_status.textContent = item_tr.status;
    tr.appendChild(td_status);
    const td_timestamp = document.createElement('td');
    td_timestamp.textContent = item_tr.timestamp;
    tr.appendChild(td_timestamp);
    const td_order_id = document.createElement('td');
    td_order_id.textContent = item_tr.order_id;
    tr.appendChild(td_order_id);
    const td_transaction = document.createElement('td');
    td_transaction.textContent = item_tr.transaction;
    tr.appendChild(td_transaction);
    const td_description = document.createElement('td');
    td_description.textContent = item_tr.description;
    tr.appendChild(td_description);
    const td_order_val = document.createElement('td');
    td_order_val.textContent = item_tr.order_val;
    tr.appendChild(td_order_val);
    const td_dr_cr = document.createElement('td');
    td_dr_cr.textContent = item_tr.dr_cr;
    tr.appendChild(td_dr_cr);
    const td_balance = document.createElement('td');
    td_balance.textContent = item_tr.balance;
    tr.appendChild(td_balance);
    tbody.appendChild(tr);
  });
  table.appendChild(thead);
  table.appendChild(tbody);





    const handlePrint = () => {
        const printWindow = window.open('', '', 'height=500,width=800');
        printWindow.document.write('<html><head><title>Van Collection Report</title><style>table{width:100%}th,td{text-align:center}th,td,tr,thead,tbody,table{border:1px solid black;border-collapse:collapse;}</style>');
        printWindow.document.write('</head><body>');
        printWindow.document.write(table.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
      };
      
// const handleDownloadPDF = () => {
//     const doc = new jsPDF();
//     const tableData = [];
//     rows.forEach((item) => {
//       const rowData = [];
//       tableData.push(tableHeader);
//       tableHeader.forEach((header) => {
//         rowData.push(item[header]);
//       });
//       tableData.push(rowData);
//     });
//     doc.autoTable({
//       head: [columns.map((column) => column.name)],
//       body: tableData,
//     });
//     doc.save('table.pdf');
//   };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();    
    doc.text('Van Collection Report', 10, 10);

    const rows2 = rows.map(item => {
        // const rowData = tableHeader.map(header => item[header]);
        return [item.sn, item.status,item.timestamp,item.order_id,item.transaction,item.description,item.order_val,item.dr_cr,item.balance]; // Include SNo manually
    });
    
    doc.autoTable(tableHeader, rows2);
    doc.save('van_collection_report.pdf');
  };

  const handleDownloadExcel = () => {
    const page_url = window.location.href;
    console.log(table);
    let csv = "";
    const rows = table.querySelectorAll("tr");
    rows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("td, th");
      cells.forEach((cell, index) => {
        if (index === 6) {
          rowData.push(cell.textContent.replace(/\s/g, ""));
        } else if (index === 14) {
          return;
        } else {
          rowData.push('"' + cell.textContent.replace(/"/g, '""') + '"');
        }
      });
      csv += rowData.join(",") + "\n";
    });
    const link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.download = "transaction_report.csv";
    link.click();
  };
  
    
  
  const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const Root = styled('div')(
    ({ theme }) => `
    border-radius: 12px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    overflow: clip;
  
    table {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      border: none;
      width: 100%;
      margin: -1px;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 8px;
    }
  
    `,
  );
  
  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 4px 0;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      font-family: 'IBM Plex Sans', sans-serif;
      padding: 2px 0 2px 4px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 6px; 
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      transition: all 100ms ease;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }
  
      &:focus {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      display: flex;
      gap: 6px;
      border: transparent;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      display: flex;
      align-items: center;
      padding: 0;
      border: transparent;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      transition: all 120ms ease;
  
      > svg {
        font-size: 22px;
      }
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }
  
      &:focus {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
  
      &:disabled {
        opacity: 0.3;
        &:hover {
          border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
          background-color: transparent;
        }
      }
    }
    `,
  );
  