import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Seo from '../../Components/SeoComponent/Seo';
import { DivTag, HeadTag, Button, InputField, ImgTag, FormTag } from '../../Components/Tags/Tags';
import SliderCrousel from '../../Components/SliderLoginPage/SliderCrousel';
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import ReCAPTCHA from 'react-google-recaptcha'; 
import { login,verifyPin } from "../../Services/authService";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalWithPinInput from "../../Components/ModalWithPinComponent/ModalWithPinInput";
import { Link } from "react-router-dom";
import Marquee from 'react-fast-marquee';
import {
  showSuccessToast,
  showErrorToast,
} from "../../utils/toastUtils";
import Confetti from 'react-confetti-boom';
import SliderCrousel2 from "../../Components/SliderCrousel2";
import NewsArea from "../../Components/NewsArea";

const LoginUserPage = () => {
  const [user_id, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [tempToken, setTempToken] = useState('');
  const [captchaToken, setCaptchaToken] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = !!Cookies.get('authTokenUser');
    if (isLoggedIn) {
      navigate('/Dashboard');
    }
  }, [navigate]);


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

const handleCaptchaChange = () => {
  setCaptchaToken(!captchaToken);
};

const handleLogin = async (e) => {
  e.preventDefault();

  if (user_id.trim() === '' || password.trim() === '') {
    showErrorToast('Please enter Username and Password');
    return;
  }

  if (!captchaToken) {
    showErrorToast("Please complete the reCAPTCHA.");
    return;
  }

  setIsLoading(true);

  try {
    const result = await login({ user_id, password, captchaToken });
    // console.log(result);

    // Check if login failed due to missing location
    if (result.message === "Please enable location services.") {
      showErrorToast("Location permission denied. Please allow access to location.");
      return;
    }

    if (result.success === false && result.data.temp_token) {
      setTempToken(result.data.temp_token); // Store tempToken after login
      showSuccessToast(result.message || "Step 1 successful. Please verify your PIN.");
      setIsModalOpen(true); // Show PIN verification modal
    } else {
      showErrorToast(result.data.message || "Login failed.");
    }
  } catch (error) {
    console.error(error);
    if (error.message === "Location access denied or unavailable.") {
      showErrorToast("Location permission denied. Please allow access to your location.");
    } else {
      showErrorToast("Server error occurred.");
    }
  } finally {
    setIsLoading(false);
  }
};

  const handlePinSubmit = async () => {
    if (pin.length < 6) {
      showErrorToast("Please enter a valid PIN.");
      return;
    }
  
    setIsLoading(true); // Show loading state
  
    try {
      const result = await verifyPin(pin.join(''), tempToken); // Join PIN digits
      if (result.success) {
        navigate('/Dashboard'); // Redirect to dashboard
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      showErrorToast("Error verifying PIN.");
    } finally {
      setIsLoading(false); // Hide loading state
    }
  };

  return (
    <>
      <Seo />
      {/* <ToastContainer /> */}
      <ModalWithPinInput
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          tempToken={tempToken} // Pass tempToken to the modal
          onPinSubmit={handlePinSubmit} // Handle PIN submission
        />

      <DivTag className="bg-slate-700 md:w-full min-h-screen flex flex-col sm:flex-row">
        <SliderCrousel />
        
        <DivTag className="hidden sm:flex flex-col items-center w-full sm:w-[35%] bg-gradient-to-r to-blue-800/70 from-white/50 bg-white/30 backdrop-blur-lg rounded-lg shadow-lg">
        <SliderCrousel2 />
         

          <DivTag className="text-center">
          
            <style jsx>{`
              @keyframes flicker {
                0%, 100% { opacity: 1; }
                41.99%, 42%, 48%, 49% { opacity: 0; }
                43.01%, 49.01% { opacity: 1; }
              }
              @keyframes scroll {
                0% { transform: translateY(100%); }
                100% { transform: translateY(-100%); }
              }
              .animate-scroll {
                animation: scroll 10s linear infinite;
                white-space: nowrap;
              }
            `}</style>

<style jsx>{`
        .animated-squares-bg {
          position: relative;
          overflow: hidden;
          background: rgba(255, 255, 255, 0.1);
        }

        .animated-squares-bg::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.1);
          animation: squares 20s infinite linear;
        }

        @keyframes squares {
          0% { background-size: 20%; }
          50% { background-size: 40%; }
          100% { background-size: 20%; }
        }

        * {
          margin: 0;
          padding: 0;
        }

        section {
          position: relative;
          width: 100%;
          height: 100vh;
          background: #3586ff;
          overflow: hidden;
        }

        section .air {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100px;
          background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
          background-size: 1000px 100px;
        }

        section .air.air1 {
          animation: wave 30s linear infinite;
          z-index: 1000;
          opacity: 1;
          animation-delay: 0s;
          bottom: 0;
        }

        section .air.air2 {
          animation: wave2 15s linear infinite;
          z-index: 999;
          opacity: 0.5;
          animation-delay: -5s;
          bottom: 10px;
        }

        section .air.air3 {
          animation: wave 30s linear infinite;
          z-index: 998;
          opacity: 0.2;
          animation-delay: -2s;
          bottom: 15px;
        }

        section .air.air4 {
          animation: wave2 5s linear infinite;
          z-index: 997;
          opacity: 0.7;
          animation-delay: -5s;
          bottom: 20px;
        }

        @keyframes wave {
          0% { background-position-x: 0px; }
          100% { background-position-x: 1000px; }
        }

        @keyframes wave2 {
          0% { background-position-x: 0px; }
          100% { background-position-x: -1000px; }
        }
      `}</style>

           
          </DivTag>
                   {/* Colored Background Section */}
                   <div className="w-full h-full rounded-lg relative overflow-hidden">
                <h1 className="text-red-700 text-center text-2xl underline decoration-sky-500 font-mono">News</h1>
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="overflow-hidden">
                        <div className="animate-scroll flex flex-col">
                            <h6 className="text-blue-800 text-lg truncate text-clip overflow-hidden font-serif">#Welcome to the Login Page!.............................................</h6>
                            <h6 className="text-pink-800 text-lg truncate text-clip overflow-hidden font-serif">#Please enter your credentials!........................................</h6>
                            <h6 className="text-blue-800 text-lg truncate text-clip overflow-hidden font-serif">#Access your account securely!...........................................</h6>
                            <h6 className="text-pink-800 text-lg truncate text-clip overflow-hidden font-serif">#Need help? Contact support!................................................</h6>
                            <h6 className="text-blue-800 text-lg truncate text-clip overflow-hidden font-serif">#Remember to log out after use!......................</h6>
                            <h6 className="text-blue-800 text-lg truncate text-clip overflow-hidden font-serif">#Welcome to the Login Page!......................</h6>
                            <h6 className="text-pink-800 text-lg truncate text-clip overflow-hidden font-serif">#Please enter your credentials!......................</h6>
                            <h6 className="text-blue-800 text-lg truncate text-clip overflow-hidden font-serif">#Access your account securely!......................</h6>
                            <h6 className="text-pink-800 text-lg truncate text-clip overflow-hidden font-serif">#Need help? Contact support!......................</h6>
                            <h6 className="text-blue-800 text-lg truncate text-clip overflow-hidden font-serif">#Remember to log out after use!......................</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-around w-full mt-0">
                <p className="text-white font-semibold underline text-xl">Terms & Conditions</p>
                <p className="text-white font-semibold underline text-xl">Privacy Policy</p>
            </div>

            <style jsx>{`
              .animate-scroll {
                animation: scroll 10s linear infinite;
              }

              .animate-scroll:hover {
                animation-play-state: paused;
              }

              @keyframes scroll {
                0% {
                  transform: translateY(100%);
                }
                10% {
                  transform: translateY(0);
                }
                50% {
                  transform: translateY(0);
                }
                90% {
                  transform: translateY(-90%);
                }
              }
            `}</style>

            <style jsx>{`
              .animate-scroll {
                animation: scroll 10s linear infinite;
              }

              @keyframes scroll {
                0% {
                  transform: translateY(100%);
                }
                10% {
                  transform: translateY(0);
                }
                50% {
                  transform: translateY(0);
                }
                90% {
                  transform: translateY(-90%);
                }
              }
            `}</style>
            {/*  */}
                    </DivTag>


        <FormTag className="w-full sm:w-[39%] bg-gradient-to-r from-blue-800/50 to-white/60 backdrop-blur-xl border border-white/20 rounded-xl shadow-xl  hover:bg-white/10">
         
         
          <DivTag className="flex flex-col w-full sm:w-full md:p-10">
          <ImgTag className='w-[72%] md:w-[82%]' src="assets/images/idspay_logo_log2.png" alt="logo" />
          <DivTag className="text-center mb-2">
            <h1
  className="text-xl md:text-2xl font-bold text-transparent stroke-[1px] stroke-white tracking-widest text-center relative"
  style={{
    textShadow: '12px 12px 32px #cecece',
    WebkitTextStroke: '0.1rem #fff',
    animation: 'flicker 7s infinite'
  }}
  
>Welcome Back</h1>

<style jsx>{`
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    41.99%, 42%, 48%, 49% { opacity: 0; }
    43.01%, 49.01% { opacity: 1; }
  }
`}</style>


            <h1
  className="text-2xl md:text-4xl font-bold text-transparent stroke-[1px] stroke-white tracking-widest text-center relative"
  style={{
    textShadow: '12px 12px 32px #cecece',
    WebkitTextStroke: '0.1rem #fff',
    animation: 'flicker 3s infinite'
  }}
  
>To API User Panel</h1>

<style jsx>{`
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    41.99%, 42%, 48%, 49% { opacity: 0; }
    43.01%, 49.01% { opacity: 1; }
  }
`}</style>

          </DivTag>
 

            <p className="p-8">
            <label className="text-white font-semibold text-xl">User Name</label>
            <div className="flex flex-row gap-1 mt-1 bg-white/80 items-center rounded-md py-2 mb-3 px-1 w-full border-blue-500 shadow-md shadow-blue-700 transition duration-300 ease-in-out transform hover:scale-105">
              <FaRegUser size={26} color='blue' />
              <InputField type="text" className="bg-inherit focus:outline-none pl-1 w-full uppsercase"
                id="user_id"
                name="user_id"
                value={user_id}
                onChange={(e) => setUsername(e.target.value.toUpperCase())}
                placeholder='Enter User Name'
                required
                autofocus={true}
                
              />
            </div>
            <label className="text-white font-semibold text-xl">Password</label>
            <div className="flex flex-row gap-1 bg-white/80 rounded-md py-2 px-1 w-full border-blue-500 mt-1 shadow-md shadow-blue-700 transition duration-300 ease-in-out transform hover:scale-105">
              <RiLockPasswordFill size={26} color='blue' />
              <InputField className="bg-inherit focus:outline-none pl-1 w-full"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                
                placeholder="Enter Password"
              />
            </div>
            </p>

            <div className="flex justify-center transform md:scale-75 sm:scale-75 xl:scale-75 2xl:scale-100  origin-center transition duration-300 ease-in-out">
  <ReCAPTCHA
    sitekey="6LdVjzIqAAAAAGc1B3BkXGFyUZmHTUYyI1aE_FtA"
    onChange={handleCaptchaChange}
  />
</div>

            <DivTag className="mt-5 flex flex-col items-center md:items-center">
                <Button
                    text={isLoading ? "Logging in..." : "Login"}
                    className={`text-center text-white bg-blue-900 shadow-xl  shadow-white  bg-gradient-to-r from-blue-400 to-blue-900 font-semibold rounded-md px-3 py-1 text-xl hover:from-blue-900 hover:to-blue-400 border-1 ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    id="login"
                    name="login"
                    onClick={handleLogin} 
                    disabled={isLoading}
                  >
                    </Button>
          
              <Link to="/" className="text-white mt-10 md:mb-0 mb-24 font-bold shadow-lg text-dark underline text-2xl">Forgot Password</Link>
             
            </DivTag>
          </DivTag>
        </FormTag>
      </DivTag>
     
         <ToastContainer />
    </>
  );
};

export default LoginUserPage;









