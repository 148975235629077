import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { DivTag, ImgTag } from '../Tags/Tags';

const SliderCrousel = () => {
  const [showCarousel, setShowCarousel] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowCarousel(window.innerWidth > 600); // Adjust the width threshold as needed
    };
    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {showCarousel && (
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          transitionTime={1000} // Transition duration between slides (ms)
          interval={6000} // Time each slide stays on screen (ms)
          showThumbs={false}
          showIndicators={true}
          className="w-[26%] invisible sm:visible"
        >
          <DivTag className="w-full overflow-hidden">
            <ImgTag src="assets/images/NGO-IDSPay-Software.png" alt="sideimg" className="w-full h-auto" />
          </DivTag>
          <DivTag className="w-full">
            <ImgTag src="assets/images/Patho-Lab IDSPay-Software.png" alt="sideimg" className="w-full h-auto" />
          </DivTag>
          <DivTag className="w-full">
            <ImgTag src="assets/images/IDSPay Softwares Products.png" alt="sideimg" className="w-full h-auto" />
          </DivTag>
          <DivTag className="w-full">
            <ImgTag src="assets/images/IDSPay CRM & HR Software.png" alt="sideimg" className="w-full h-auto" />
          </DivTag>
        </Carousel>
      )}
    </>
  );
};

export default SliderCrousel;