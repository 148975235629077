
import React from 'react';
import { DivTag } from './Tags/Tags';

const PopupModal = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
<DivTag className="fixed inset-0 bg-gray-600 bg-opacity-60 flex justify-center items-center z-20  p-6">
      <DivTag className="bg-white rounded-lg  max-w-md w-full relative p-2 border border-blue-400 shadow-md shadow-blue-500">
       
        
        {children}
      </DivTag>
    </DivTag>
  );
};

export default PopupModal;




