import React from 'react'
import { CiFaceSmile } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { DivTag, HeadTag, PTag, ImgTag } from '../Tags/Tags';

const TestimonialCard = () => {
    return (

        <DivTag className="bg-blue-900 text-white p-3 mt-10 md:w-1/3 rounded-lg  h-screen md:h-1/2 ">
            <DivTag className="flex items-center justify-between">
                <DivTag className=" font-bold size-25 ">
                    <img src="assets/images/idspay_logo_log2.png" alt="logo" /></DivTag>
            </DivTag>
            <HeadTag className=" font-bold mt-4 flex items-center justify-between text-center" text="Plans Designed for Every Business" />
            <PTag className="mt-2" text="Choose plan that works best for you. Feel free to connect with us if you face any kind of issue." />
            <DivTag className=" p-2 mr-5 rounded-md  bg-blue-300">
                <PTag className="italic" text="Amazing and totally satisfied with the service." />
                <DivTag className="flex items-center mt-6">
                    <CiUser color='white' text-left size={80} />
                    <DivTag className="ml-2">
                        <PTag className="text-sm font-semibold text-white" text="Anna Jonas" />
                        <PTag className="text-xs text-white" text="Cras sit" />
                    </DivTag>
                </DivTag>
            </DivTag>
            <DivTag className="flex items-center mt-4 bg-white rounded-md">
                <CiFaceSmile color='blue' />
                <PTag className="text-lg text-black border-lg rounded" text="24000+ Happy Customers" />
            </DivTag>
        </DivTag>
    );
};

export default TestimonialCard
