import React, { useEffect, useState } from 'react';
import { DivTag, PTag, Button } from '../../Components/Tags/Tags';
import Header from '../../Components/Header';
import { AddIPService, IPRetriveService, ToggleStatusService } from '../../Services/AddBankAC.js/IpService';
import { FaInbox } from 'react-icons/fa';
import moment from 'moment';
import Seo from '../../Components/SeoComponent/Seo';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';
import { showSuccessToast,showErrorToast } from '../../utils/toastUtils';
import Loader from '../../Loader';

const WhiteListIP = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ipAddressData, setIpAddressData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [showLoader, setShowLoader] = useState(false);


  const handleInputChange = (e) => {
    const value = e.target.value;
    setIpAddress(value);
    setIsValid(value.length >= 5); // Validate IP length for simplicity
  };
  
  const handleWhitelist = async () => {
    if (isValid) {
      await addIPAddress(); // Wait for the IP address to be added
      await ipAddressDataRetrieve(); 
    }
  };
  
// Add IP Service
const addIPAddress = async () => {
  try {
    setIsLoading(true);
    const response = await AddIPService({ ip_address: ipAddress });
    if(response.status==422){
      return showErrorToast(response.data.errors.ip_address[0])
    }
    setIpAddress('');
    showSuccessToast("IP has been successfully whitelisted");
    setIsLoading(false); 
    await ipAddressDataRetrieve(); 
    return
  } catch (error) {
    setIsLoading(false)
  return showErrorToast(error)
  } finally {
    setIsLoading(false);
  }
};

  // Retrieve IP Data
  const ipAddressDataRetrieve = async (page = currentPage) => {
    try {
      setIsLoading(true);
    
      const response = await IPRetriveService(page);
     // console.log(response);
      setIpAddressData(response.data);
      setPaginationData(response.pagination);
      setCurrentPage(response.pagination.current_page);
      setLastPage(response.pagination.last_page);
    } catch (error) {
      console.error("Error fetching IP address data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Use Effect for Initial Data Fetch
  useEffect(() => {
    ipAddressDataRetrieve(); // Fetch data on component mount and page change
  }, [currentPage]);
  
  // Toggle Status Service
  const toggleStatus = async () => {
    try {
      const response = await ToggleStatusService({ ip_id: selectedId });
      console.log("Status toggled:", response);
      await ipAddressDataRetrieve(currentPage); // Refresh data after status change
    } catch (error) {
      console.error("Error toggling status:", error);
    } finally {
      closeModal(); // Close modal after status change
    }
  };
  
  // Modal Handling
  const openModal = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };
  
  const closeModal = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  return (
    <>
      <Seo />
      {isLoading && <Loader />}
      <ConfirmPopUp
        onConfirm={toggleStatus}
        visible={showModal}
        onClose={closeModal}
        title="Are you Sure Want to Submit"
      />
      <DivTag className="text-center">
        <Header heading='WhiteList IP' />
        <DivTag className="mb-4 text-center ">
          <DivTag className="flex justify-center">
            <PTag className='mr-20 font-semibold' text="Enter IP Address" />
          </DivTag>
          <input
            type="text"
            id="ipAddress"
            name="ipAddress"
            className="border p-1 rounded mr-2 ${isValid ? 'border-gray-300' : 'border-red-500 shadow-lg shadow-blue-500/50 text-center hover:border-spacing-2 border-blue-900"
            placeholder="Enter IP Address"
            value={ipAddress}
            onChange={handleInputChange}
          />
          <Button
            type="button"
            onClick={handleWhitelist}
            className="bg-gradient-to-r from-cyan-300 to-blue-900 ... text-white p-1 rounded disabled:opacity-50 border-sky-300 shadow-lg shadow-blue-500/50 ..."
            disabled={!isValid}
          >
            Whitelist
          </Button>
        </DivTag>
        {!isValid && (
          <PTag className="text-red-600" text="IP address must be at least 5 characters." />
        )}

        {isWhitelisted && (
          <>
            <PTag className="text-green-600" text="IP address successfully whitelisted!" />
          </>
        )}
        <>

          {ipAddressData && ipAddressData.length > 0 ? (
            <div className="overflow-x-auto my-1 p-2">
          <table id="table" className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className='bg-blue-900 text-center text-white'>
                  <th className="border-2 p-2">Sno</th>
                  <th className="border-2">IP Address</th>
                  <th className="border-2">Created At</th>
                  <th className="border-2">Token ID</th>
                  <th className="border-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {ipAddressData.map((item, index) => (
                  <tr key={(currentPage - 1) * (lastPage) + index + 1} className='text-center'>
                    <td className="border shadow-inner shadow-blue-600 p-1">{(currentPage - 1) * (paginationData?.per_page) + index + 1}</td>
                    <td className="border shadow-inner shadow-blue-400 p-1">{item?.ip_address}</td>
                    <td className="border shadow-inner shadow-blue-400 p-1">{moment(item.created_at).format('YYYY/MM/DD')}</td>
                    <td className="border shadow-inner shadow-blue-400 p-1">{item.token_id}</td>
                    <td className='border shadow-inner shadow-blue-400 p-1'>
                      <div className='flex justify-center gap-1'>
                        <p className='font-semibold'>Off</p><button className='border h-6 w-14 rounded-2xl bg-white  shadow-lg  border-sky-400 ' onClick={() => openModal(item.id)}>
                          <div className={item.status.value === "active" ? 'bg-green-500 translate-x-8 duration-200 transition-all rounded-full h-6 w-6' : "bg-red-500 rounded-full h-6 w-6 translate-x-0.1 duration-200 transition-all"} />
                        </button> <p className='font-semibold'>On</p></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          ) : (
            <div className='flex items-center mt-4 gap-4 text-xl text-neutral-600 justify-center'>
              <FaInbox className='text-blue-700' />
              <p className='flex justify-center items-center'>Data not found</p>
            </div>
          )}
          <div className='gap-2 mt-3'>
            <div className='flex justify-center gap-3'>
              <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-500 text-white border-none cursor-pointer"
               onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1} >Prev</button>
              <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-900 text-white border-none cursor-pointer"
               onClick={() =>setCurrentPage((prev) => Math.min(prev + 1, paginationData?.last_page || 1))}
                disabled={currentPage === paginationData?.last_page} >Next</button>
              <div className='flex justify-end'>
                <p className='text-[17px] font-bold'>Page {currentPage} of {lastPage}</p>
              </div>
            </div>
          </div>
        </>
      </DivTag>
    </>
  );
};
export default WhiteListIP;