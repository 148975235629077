import React, { useState } from 'react';
import { DivTag, Button, InputWithLabel, SelectWithLabelOption, FormValidation } from '../../Components/Tags/Tags';
import Header from '../../Components/Header';
import FieldValidation from '../../Components/FieldValidation/FieldValidation';

import { useTable } from 'react-table';
import Seo from '../../Components/SeoComponent/Seo';

const PayoutReport = () => {


    const initialState = {
        from_date: '',
        to_date: '',
        status: '',
        txn_id: '',
    };

    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }
      
        if (!values.txn_id) {
            errors.txn_id = 'Txn ID is required';
        }

        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FieldValidation(
        initialState,
        validate
    );






// Define your columns and data
const data = React.useMemo(
    () => [
      {
        col1: '1',
        col2: 'yes',
        col3: 'Abin984',
        col4: 'ut898',
        col5: 'Nics',
        col6: 'txn',
        col7: 'ap837',
        col8: '3.453',
        col9: '7.484',
        col10: '12/12/2000'
      },
      {
        col1: '2',
        col2: 'No',
        col3: 'Abin982',
        col4: 'ut988',
        col5: 'znumpy',
        col6: '98tx',
        col7: 'oiu837',
        col8: '4.453',
        col9: '8.484',
        col10: '11/11/2024'
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'SNo.',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Beneficiary A/C No',
        accessor: 'col2',
      },
      {
        Header: 'IFSC',
        accessor: 'col3',
      },
      {
        Header: 'Utr',
        accessor: 'col4',
      },
      {
        Header: 'Company Name',
        accessor: 'col5',
      },
      {
        Header: 'Txn ID',
        accessor: 'col6',
      },
      {
        Header: 'Api Txn ID',
        accessor: 'col7',
      },
      {
        Header: 'Txn Amt',
        accessor: 'col8',
      },
      {
        Header: 'charges',
        accessor: 'col9',
      },
      {
        Header: 'Txn Date',
        accessor: 'col10',
      },
    ],
    []
  );

  // Use the useTable hook to get table properties
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });


    return (
      <>
      <Seo />
        <DivTag>
            <Header heading='Payout Report' />
                <DivTag className="flex flex-wrap justify-center mt-3">

                    <InputWithLabel
                        DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                        label="From Date"
                        type="date"
                        name="from_date"
                        id="from_date"
                        required={true}
                        requiredfield={true}
                        value={values.from_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                        error={errors.from_date}
                    />


                    <InputWithLabel
                        DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                        label="To Date"
                        type="date"
                        name="to_date"
                        id="to_date"
                        required={true}
                        requiredfield={true}
                        value={values.to_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                        error={errors.to_date}
                    />

                    <InputWithLabel
                        DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                        label="TxnID/UTR"
                        type="text"
                        placeholder="Enter Txnid/Utr"
                        name="txn_id"
                        id="txn_id"
                        required={true}
                        value={values.txn_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.txn_id ? 'border border-red-500' : 'border border-blue-600'}
                        error={errors.txn_id}
                    />



                    <SelectWithLabelOption
                        DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                        label="Status"
                        id="status"
                        name="status"
                        required={false}
                        requiredfield={true}
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.status ? 'border border-red-500' : 'border border-blue-600'}
                        error={errors.status}
                    >
                        <option value="" disabled selected>Select Status</option>
                        <option value="New"></option>
                        <option value="De-Assigned"></option>
                    </SelectWithLabelOption>

                </DivTag>

           

            <DivTag className="flex justify-center mt-4">
                <Button
                    type="button"
                    id="submit"
                    onClick=""
                    text="Search"
                />
            </DivTag>

<DivTag className="overflow-x-auto max-w-full md:w-full mt-4">
      <table {...getTableProps()} className="divide-y divide-red-200">
        <thead className="bg-blue-700 rounded-md text-white">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className="px-4 py-3 text-center text-xs font-medium text-white border-2 uppercase tracking-wider"
                >
                  {column.render('Header')}
                </th>
              ))} 
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-500">
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-800"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </DivTag>


        </DivTag>
        </>

    );
};

export default PayoutReport;

















