import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description, url, image, structuredData }) => {
  const defaultStructuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description,
    "url": url,
  };

  return (
    <Helmet>
      <title>IDSPay API User Login</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <link rel="icon" href="assets/images/infocargroup logo.png" />
      <script type="application/ld+json">
        {JSON.stringify(structuredData || defaultStructuredData)}
      </script>
    </Helmet>
  );
};

export default Seo;