// src/Components/Loader.js
import React from 'react';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="relative w-20 h-20 flex items-center justify-center">
        <div className="absolute w-full h-full border-4 border-blue-800 border-t-4 border-solid rounded-full animate-spin"></div>
        <img 
          className="w-24 h-16 object-contain animate-bounce"
          src={process.env.PUBLIC_URL + '/media/logo/idspay_circle_white.png'} 
          alt="logo" 
        />
      </div>
    </div>
  );
};

export default Loader
