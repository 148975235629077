export const CreditData = {
    "InquiryResponseHeader": {
      "ClientID": "010FZ00385",
      "CustRefField": "ref235",
      "ReportOrderNO": "1596154618",
      "ProductCode": [
        "CCR"
      ],
      "SuccessCode": "1",
      "Date": "2024-10-08",
      "Time": "11:42:54"
    },
    "InquiryRequestInfo": {
      "InquiryPurpose": "01",
      "FirstName": "RAM SINGH BHANDARI",
      "InquiryAddresses": [
        {
          "seq": "1",
          "AddressType": [
            "H"
          ],
          "AddressLine1": "169 RAJEEV NAGAR  TARLI KANDOLI DEHRADUN UTTARAKHAND DEHRADUN",
          "State": "UK",
          "Postal": "249145"
        }
      ],
      "InquiryPhones": [
        {
          "seq": "1",
          "PhoneType": [
            "M"
          ],
          "Number": "8191817766"
        }
      ],
      "IDDetails": [
        {
          "seq": "1",
          "IDType": "T",
          "IDValue": "ARSPB2789E",
          "Source": "Inquiry"
        },
        {
          "seq": "2",
          "IDType": "P",
          "Source": "Inquiry"
        },
        {
          "seq": "3",
          "IDType": "V",
          "Source": "Inquiry"
        },
        {
          "seq": "4",
          "IDType": "D",
          "Source": "Inquiry"
        },
        {
          "seq": "5",
          "IDType": "M",
          "Source": "Inquiry"
        },
        {
          "seq": "6",
          "IDType": "R",
          "Source": "Inquiry"
        },
        {
          "seq": "7",
          "IDType": "O",
          "Source": "Inquiry"
        }
      ],
      "DOB": "1960-05-30",
      "MFIDetails": {
        "FamilyDetails": [
          {
            "seq": "1",
            "AdditionalNameType": "O",
            "AdditionalName": "krs"
          }
        ]
      }
    },
    "Score": [
      {
        "Type": "ERS",
        "Version": "4.0"
      }
    ],
    "CCRResponse": {
      "Status": "1",
      "CIRReportDataLst": [
        {
          "InquiryResponseHeader": {
            "CustomerCode": "SZYG",
            "CustRefField": "ref235",
            "ReportOrderNO": "1596154618",
            "ProductCode": [
              "PCS"
            ],
            "SuccessCode": "1",
            "Date": "2024-10-08",
            "Time": "11:42:54",
            "HitCode": "10",
            "CustomerName": "SZYG"
          },
          "InquiryRequestInfo": {
            "InquiryPurpose": "Auto Loan (Personal)",
            "FirstName": "RAM SINGH BHANDARI",
            "InquiryAddresses": [
              {
                "seq": "1",
                "AddressType": [
                  "H"
                ],
                "AddressLine1": "169 RAJEEV NAGAR  TARLI KANDOLI DEHRADUN UTTARAKHAND DEHRADUN",
                "State": "UK",
                "Postal": "249145"
              }
            ],
            "InquiryPhones": [
              {
                "seq": "1",
                "PhoneType": [
                  "M"
                ],
                "Number": "8191817766"
              }
            ],
            "IDDetails": [
              {
                "seq": "1",
                "IDType": "T",
                "IDValue": "ARSPB2789E",
                "Source": "Inquiry"
              }
            ],
            "DOB": "1960-05-30",
            "MFIDetails": {
              "FamilyDetails": [
                {
                  "seq": "1",
                  "AdditionalNameType": "O",
                  "AdditionalName": "krs"
                }
              ]
            }
          },
          "Score": [
            {
              "Type": "ERS",
              "Version": "4.0"
            }
          ],
          "CIRReportData": {
            "IDAndContactInfo": {
              "PersonalInfo": {
                "Name": {
                  "FullName": "RAM SINGH BHANDARI ",
                  "FirstName": "RAM ",
                  "MiddleName": "SINGH ",
                  "LastName": "BHANDARI "
                },
                " AliasName": [],
                "DateOfBirth": "1960-05-30",
                "Gender": "Male",
                "Age": {
                  "Age": "64"
                },
                "PlaceOfBirthInfo": []
              },
              "IdentityInfo": {
                "PANId": [
                  {
                    "seq": "1",
                    "ReportedDate": "2024-08-31",
                    "IdNumber": "ARSPB2789E"
                  }
                ],
                "NationalIDCard": [
                  {
                    "seq": "1",
                    "ReportedDate": "2018-08-31",
                    "IdNumber": "XXXXXXXXXXXX"
                  }
                ],
                "RationCard": [
                  {
                    "seq": "1",
                    "ReportedDate": "2015-03-31",
                    "IdNumber": "DDN171419"
                  }
                ],
                "OtherId": [
                  {
                    "seq": "1",
                    "ReportedDate": "2019-08-31",
                    "IdNumber": "81022246083"
                  }
                ]
              },
              "AddressInfo": [
                {
                  "Seq": "1",
                  "ReportedDate": "2024-08-31",
                  "Address": "169 RAJEEV NAGAR  TARLI KANDOLI RAJPUR ROAD",
                  "State": "UL",
                  "Postal": "248001",
                  "Type": "Primary"
                },
                {
                  "Seq": "2",
                  "ReportedDate": "2024-08-31",
                  "Address": "169 JAEEV NAGAR  KANDOLI",
                  "State": "UL",
                  "Postal": "248001",
                  "Type": "Office"
                },
                {
                  "Seq": "3",
                  "ReportedDate": "2023-10-01",
                  "Address": "169 RAJEEV NAGAR  TARLI KONDOLI",
                  "State": "UL",
                  "Postal": "248001",
                  "Type": "Permanent"
                },
                {
                  "Seq": "4",
                  "ReportedDate": "2018-04-30",
                  "Address": "169 RAJEEV NAGAR  TARLI KONDOLI",
                  "State": "PY",
                  "Postal": "248001",
                  "Type": "Primary"
                },
                {
                  "Seq": "5",
                  "ReportedDate": "2017-05-31",
                  "Address": "169 RAJEEV NAGAR  TARLI KANDOLI DEHRADUN UTTARAKHAND DEHRADUN",
                  "State": "UL",
                  "Postal": "249145"
                }
              ],
              "PhoneInfo": [
                {
                  "seq": "1",
                  "typeCode": "H",
                  "ReportedDate": "2024-08-31",
                  "Number": "8191817766"
                },
                {
                  "seq": "2",
                  "typeCode": "M",
                  "ReportedDate": "2024-08-31",
                  "Number": "8191817766"
                },
                {
                  "seq": "3",
                  "typeCode": "M",
                  "ReportedDate": "2023-10-01",
                  "Number": "8958994992"
                },
                {
                  "seq": "4",
                  "typeCode": "T",
                  "ReportedDate": "2024-08-31",
                  "Number": "8191817766"
                }
              ]
            },
            "RetailAccountDetails": [
              {
                "seq": "1",
                "AccountNumber": "**********",
                "Institution": "BANK",
                "AccountType": "Auto Loan",
                "OwnershipType": "Joint Account",
                "Balance": "344231",
                "PastDueAmount": "0",
                "LastPayment": "12328",
                "Open": "Yes",
                "SanctionAmount": "607998",
                "LastPaymentDate": "2024-08-08",
                "DateReported": "2024-08-31",
                "DateOpened": "2022-03-19",
                "TermFrequency": "Monthly",
                "AccountStatus": "Current Account",
                "AssetClassification": "Standard",
                "source": "INDIVIDUAL",
                "History48Months": [
                  {
                    "key": "08-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-24",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "12-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "05-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-22",
                    "PaymentStatus": "NEW",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  }
                ]
              },
              {
                "seq": "2",
                "AccountNumber": "**********",
                "Institution": "BANK",
                "AccountType": "Auto Loan",
                "OwnershipType": "Joint Account",
                "Balance": "81482",
                "PastDueAmount": "6274",
                "LastPayment": "6274",
                "Open": "Yes",
                "SanctionAmount": "386761",
                "LastPaymentDate": "2024-08-14",
                "DateReported": "2024-08-31",
                "DateOpened": "2018-04-04",
                "TermFrequency": "Monthly",
                "AccountStatus": "1-29 days past due",
                "AssetClassification": "Standard",
                "source": "INDIVIDUAL",
                "History48Months": [
                  {
                    "key": "08-24",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-24",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-24",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-24",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-24",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-24",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-24",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "12-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-23",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-23",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-22",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-22",
                    "PaymentStatus": "30+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-22",
                    "PaymentStatus": "30+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-22",
                    "PaymentStatus": "30+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-22",
                    "PaymentStatus": "01+",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-22",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-21",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-20",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-20",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-20",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-20",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  }
                ]
              },
              {
                "seq": "3",
                "AccountNumber": "**********",
                "Institution": "FINANCE",
                "AccountType": "Two-wheeler Loan",
                "OwnershipType": "Individual",
                "Balance": "0",
                "PastDueAmount": "0",
                "Open": "No",
                "SanctionAmount": "40000",
                "LastPaymentDate": "2019-04-30",
                "DateReported": "2023-10-01",
                "DateOpened": "2018-03-21",
                "DateClosed": "2019-04-30",
                "Reason": "Closed Account",
                "AccountStatus": "Closed Account",
                "source": "INDIVIDUAL",
                "History48Months": [
                  {
                    "key": "10-23",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "09-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "08-23",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "07-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "06-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "05-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "04-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "03-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "02-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "01-23",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "12-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "11-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "10-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "09-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "08-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "07-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "06-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "05-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "04-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "03-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "02-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "01-22",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "12-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "11-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "10-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "09-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "08-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "07-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "06-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "05-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "04-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "03-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "02-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "01-21",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "12-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-20",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "04-20",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "03-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-20",
                    "PaymentStatus": "*",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "*"
                  },
                  {
                    "key": "01-20",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-19",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-19",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  }
                ]
              },
              {
                "seq": "4",
                "AccountNumber": "**********",
                "Institution": "BANK",
                "AccountType": "Personal Loan",
                "OwnershipType": "Individual",
                "Balance": "0",
                "Open": "No",
                "SanctionAmount": "300000",
                "DateReported": "2022-04-30",
                "DateOpened": "2017-02-06",
                "DateClosed": "2022-04-28",
                "Reason": "Closed Account",
                "InterestRate": "12.000",
                "RepaymentTenure": "70",
                "InstallmentAmount": "0",
                "TermFrequency": "Monthly",
                "AccountStatus": "Closed Account",
                "AssetClassification": "Standard",
                "source": "INDIVIDUAL",
                "History48Months": [
                  {
                    "key": "04-22",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-22",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-22",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-22",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-21",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-20",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-19",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-18",
                    "PaymentStatus": "STD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  }
                ]
              },
              {
                "seq": "5",
                "AccountNumber": "**********",
                "Institution": "BANK",
                "AccountType": "Personal Loan",
                "OwnershipType": "Individual",
                "Balance": "0",
                "Open": "No",
                "SanctionAmount": "100000",
                "DateReported": "2016-11-30",
                "DateOpened": "2013-05-07",
                "DateClosed": "2016-11-30",
                "Reason": "Closed Account",
                "InterestRate": "14.000",
                "RepaymentTenure": "60",
                "InstallmentAmount": "0",
                "TermFrequency": "Monthly",
                "AccountStatus": "Closed Account",
                "AssetClassification": "Standard",
                "source": "INDIVIDUAL",
                "History48Months": [
                  {
                    "key": "11-16",
                    "PaymentStatus": "CLSD",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-16",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-15",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "04-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "03-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "02-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "01-14",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "12-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "11-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "10-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "09-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "08-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "07-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "06-13",
                    "PaymentStatus": "000",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  },
                  {
                    "key": "05-13",
                    "PaymentStatus": "NEW",
                    "SuitFiledStatus": "*",
                    "AssetClassificationStatus": "STD"
                  }
                ]
              }
            ],
            "RetailAccountsSummary": {
              "NoOfAccounts": "5",
              "NoOfActiveAccounts": "2",
              "NoOfWriteOffs": "0",
              "TotalPastDue": "6274.00",
              "MostSevereStatusWithIn24Months": "30+",
              "SingleHighestCredit": "0.00",
              "SingleHighestSanctionAmount": "607998.00",
              "TotalHighCredit": "0.00",
              "AverageOpenBalance": "212856.50",
              "SingleHighestBalance": "344231.00",
              "NoOfPastDueAccounts": "1",
              "NoOfZeroBalanceAccounts": "0",
              "RecentAccount": "Auto Loan on 19-03-2022",
              "OldestAccount": "Personal Loan on 07-05-2013",
              "TotalBalanceAmount": "425713.00",
              "TotalSanctionAmount": "994759.00",
              "TotalCreditLimit": "0.0",
              "TotalMonthlyPaymentAmount": "0.00"
            },
            "ScoreDetails": [
              {
                "Type": "ERS",
                "Version": "4.0",
                "Name": "ERS4.0",
                "Value": "736",
                "ScoringElements": [
                  {
                    "type": "RES",
                    "seq": "1",
                    "code": "703",
                    "Description": "Total Utilization"
                  },
                  {
                    "type": "RES",
                    "seq": "2",
                    "code": "701",
                    "Description": "Overdue Amount"
                  },
                  {
                    "type": "RES",
                    "seq": "3",
                    "code": "707",
                    "Description": "Delinquency Presence"
                  }
                ]
              }
            ],
            "Enquiries": [
              {
                "seq": "1",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-04",
                "Time": "14:55",
                "RequestPurpose": "00"
              },
              {
                "seq": "2",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-04",
                "Time": "14:40",
                "RequestPurpose": "00"
              },
              {
                "seq": "3",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-03",
                "Time": "14:50",
                "RequestPurpose": "05"
              },
              {
                "seq": "4",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-03",
                "Time": "14:33",
                "RequestPurpose": "05"
              },
              {
                "seq": "5",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-03",
                "Time": "12:46",
                "RequestPurpose": "05"
              },
              {
                "seq": "6",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-03",
                "Time": "10:48",
                "RequestPurpose": "05"
              },
              {
                "seq": "7",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "18:01",
                "RequestPurpose": "05"
              },
              {
                "seq": "8",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "17:14",
                "RequestPurpose": "05"
              },
              {
                "seq": "9",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "17:03",
                "RequestPurpose": "05"
              },
              {
                "seq": "10",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "17:00",
                "RequestPurpose": "05"
              },
              {
                "seq": "11",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "16:00",
                "RequestPurpose": "05"
              },
              {
                "seq": "12",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "15:37",
                "RequestPurpose": "05"
              },
              {
                "seq": "13",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "15:35",
                "RequestPurpose": "05"
              },
              {
                "seq": "14",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "15:27",
                "RequestPurpose": "05"
              },
              {
                "seq": "15",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "15:17",
                "RequestPurpose": "05"
              },
              {
                "seq": "16",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "15:09",
                "RequestPurpose": "05"
              },
              {
                "seq": "17",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "15:02",
                "RequestPurpose": "05"
              },
              {
                "seq": "18",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "14:25",
                "RequestPurpose": "05"
              },
              {
                "seq": "19",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "14:22",
                "RequestPurpose": "05"
              },
              {
                "seq": "20",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "14:17",
                "RequestPurpose": "05"
              },
              {
                "seq": "21",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "14:14",
                "RequestPurpose": "05"
              },
              {
                "seq": "22",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "14:12",
                "RequestPurpose": "05"
              },
              {
                "seq": "23",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "13:39",
                "RequestPurpose": "05"
              },
              {
                "seq": "24",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "13:31",
                "RequestPurpose": "05"
              },
              {
                "seq": "25",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "13:29",
                "RequestPurpose": "05"
              },
              {
                "seq": "26",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "12:09",
                "RequestPurpose": "05"
              },
              {
                "seq": "27",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "12:08",
                "RequestPurpose": "05"
              },
              {
                "seq": "28",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "12:07",
                "RequestPurpose": "05"
              },
              {
                "seq": "29",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "12:07",
                "RequestPurpose": "05"
              },
              {
                "seq": "30",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "12:07",
                "RequestPurpose": "05"
              },
              {
                "seq": "31",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "12:04",
                "RequestPurpose": "05"
              },
              {
                "seq": "32",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:58",
                "RequestPurpose": "05"
              },
              {
                "seq": "33",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:54",
                "RequestPurpose": "05"
              },
              {
                "seq": "34",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:49",
                "RequestPurpose": "05"
              },
              {
                "seq": "35",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:47",
                "RequestPurpose": "05"
              },
              {
                "seq": "36",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:43",
                "RequestPurpose": "05"
              },
              {
                "seq": "37",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:42",
                "RequestPurpose": "05"
              },
              {
                "seq": "38",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:39",
                "RequestPurpose": "05"
              },
              {
                "seq": "39",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:30",
                "RequestPurpose": "05"
              },
              {
                "seq": "40",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:26",
                "RequestPurpose": "05"
              },
              {
                "seq": "41",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:22",
                "RequestPurpose": "05"
              },
              {
                "seq": "42",
                "Institution": "7FINMONEY MICROFINANCE PRIVATE LIMITED",
                "Date": "2024-10-01",
                "Time": "11:14",
                "RequestPurpose": "05"
              },
              {
                "seq": "43",
                "Institution": "Subhkiran Capital Limited",
                "Date": "2024-09-18",
                "Time": "18:56",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "44",
                "Institution": "FINANCE",
                "Date": "2024-07-10",
                "Time": "11:28",
                "RequestPurpose": "0E"
              },
              {
                "seq": "45",
                "Institution": "FINANCE",
                "Date": "2024-05-31",
                "Time": "15:19",
                "RequestPurpose": "0E"
              },
              {
                "seq": "46",
                "Institution": "FINANCE",
                "Date": "2024-05-31",
                "Time": "15:19",
                "RequestPurpose": "0E"
              },
              {
                "seq": "47",
                "Institution": "FINANCE",
                "Date": "2024-05-31",
                "Time": "15:18",
                "RequestPurpose": "0E"
              },
              {
                "seq": "48",
                "Institution": "FINANCE",
                "Date": "2024-05-09",
                "Time": "15:34",
                "RequestPurpose": "0E"
              },
              {
                "seq": "49",
                "Institution": "FINANCE",
                "Date": "2024-04-25",
                "Time": "00:55",
                "RequestPurpose": "25",
                "Amount": "0"
              },
              {
                "seq": "50",
                "Institution": "FINANCE",
                "Date": "2024-04-24",
                "Time": "21:58",
                "RequestPurpose": "21",
                "Amount": "400000"
              },
              {
                "seq": "51",
                "Institution": "FINANCE",
                "Date": "2024-04-24",
                "Time": "21:45",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "52",
                "Institution": "Pratyancha Financial Services Limited",
                "Date": "2024-04-23",
                "Time": "07:40",
                "RequestPurpose": "00"
              },
              {
                "seq": "53",
                "Institution": "Pratyancha Financial Services Limited",
                "Date": "2024-04-17",
                "Time": "12:17",
                "RequestPurpose": "00"
              },
              {
                "seq": "54",
                "Institution": "FINANCE",
                "Date": "2024-04-05",
                "Time": "16:47",
                "RequestPurpose": "05"
              },
              {
                "seq": "55",
                "Institution": "FINANCE",
                "Date": "2024-04-02",
                "Time": "18:24",
                "RequestPurpose": "05"
              },
              {
                "seq": "56",
                "Institution": "FINANCE",
                "Date": "2024-03-04",
                "Time": "18:46",
                "RequestPurpose": "05"
              },
              {
                "seq": "57",
                "Institution": "FINANCE",
                "Date": "2024-03-04",
                "Time": "18:23",
                "RequestPurpose": "05"
              },
              {
                "seq": "58",
                "Institution": "FINANCE",
                "Date": "2024-02-28",
                "Time": "11:32",
                "RequestPurpose": "0E"
              },
              {
                "seq": "59",
                "Institution": "FINANCE",
                "Date": "2024-02-13",
                "Time": "19:50",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "60",
                "Institution": "YES BANK LIMITED",
                "Date": "2024-02-13",
                "Time": "15:31",
                "RequestPurpose": "0E"
              },
              {
                "seq": "61",
                "Institution": "FINANCE",
                "Date": "2024-01-11",
                "Time": "17:20",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "62",
                "Institution": "BANK",
                "Date": "2024-01-04",
                "Time": "19:54",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "63",
                "Institution": "BANK",
                "Date": "2024-01-04",
                "Time": "19:53",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "64",
                "Institution": "BANK",
                "Date": "2024-01-04",
                "Time": "19:52",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "65",
                "Institution": "BANK",
                "Date": "2024-01-04",
                "Time": "19:48",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "66",
                "Institution": "FINANCE",
                "Date": "2023-12-20",
                "Time": "15:13",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "67",
                "Institution": "FINANCE",
                "Date": "2023-12-14",
                "Time": "00:58",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "68",
                "Institution": "FINANCE",
                "Date": "2023-12-13",
                "Time": "19:12",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "69",
                "Institution": "FINANCE",
                "Date": "2023-11-13",
                "Time": "15:40",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "70",
                "Institution": "FINANCE",
                "Date": "2023-11-07",
                "Time": "11:59",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "71",
                "Institution": "IIFL SAMASTA FINANCE LIMITED",
                "Date": "2023-11-02",
                "Time": "17:59",
                "RequestPurpose": "0E"
              },
              {
                "seq": "72",
                "Institution": "FINANCE",
                "Date": "2023-10-24",
                "Time": "16:02",
                "RequestPurpose": "06"
              },
              {
                "seq": "73",
                "Institution": "FINANCE",
                "Date": "2023-10-24",
                "Time": "15:40",
                "RequestPurpose": "06"
              },
              {
                "seq": "74",
                "Institution": "FINANCE",
                "Date": "2023-10-24",
                "Time": "15:35",
                "RequestPurpose": "06"
              },
              {
                "seq": "75",
                "Institution": "FINANCE",
                "Date": "2023-10-24",
                "Time": "12:07",
                "RequestPurpose": "06"
              },
              {
                "seq": "76",
                "Institution": "FINANCE",
                "Date": "2023-10-24",
                "Time": "11:55",
                "RequestPurpose": "0E"
              },
              {
                "seq": "77",
                "Date": "2023-10-23",
                "Time": "16:05",
                "RequestPurpose": "05"
              },
              {
                "seq": "78",
                "Institution": "FINANCE",
                "Date": "2023-10-20",
                "Time": "18:03",
                "RequestPurpose": "0E"
              },
              {
                "seq": "79",
                "Institution": "FINANCE",
                "Date": "2023-10-20",
                "Time": "18:02",
                "RequestPurpose": "0E"
              },
              {
                "seq": "80",
                "Institution": "FINANCE",
                "Date": "2023-10-11",
                "Time": "17:35",
                "RequestPurpose": "0E"
              },
              {
                "seq": "81",
                "Institution": "FINANCE",
                "Date": "2023-10-11",
                "Time": "16:35",
                "RequestPurpose": "0E"
              },
              {
                "seq": "82",
                "Institution": "FINANCE",
                "Date": "2023-10-11",
                "Time": "15:28",
                "RequestPurpose": "0E"
              },
              {
                "seq": "83",
                "Institution": "FINANCE",
                "Date": "2023-10-11",
                "Time": "11:25",
                "RequestPurpose": "0E"
              },
              {
                "seq": "84",
                "Institution": "FINANCE",
                "Date": "2023-10-11",
                "Time": "11:09",
                "RequestPurpose": "0E"
              },
              {
                "seq": "85",
                "Institution": "FINANCE",
                "Date": "2023-10-05",
                "Time": "17:26",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "86",
                "Institution": "FINANCE",
                "Date": "2023-10-05",
                "Time": "15:15",
                "RequestPurpose": "00",
                "Amount": "400000"
              },
              {
                "seq": "87",
                "Institution": "FINANCE",
                "Date": "2023-09-29",
                "Time": "14:12",
                "RequestPurpose": "0E"
              },
              {
                "seq": "88",
                "Institution": "FINANCE",
                "Date": "2023-09-29",
                "Time": "14:11",
                "RequestPurpose": "0E"
              },
              {
                "seq": "89",
                "Institution": "FINANCE",
                "Date": "2023-09-29",
                "Time": "12:32",
                "RequestPurpose": "0E"
              },
              {
                "seq": "90",
                "Institution": "FINANCE",
                "Date": "2023-09-29",
                "Time": "12:32",
                "RequestPurpose": "0E"
              },
              {
                "seq": "91",
                "Institution": "FINANCE",
                "Date": "2023-09-29",
                "Time": "12:07",
                "RequestPurpose": "0E"
              },
              {
                "seq": "92",
                "Institution": "FINANCE",
                "Date": "2023-09-29",
                "Time": "10:29",
                "RequestPurpose": "0E"
              },
              {
                "seq": "93",
                "Institution": "FINANCE",
                "Date": "2023-09-28",
                "Time": "18:55",
                "RequestPurpose": "0E"
              },
              {
                "seq": "94",
                "Institution": "FINANCE",
                "Date": "2023-09-28",
                "Time": "18:46",
                "RequestPurpose": "0E"
              },
              {
                "seq": "95",
                "Institution": "FINANCE",
                "Date": "2023-09-26",
                "Time": "13:20",
                "RequestPurpose": "0E"
              },
              {
                "seq": "96",
                "Institution": "FINANCE",
                "Date": "2023-09-08",
                "Time": "11:44",
                "RequestPurpose": "05"
              },
              {
                "seq": "97",
                "Institution": "FINANCE",
                "Date": "2023-09-08",
                "Time": "11:44",
                "RequestPurpose": "05"
              },
              {
                "seq": "98",
                "Institution": "FINANCE",
                "Date": "2023-09-08",
                "Time": "11:33",
                "RequestPurpose": "05"
              },
              {
                "seq": "99",
                "Institution": "FINANCE",
                "Date": "2023-09-08",
                "Time": "05:02",
                "RequestPurpose": "05"
              },
              {
                "seq": "100",
                "Institution": "FINANCE",
                "Date": "2023-09-08",
                "Time": "04:47",
                "RequestPurpose": "05"
              },
              {
                "seq": "101",
                "Institution": "FINANCE",
                "Date": "2023-09-08",
                "Time": "04:45",
                "RequestPurpose": "05"
              },
              {
                "seq": "102",
                "Institution": "FINANCE",
                "Date": "2023-09-07",
                "Time": "16:58",
                "RequestPurpose": "05"
              },
              {
                "seq": "103",
                "Institution": "FINANCE",
                "Date": "2023-09-07",
                "Time": "16:56",
                "RequestPurpose": "05"
              },
              {
                "seq": "104",
                "Institution": "FINANCE",
                "Date": "2023-09-07",
                "Time": "16:56",
                "RequestPurpose": "05"
              },
              {
                "seq": "105",
                "Institution": "FINANCE",
                "Date": "2023-09-07",
                "Time": "16:55",
                "RequestPurpose": "05"
              },
              {
                "seq": "106",
                "Institution": "BANK",
                "Date": "2023-08-07",
                "Time": "18:59",
                "RequestPurpose": "1E",
                "Amount": "45000"
              },
              {
                "seq": "107",
                "Institution": "BANK",
                "Date": "2023-08-07",
                "Time": "18:55",
                "RequestPurpose": "1E",
                "Amount": "45000"
              },
              {
                "seq": "108",
                "Institution": "FINANCE",
                "Date": "2023-07-27",
                "Time": "13:41",
                "RequestPurpose": "0E"
              },
              {
                "seq": "109",
                "Institution": "FINANCE",
                "Date": "2023-07-17",
                "Time": "23:15",
                "RequestPurpose": "0E",
                "Amount": "10000"
              },
              {
                "seq": "110",
                "Institution": "FINANCE",
                "Date": "2023-07-13",
                "Time": "18:20",
                "RequestPurpose": "0E"
              },
              {
                "seq": "111",
                "Institution": "BANK",
                "Date": "2023-07-11",
                "Time": "16:06",
                "RequestPurpose": "1E",
                "Amount": "30000"
              },
              {
                "seq": "112",
                "Institution": "FINANCE",
                "Date": "2023-06-30",
                "Time": "11:58",
                "RequestPurpose": "0E",
                "Amount": "35000"
              },
              {
                "seq": "113",
                "Institution": "FINANCE",
                "Date": "2023-06-30",
                "Time": "11:58",
                "RequestPurpose": "0E",
                "Amount": "35000"
              },
              {
                "seq": "114",
                "Institution": "FINANCE",
                "Date": "2023-06-30",
                "Time": "11:58",
                "RequestPurpose": "0E",
                "Amount": "35000"
              },
              {
                "seq": "115",
                "Institution": "FINANCE",
                "Date": "2023-06-30",
                "Time": "11:58",
                "RequestPurpose": "0E",
                "Amount": "35000"
              },
              {
                "seq": "116",
                "Institution": "FINANCE",
                "Date": "2023-06-30",
                "Time": "11:58",
                "RequestPurpose": "0E",
                "Amount": "35000"
              },
              {
                "seq": "117",
                "Institution": "FINANCE",
                "Date": "2023-06-29",
                "Time": "19:54",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "118",
                "Institution": "FINANCE",
                "Date": "2023-06-29",
                "Time": "15:35",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "119",
                "Institution": "FINANCE",
                "Date": "2023-06-29",
                "Time": "15:35",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "120",
                "Institution": "FINANCE",
                "Date": "2023-06-29",
                "Time": "15:35",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "121",
                "Institution": "FINANCE",
                "Date": "2023-06-29",
                "Time": "15:35",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "122",
                "Institution": "FINANCE",
                "Date": "2023-06-29",
                "Time": "15:35",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "123",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "124",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "125",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "126",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "127",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "128",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "129",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "130",
                "Institution": "FINANCE",
                "Date": "2023-06-28",
                "Time": "14:01",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "131",
                "Institution": "FINANCE",
                "Date": "2023-06-23",
                "Time": "16:21",
                "RequestPurpose": "0E",
                "Amount": "30000"
              },
              {
                "seq": "132",
                "Institution": "FINANCE",
                "Date": "2023-06-22",
                "Time": "15:18",
                "RequestPurpose": "0E",
                "Amount": "30000"
              },
              {
                "seq": "133",
                "Institution": "FINANCE",
                "Date": "2023-06-22",
                "Time": "15:18",
                "RequestPurpose": "0E",
                "Amount": "30000"
              },
              {
                "seq": "134",
                "Institution": "BANK",
                "Date": "2023-06-21",
                "Time": "21:59",
                "RequestPurpose": "1E",
                "Amount": "30000"
              },
              {
                "seq": "135",
                "Institution": "FINANCE",
                "Date": "2023-06-19",
                "Time": "14:29",
                "RequestPurpose": "0E",
                "Amount": "15000"
              },
              {
                "seq": "136",
                "Institution": "FINANCE",
                "Date": "2023-06-19",
                "Time": "14:29",
                "RequestPurpose": "0E",
                "Amount": "15000"
              },
              {
                "seq": "137",
                "Institution": "FINANCE",
                "Date": "2023-06-15",
                "Time": "12:03",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "138",
                "Institution": "FINANCE",
                "Date": "2023-06-15",
                "Time": "12:03",
                "RequestPurpose": "0E",
                "Amount": "25000"
              },
              {
                "seq": "139",
                "Institution": "FINANCE",
                "Date": "2023-06-14",
                "Time": "16:18",
                "RequestPurpose": "0E",
                "Amount": "10000"
              },
              {
                "seq": "140",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "141",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "142",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "143",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "144",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "145",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "146",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "147",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "148",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "149",
                "Institution": "FINANCE",
                "Date": "2023-06-05",
                "Time": "18:52",
                "RequestPurpose": "0E",
                "Amount": "50000"
              },
              {
                "seq": "150",
                "Institution": "FINANCE",
                "Date": "2023-06-01",
                "Time": "18:06",
                "RequestPurpose": "0E",
                "Amount": "10000"
              },
              {
                "seq": "151",
                "Institution": "FINANCE",
                "Date": "2023-06-01",
                "Time": "18:06",
                "RequestPurpose": "0E",
                "Amount": "10000"
              },
              {
                "seq": "152",
                "Institution": "FINANCE",
                "Date": "2023-05-29",
                "Time": "16:53",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "153",
                "Institution": "FINANCE",
                "Date": "2023-05-27",
                "Time": "14:25",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "154",
                "Institution": "FINANCE",
                "Date": "2023-05-09",
                "Time": "17:35",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "155",
                "Institution": "FINANCE",
                "Date": "2023-05-08",
                "Time": "14:44",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "156",
                "Institution": "FINANCE",
                "Date": "2023-05-02",
                "Time": "11:40",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "157",
                "Institution": "FINANCE",
                "Date": "2023-05-01",
                "Time": "12:49",
                "RequestPurpose": "0E",
                "Amount": "5000"
              },
              {
                "seq": "158",
                "Institution": "FINANCE",
                "Date": "2023-03-21",
                "Time": "12:35",
                "RequestPurpose": "0E"
              },
              {
                "seq": "159",
                "Institution": "Subhkiran Capital Limited",
                "Date": "2023-03-16",
                "Time": "09:15",
                "RequestPurpose": "00"
              },
              {
                "seq": "160",
                "Institution": "FINANCE",
                "Date": "2023-02-24",
                "Time": "15:58",
                "RequestPurpose": "31"
              },
              {
                "seq": "161",
                "Institution": "FINANCE",
                "Date": "2023-02-09",
                "Time": "11:55",
                "RequestPurpose": "00"
              },
              {
                "seq": "162",
                "Institution": "FINANCE",
                "Date": "2023-02-09",
                "Time": "11:39",
                "RequestPurpose": "00"
              },
              {
                "seq": "163",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "16:59",
                "RequestPurpose": "00"
              },
              {
                "seq": "164",
                "Institution": "Subhkiran Capital Limited",
                "Date": "2023-02-03",
                "Time": "15:35",
                "RequestPurpose": "00"
              },
              {
                "seq": "165",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "14:47",
                "RequestPurpose": "00"
              },
              {
                "seq": "166",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "14:44",
                "RequestPurpose": "00"
              },
              {
                "seq": "167",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "14:32",
                "RequestPurpose": "00"
              },
              {
                "seq": "168",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "14:22",
                "RequestPurpose": "00"
              },
              {
                "seq": "169",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "13:14",
                "RequestPurpose": "00"
              },
              {
                "seq": "170",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "12:48",
                "RequestPurpose": "00"
              },
              {
                "seq": "171",
                "Institution": "FINANCE",
                "Date": "2023-02-03",
                "Time": "12:36",
                "RequestPurpose": "00"
              },
              {
                "seq": "172",
                "Institution": "Subhkiran Capital Limited",
                "Date": "2023-02-01",
                "Time": "14:56",
                "RequestPurpose": "00"
              },
              {
                "seq": "173",
                "Institution": "Subhkiran Capital Limited",
                "Date": "2023-02-01",
                "Time": "14:53",
                "RequestPurpose": "00"
              },
              {
                "seq": "174",
                "Institution": "FINANCE",
                "Date": "2023-01-25",
                "Time": "15:06",
                "RequestPurpose": "00"
              },
              {
                "seq": "175",
                "Institution": "FINANCE",
                "Date": "2023-01-19",
                "Time": "16:41",
                "RequestPurpose": "00"
              },
              {
                "seq": "176",
                "Institution": "FINANCE",
                "Date": "2023-01-19",
                "Time": "16:28",
                "RequestPurpose": "00"
              },
              {
                "seq": "177",
                "Institution": "FINANCE",
                "Date": "2023-01-19",
                "Time": "16:21",
                "RequestPurpose": "00"
              },
              {
                "seq": "178",
                "Institution": "FINANCE",
                "Date": "2022-12-22",
                "Time": "12:54",
                "RequestPurpose": "0E"
              },
              {
                "seq": "179",
                "Institution": "FINANCE",
                "Date": "2022-12-13",
                "Time": "14:54",
                "RequestPurpose": "05"
              },
              {
                "seq": "180",
                "Institution": "FINANCE",
                "Date": "2022-12-08",
                "Time": "16:34",
                "RequestPurpose": "05"
              },
              {
                "seq": "181",
                "Institution": "FINANCE",
                "Date": "2022-12-08",
                "Time": "12:27",
                "RequestPurpose": "0E"
              }
            ],
            "EnquirySummary": {
              "Purpose": "ALL",
              "Total": "311",
              "Past30Days": "43",
              "Past12Months": "108",
              "Past24Months": "208",
              "Recent": "2024-10-04"
            },
            "OtherKeyInd": {
              "AgeOfOldestTrade": "137",
              "NumberOfOpenTrades": "2",
              "AllLinesEVERWritten": "0.00",
              "AllLinesEVERWrittenIn9Months": "0",
              "AllLinesEVERWrittenIn6Months": "0"
            },
            "RecentActivities": {
              "AccountsDeliquent": "1",
              "AccountsOpened": "0",
              "TotalInquiries": "44",
              "AccountsUpdated": "2"
            }
          }
        },
        {
          "InquiryResponseHeader": {
            "ClientID": "010FZ00385",
            "CustRefField": "ref235",
            "ReportOrderNO": "1596154618",
            "ProductCode": [
              "MCS"
            ],
            "SuccessCode": "1",
            "Date": "2024-10-08",
            "Time": "11:42:54",
            "HitCode": "00"
          },
          "InquiryRequestInfo": {
            "InquiryPurpose": "01",
            "FirstName": "RAM SINGH BHANDARI",
            "InquiryAddresses": [
              {
                "seq": "1",
                "AddressType": [
                  "H"
                ],
                "AddressLine1": "169 RAJEEV NAGAR  TARLI KANDOLI DEHRADUN UTTARAKHAND DEHRADUN",
                "State": "UK",
                "Postal": "249145"
              }
            ],
            "InquiryPhones": [
              {
                "seq": "1",
                "PhoneType": [
                  "M"
                ],
                "Number": "8191817766"
              }
            ],
            "IDDetails": [
              {
                "seq": "1",
                "IDType": "T",
                "IDValue": "ARSPB2789E",
                "Source": "Inquiry"
              }
            ],
            "DOB": "1960-05-30",
            "MFIDetails": {
              "FamilyDetails": [
                {
                  "seq": "1",
                  "AdditionalNameType": "O",
                  "AdditionalName": "krs"
                }
              ]
            }
          }
        }
      ]
    }
  }