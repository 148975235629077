import React from 'react'
import { Link } from 'react-router-dom'

function ListSidebar({ link, buttonText, icon,icon_above_class,small_font,arrow_bottom }) {
  return (
<li className="my-1 mx-2">
<Link className="d-flex w-100 py-1 align-items-center decoration_none" to={link}>
  <button className='_btn_'>
    <div className="text flex justify-left gap-2 items-center">
      <span className={icon_above_class}>
      {icon}
      </span>
      <span className={`${small_font} flex items-center font-bold`}>{buttonText}{arrow_bottom}</span>
    </div>
  </button>
</Link>
</li>
  )
}

export default ListSidebar