import React, { useEffect, useState } from 'react';
import {
    showSuccessToast,
    showErrorToast,
} from "../../utils/toastUtils";
import Header from '../../Components/Header';
import { fetchServiceDocumentation } from '../../Services/ServiceDocumentation/ServiceDoct';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';


const ApiLink = () => {
    const [documentationData, setDocumentationData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const search = window.location.search;
    const serviceId = new URLSearchParams(search).get('serviceId');
console.log(serviceId)
    useEffect(() => {
        const getDocumentation = async () => {
            try {
                const response = await fetchServiceDocumentation(serviceId);
                console.log(response);
                setDocumentationData(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        getDocumentation();
    }, [serviceId]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!documentationData) {
        return <div>No documentation available.</div>;
    }


const jsonPayload = `"amount": 50,
"payment_type": "IMPS",
"ifsc": "HDFC0001234",
"number": "1234567890",
"note": "Fund Transfer Test",
"udf1": "UDF Test 1",
"udf2": "UDF Test 2",
"udf3": "UDF Test 3",
"reference_number": "DEMOREFNUM0123",
"connected_banking": false`;


    const handleCopy = () => {
        // Create a temporary textarea to copy the text
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = jsonPayload;
        document.body.appendChild(tempTextarea);

        // Select and copy the text
        tempTextarea.select();
        document.execCommand('copy');

        // Remove the temporary textarea
        document.body.removeChild(tempTextarea);

        showSuccessToast('Copied to clipboard!');
    };

    return (
        <>
            <Header heading='ApiLink' />
            <div className='flex justify-center text-center'>
            <Link to="/ServicePage" className='font-bold text-2xl underline text-blue-700 animate-bounce' >Back to Service</Link>
            </div>
            <div className=" bg-gray-100 flex items-center justify-center">
                <div className="flex flex-col md:flex-row w-full max-w-5xl">
                    {/* First Half: Text Content */}
                    <div className="flex-1 bg-gray-200 p-1 flex justify-center">
                        <div className="p-4 bg-gray-100 rounded-lg shadow-md">
                            {/* <h2 className="text-xl font-bold mb-2">Services</h2> */}
                            <div className="mb-2">
                                <strong>Service ID:</strong> {documentationData.service_id}
                            </div>
                            <div className="mb-2">
                                <strong>Endpoint:</strong> <a href={documentationData.end_point} target="_blank" rel="noopener noreferrer">{documentationData.end_point}</a>
                            </div>
                            <div className="mb-2">
                                <strong>Introduction:</strong>
                                <div dangerouslySetInnerHTML={{ __html: documentationData.introduction }} />
                            </div>
                            <pre className="text-black whitespace-pre-wrap">
                            <div className="mb-2">
                                <strong>Request:</strong> {documentationData.request}
                            </div>
                            <div className="mb-2">
                                <strong>Response:</strong> {documentationData.response || "No response provided"}
                            </div>
                            <div className="mb-2">
                                <strong>Failed Response:</strong> {documentationData.failed_response}
                            </div>
                        </pre>
                        </div>
                    </div>

                    {/* Second Half: Form and JSON Payload */}
                    <aside className="flex-1 bg-black text-white p-4 rounded-lg font-mono">
                        <div class="flex space-x-2 text-red-500 mb-3">
                            <div class="w-3 h-3 rounded-full bg-red-500"></div>
                            <div class="w-3 h-3 rounded-full bg-yellow-500"></div>
                            <div class="w-3 h-3 rounded-full bg-green-500"></div>
                        </div>
                        <label className="font-bold block mb-2">Endpoint</label>
                        <input type="text" value={documentationData.end_point} className="w-full text-black rounded-md p-2 mb-4" />

                        <button className="text-xl mb-4 bg-white text-black rounded-md p-1 hover:bg-blue-800 hover:text-white">
                            Payload
                        </button>
                        <label className="font-bold block mb-2">Content Type</label>
                        <select className="w-1/2 rounded-md p-2 mb-4 text-black">
                            <option value="" disabled selected>application/json</option>
                        </select>

                        {/* Copy Button */}
                        <div className="flex justify-end">
                            <button
                                onClick={handleCopy}
                                className="bg-blue-500 text-white px-2 py-1 rounded-md mb-2 hover:bg-white hover:text-black"
                            >
                                Copy
                            </button>
                        </div>
                        {/* JSON Payload */}
                        <pre className="text-green-400 whitespace-pre-wrap">
                            <div className="mb-2">
                                <strong>Request:</strong> {documentationData.request}
                            </div>
                            <div className="mb-2">
                                <strong>Response:</strong> {documentationData.response || "No response provided"}
                            </div>
                            <div className="mb-2">
                                <strong>Failed Response:</strong> {documentationData.failed_response}
                            </div>
                        </pre>
                        
                    </aside>
                </div>
            </div>
        </>
    );
};

export default ApiLink;
