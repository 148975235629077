import React, { createContext, useState, useEffect,useContext } from 'react';
import { fetchProfile } from '../Services/profile/profileservice';

export const UserContext = createContext();

export const useProfile = () => {
    return useContext(UserContext);
};


export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const getProfileData = async () => {
           try {
            const data = await fetchProfile();
            setProfile(data);
           } catch (error) {
            console.error('Erro in fetching data', error);
           }
    };
    getProfileData();
}, []);
    return (
        <UserContext.Provider value={profile}>
            {children}
        </UserContext.Provider>
    );
};
