
import API_URLS from '../../config/BackendConfig';
import { getToken } from '../../utils/tokenUtils'; // Updated import to get token from
import { getRequest, postRequest } from '../../config/AxiosConfig';

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const RequstFundService = async (formData) => {
    try {
      const response = await postRequest({
        url: apiBaseUrl + 'fund/request',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
     //  console.log('Fund data:', response);
      return response;
    } catch (error) {
      console.error('Error in Adding Request Fund', error.response?.data?.message || error.message);
    }
  };


// Retrive Fund Data from API
export const retriveFundService = async (page = 1) => {
    try {
      const response = await postRequest({
        url: `${apiBaseUrl}fund/report?page=${page}`,
        headers: getAuthHeaders(),
      });
    //  console.log("service data",response);
      return response;
    } catch (error) {
      console.error('Error fetching Fund data:', error.response?.data || error.message);
      throw error;
    }
  };

  

  export const SlipPreviewService = async (filename) => {
    try {
        const response = getRequest({
          url: apiBaseUrl +  "fund-slip/"+filename,
          headers: {
            ...getAuthHeaders(),
            'Content-Type': 'multipart/form-data',
          },
        });
        // console.log(response);
        return response;
      } catch (error) {
        console.error('Error in Fetching Slip', error.response?.data?.message || error.message);
      }
    };