import React, { useState } from 'react';
import Seo from '../../Components/SeoComponent/Seo';
import { DivTag, HeadTag, Button, ImgTag,FormTag} from '../../Components/Tags/Tags';
import SliderCrousel from '../../Components/SliderLoginPage/SliderCrousel';
import { FaUserInjured } from "react-icons/fa6";

import { Link } from 'react-router-dom';

const ForgotPasswordPage = () => {
    const [userID, setUserID] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const VerifyUserID = () => {
        // Perform validation
        if (userID.trim() === '') {
            setAlertMessage('Please enter User ID to Get Code');
            return;
        }
        // If validation passes, open the modal and clear any previous alert message
        setAlertMessage('');
    };

    return (
        <>
  <Seo
        title="User Pannel Forgot Password"
        description="This is the API User Pannel"
        url=""
        image=""
      />



            <DivTag className="mx-auto bg-slate-300 min-h-screen flex flex-col md:flex-row">
                {/* First Column: Image */}
                <SliderCrousel />

                {/* <DivTag className="flex flex-col items-center md:w-1/3 mt-[8rem] p-8"> */}
                <DivTag className="flex flex-col items-center w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-4 sm:mt-[8rem] md:mt-[8rem] lg:mt-[8rem] xl:mt-[8rem] 2xl:mt-1/3 p-8">
                    <ImgTag
                        src="assets/images/IDSPayLogo.png"
                        alt="logo"
                        className=""
                    />
                    <DivTag className="text-center mt-12">
                        <HeadTag
                            className="text-black text-xl font-bold animate-bounce"
                            text=""
                        />
                        <HeadTag
                            className="text-blue-800 text-2xl font-semibold underline animate-pulse"
                            text="Enter User ID"
                        />
                    </DivTag>
                </DivTag>
                <FormTag className='w-full sm:w-1/3' method="post" autoComplete="off"> 
                {/* <DivTag className="flex flex-col items-center md:w-full mt-[19rem] p-5"> */}
                 <DivTag className="flex flex-col items-center sm:mt-[19rem] p-10 sm:p-6">
                    <DivTag className="w-full">
                        <div className="flex flex-row items-center gap-1 bg-white/80 rounded-md py-2 px-3 w-full mt-4 md:mt-1 border-blue-500 shadow-md shadow-blue-700">

                        <FaUserInjured color='blue' size={22} />

                            <input
                                className="bg-inherit focus:outline-none pl-1 w-full placeholder:text-blue-600"
                                type="text"
                                id="userID"
                                name="userID"
                                value={userID}
                                onChange={(e) => setUserID(e.target.value)}
                                maxLength={14}
                                minLength={4}
                                placeholder="Enter User ID"
                                required
                            />
                        </div>
                        {alertMessage && <div className="alert text-red-500 mt-2 text-sm">{alertMessage}</div>}
                    </DivTag>

                    <DivTag className="mt-5 flex flex-col items-center">    
                        {userID.trim() ? (
                            <Link to="/Auth2StepsPage"> 
                                <Button
                                    type="submit"
                                    text="Get Verification code"
                                    className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold
                                     rounded-md px-1 py-1 sm:p-2 text-md hover:from-blue-900 hover:to-blue-400"
                                    id="verify_userid"
                                    name="verify_userid"
                                    onClick={VerifyUserID}
                                />
                            </Link>
                        ) : (
                            <Button
                                type="submit"
                                text="Get Verification code"
                                className="text-center text-white bg-blue-900 sm:p-2 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-4 py-2 text-md hover:from-blue-900 hover:to-blue-400"
                                id="verify_userid"
                                name="verify_userid"
                                onClick={VerifyUserID}
                            />
                            
                        )}
                        <Link to="/LoginUserPage" className="font-bold text-dark underline mt-4">Logout</Link>
                    </DivTag>
                </DivTag>
                </FormTag>
            </DivTag>
        </>
    );
};

export default ForgotPasswordPage;
