import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SliderCrousel2 extends Component {
    render() {
        return (
            <Carousel 
                autoPlay={true} 
                infiniteLoop={true} 
                transitionTime={500} 
                showThumbs={false} 
                showIndicators={true}>
                
                <div>
                    <img 
                        style={{ 
                            width: '100%', // Ensures the image takes full width of the container
                            height: '300px', // Fixed height for consistency
                            objectFit: 'contain' // Ensures the entire image fits within the container
                        }} 
                        src={process.env.PUBLIC_URL + '/media/slider_img/Aadhar Verification API for IT solution website.png'} 
                        alt="Aadhar Verification API"
                    />
                </div>
                
                <div>
                    <img 
                        style={{ 
                            width: '100%', 
                            height: '330px', 
                            objectFit: 'contain' 
                        }} 
                        src={process.env.PUBLIC_URL + '/media/slider_img/DL Verification API for IT Solution website.png'} 
                        alt="DL Verification API"
                    />
                </div>
                
                <div>
                    <img 
                        style={{ 
                            width: '100%', 
                            height: '330px', 
                            objectFit: 'contain' 
                        }} 
                        src={process.env.PUBLIC_URL + '/media/slider_img/IFSC verification API for IT solution website.png'} 
                        alt="IFSC Verification API"
                    />
                </div>

                <div>
                    <img 
                        style={{ 
                            width: '100%', 
                            height: '330px', 
                            objectFit: 'contain' 
                        }} 
                        src={process.env.PUBLIC_URL + '/media/slider_img/Pan verification API for IT solution website.png'} 
                        alt="Pan Verification API"
                    />
                </div>
                
            </Carousel>
        );
    }
}

export default SliderCrousel2;
