import React from 'react';

const SlipViewModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" style={{ zIndex:9999}}>
      <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-md w-full">
        <div className="flex justify-end p-2">
          <button onClick={onClose} className="text-white text-3xl rounded-lg px-2 py-0 bg-red-900">&times;</button>
        </div>
        <div className="p-2">
          <img src={imageUrl} alt="Pay Slip" className="w-full h-auto rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default SlipViewModal;





