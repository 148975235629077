import React, {useState, useEffect} from 'react'
import { DivTag } from '../../../Components/Tags/Tags'
import Header from '../../../Components/Header'
import Seo from '../../../Components/SeoComponent/Seo'
import { HeadTag, InputField } from '../../../Components/Tags/Tags'
import { showSuccessToast, showErrorToast,showInfoToast,showWarningToast } from '../../../utils/toastUtils'
import GaugeChart from 'react-gauge-chart'
import {AddEquifaxData, fetchCityService, fetchEquifaxEQCodeService, fetchProfile, fetchStateService, ShowCreditScore } from '../../../Services/EquifaxCScore/equifax'
import ReportModal from './ReportModal'
import html2pdf from 'html2pdf.js';
import { CreditData } from './data'
import Loader from '../../../Loader'


const EquifaxCrScore = () => {

const [cusName, setCusName] = useState('');
const [dob, setDob] = useState('');
const [mobile, setMobile] = useState('');
const [aadharNo, setAadharNo] = useState('');
const [panNo, setPanNo] = useState('');
const [voterID, setVoterId] = useState('');
const [rationCard, setRationCard] = useState('');
const [drivingLicence, setDrivingLicence] = useState('');
const [passport, setPassport] = useState('');
const [address, setAddress] = useState('');
const [state, setState] = useState('');
const [city, setCity] = useState('');
const [pinCode, setPinCode] = useState('');
const [nominee, setNominee] = useState('');
const [selectRelation, setSelectRelation] = useState('');
const [enquiryPurpose, setEnquiryPurpose] = useState('');
const [errors, setErrors] = useState({});
const [isLoading, setIsLoading] = useState(false);
const [creditScore, setCreditScore] = useState('');  
const [isModalOpen, setModalOpen] = useState(false); 
const [stateList, setStateList] = useState([]);
const [cityList, setCityList] = useState([]);
const [enquiryList, setEnquiryList] = useState([]);
const [profileData, setProfileData] = useState([]);
const [equifaxResponseData, setEquifaxResponseData] = useState('');


const maxScore = 900;

// Validate Form Input
const validateForm = () => {
    const newErrors = {};

    if (!cusName) newErrors.cusName = 'Cus Name is required.';
    // if (!dob || !isValidDate(dob)) newErrors.dob = 'dob must be a valid date.';
    if (!mobile) newErrors.mobile = 'Mobile is required.' ;
    if (!address) newErrors.address = 'Address is required.';
    if (!state) newErrors.state = 'State is required.';
    if (!pinCode) newErrors.pinCode = 'Pin Code is required.';
    if (!nominee) newErrors.nominee = 'Nominee is required.';
    if (!selectRelation) newErrors.selectRelation = 'Relation is required.';
    if (!enquiryPurpose) newErrors.enquiryPurpose = '';

    // Check if at least one of the ID fields is filled, otherwise show a common error
    if (
        !aadharNo && 
        !panNo && 
        !voterID && 
        !rationCard && 
        !drivingLicence && 
        !passport
    ) {
        newErrors.identification = 'At least one ID is required (Aadhar No., Pan No., Voter ID, Ration Card, Driving Licence, Passport).';
    }

    setErrors(newErrors);
    
    // Return true if no errors, false if there are errors
    return Object.keys(newErrors).length === 0;
};


const handleDateChange = (event) => {
  const dateValue = event.target.value;
  setDob(dateValue);
};


const handlesubmit = async () => {
  try {
      // Validate the form first
      const isValid = validateForm();
      if (!isValid) {
          showErrorToast('Please correct the form errors.');
          return; // Exit if validation fails
      }
      // Call the AddEquifax function to submit data
      setIsLoading(true);
      await AddEquifax();
      
      setTimeout(() => {
        // Hide the loader after 1 second
        setIsLoading(false);
      
        // showSuccessToast('Data submitted successfully');
    }, 2000);
  } catch (error) {
   // console.error('Error submitting data:', error);
    showErrorToast('Validate the Required Field');
    setIsLoading(false); // Hide loader in case of error
  }
};

const handleDownloadPDF = () => {
  // Use setTimeout to ensure the modal or content is fully rendered before capturing the content
  setTimeout(() => {
    const element = document.getElementById("CreditReport");

    if (element) {
      // Temporarily remove the 'hidden' class from the element for capturing the PDF
      element.classList.remove('hidden');

      // Clone the element to avoid affecting the original content
      const clonedElement = element.cloneNode(true);

      // Adjust styles of cloned element to ensure it captures everything in the PDF
      clonedElement.style.maxHeight = 'none'; // Make sure full content is visible
      clonedElement.style.overflow = 'visible'; // Remove any overflow issues

      // Create a new temporary div to render the cloned content for PDF generation
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute'; // Ensure it's not in view but still in DOM
      tempDiv.style.visibility = 'hidden';
      tempDiv.appendChild(clonedElement);
      document.body.appendChild(tempDiv);

      // Generate the PDF from the cloned content
      html2pdf()
        .from(clonedElement)
        .set({
          margin: [0.2, 0.2, 0.2, 0.2], // Adjust margins to suit your needs
          filename: 'Credit_Report.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, backgroundColor: 'white' }, // Set high resolution
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        })
        .save()
        .then(() => {
          // Close modal and remove temporary div after PDF is saved
          document.body.removeChild(tempDiv);
          // Add 'hidden' class back to the original element after PDF is generated
          element.classList.add('hidden');
        })
        .catch(() => {
          // Handle any errors that might occur during PDF generation
          showErrorToast("Failed to generate PDF");
          // Add 'hidden' class back to the original element in case of error
          element.classList.add('hidden');
        });
    } else {
      showErrorToast("No data found to download");
    }
  }, 10); // Slightly increased timeout to ensure rendering is complete
};


const getPaymentStatusColor = (status) => {
  switch (status) {
    case "000":
      return "bg-green-500"; // Green for success
    case "*":
      return "bg-red-500";   // Red for failure
    case "NEW":
      return "bg-yellow-500"; // Yellow for new
    default:
      return "bg-gray-500";  // Default for other cases
  }
};


useEffect(() => {
  async function fetchProfileData() {
    try {
      const response = await fetchProfile(); // Your API call for profile
      setProfileData(response.data);
    } catch (error) {
      console.error('Error fetching profile data', error); } }
  fetchProfileData();
}, []);

let refCounter = 237; // Starting number (You can adjust this as needed)

const generateReferenceID = () => {
    refCounter++; // Increment the counter
    return `ref${refCounter}`; // Generate the reference ID
};

// Example usage
const referenceID = generateReferenceID();
//console.log(referenceID); // Outputs something like "REF872"


// Add Credit Data
async function AddEquifax() {
  try {
    const dobdate = new Date(dob).toLocaleDateString('en-CA');
   // console.log(dobdate);
    setIsLoading(true);
    const formData = {
      phones: mobile && mobile[0]?.number ? [{ number: mobile[0].number, type: "M" }] : [], // Phones array
      inquiry_addresses: address && state && pinCode ? [{ // Inquiry addresses array
        AddressLine1: address,
        State: state,
        Postal: pinCode,
      }] : [],
      id_details: [
        { IDValue: panNo || "", IDType: "T" }, 
        { IDValue: passport || "", IDType: "P" },
        { IDValue: voterID || "", IDType: "V" },
        { IDValue: drivingLicence || "", IDType: "D" },
         { IDValue: aadharNo || "", IDType: "M" }, 
        { IDvalue: "" ,IDType: "R" },
        { IDvalue: "" ,IDType: "O" },
      ],
      mfi_details: nominee && selectRelation ? [{
        FamilyDetails: [{
          AdditionalName: nominee,
          AdditionalNameType: selectRelation,
        }]
      }] : [],
      first_name: cusName,
      api_id: profileData.user_id,
      api_key: profileData.api_key,
      token_id: profileData.token_id,
      reference_id: referenceID,
      dob: dobdate,
      aadhar_no: aadharNo,
      pan_no: panNo,
      voter_id: voterID,
      ration_no: rationCard,
      driving_licence: drivingLicence,
      passport: passport,
      state: state,
      city: city,
      pin_code: pinCode,
      nominee: nominee,
      inquiry_purpose: enquiryPurpose,
    };
    
    // console.log("Prepared formData:", formData);

    // Call the service and pass the formData
    const response = await AddEquifaxData(formData);
   //  console.log("ress",response);
    // console.log(formData)
    // Handle successful response

    if (!response.data.success) {
      // Show error toast if no data is returned
      showErrorToast(response.data.message || 'An error occurred.');
      setIsLoading(false); // Hide loader
      return; // Exit if there's no data
    }

    setEquifaxResponseData(response.data);

    const creditScore = response.data.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.ScoreDetails[0]?.Value;
    if (creditScore) {
      setCreditScore(creditScore);
    } else {
      showErrorToast(response.data.message || 'An error occurred.');
    }

    // console.log('Equifax Data Added:', response);

    // Clear the form or reset necessary state variables
    setCusName('');
    setDob('');
    setMobile('');
    setAadharNo('');
    setPanNo('');
    setVoterId('');
    setRationCard('');
    setDrivingLicence('');
    setPassport('');
    setAddress('');
    setState('');
    setCity('');
    setPinCode('');
    setNominee('');
    setSelectRelation('');
    setEnquiryPurpose('');
    
    setIsLoading(false);
    
  } catch (error) {
    console.error('Error in AddEquifax:', error);
    showErrorToast('An error occurred while submitting the data.');
    setIsLoading(false); // Hide loader if there's an error
  }
}
// ENd

// Fetch State List
const fetchstate = async () => {
  try {
    const response =await fetchStateService();
    setStateList(response.data);
      // console.log("data in page", response)
  } catch (error) {
    console.error("Error in fetchin ", error);
  }
}
useEffect(() => {
  fetchstate();
}, []);
// ENd


  // Fetch City List
const fetchcity = async () => {
  try {
    const response = await fetchCityService({stateId:state});
    setCityList(response.data);
      // console.log("data in page", response)
  } catch (error) {
    console.error("Error in fetchin ", error);
  }
}
useEffect(() => {
  fetchcity();
}, [state]);
// ENd


// Fetch Enquiry List
const fetchenquiry = async () => {
  try {
    const response = await fetchEquifaxEQCodeService();
    setEnquiryList(response.data);
  } catch (error) {
    console.error("Error in Fetching", error);
  }
}
useEffect(() => {
  fetchenquiry();
},[])
// ENd


  return (
    <>
    <Seo />
    {isLoading && <Loader />} {/* Conditionally show the loader */}
      <Header heading='Equifax Credit Score' />
      <DivTag className="container grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-1 md:gap-2 p-2 bg-white  md:mt-1 mt-0 shadow-blue-600 rounded-md">
     
        <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Customer Name<strong className='text-red-700'>*</strong></HeadTag>
            <InputField 
        type='text'
        id="cus_name"
        name="cus_name"
        className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
        placeholder="Enter Customer Name"
        onChange={(e) => setCusName(e.target.value)}
        />
         {errors.cusName && <p className="text-red-600 text-sm font-mono">{errors.cusName}</p>}
        </DivTag>


<DivTag className="flex flex-col">
    <HeadTag className="text-md font-serif">  
        D.O.B <strong className='text-red-700'>*</strong>
    </HeadTag>
    <input
      type="date"
      name="dob"
      id="dob"
      value={dob}
      onChange={handleDateChange}
      className="w-full border border-blue-600 rounded-md py-1"
    />
    
    {errors.dob && <p className="text-red-600 text-sm font-mono">{errors.dob}</p>}
</DivTag>

<DivTag className="flex flex-col">
  <HeadTag className="text-md font-serif">
    Mobile <strong className="text-red-700">*</strong>
  </HeadTag>
  <InputField
    type="tel"  // Changed to 'tel' for better mobile keyboard
    placeholder="Enter Mobile"
    className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
    name="mobile"
    id="mobile"
    onChange={(e) => {
      const mobileNumber = e.target.value;
      if (mobileNumber.length >= 10 && mobileNumber.length <= 15) {
        setMobile([{ number: mobileNumber, type: "M" }]);  // Directly passing value as an array
      } else {
        setMobile([]); // Reset if the number is invalid
      }
    }}
  />
  {errors.mobile && <p className="text-red-600 text-sm font-mono">{errors.mobile}</p>}
</DivTag>


      
          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Aadhar No.<strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Aadhar No."
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="aadhar_no"
              id="aadhar_no"
              onChange={(e) => setAadharNo(e.target.value)}
            />
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Pan No. <strong className='text-red-700'>*</strong></HeadTag>           
             <InputField
              type="text"
              placeholder="Enter Pan"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="pan_no"
              id="pan_no"
              onChange={(e) => setPanNo(e.target.value)}
            />
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Voter ID <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Voter ID"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="voter_id"
              id="voter_id"
              onChange={(e) => setVoterId(e.target.value)}
            />
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Ration Card No<strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Ration Card No"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="ration_no"
              id="ration_no"
              onChange={(e) => setRationCard(e.target.value)}
            />
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Driving Licence <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Driving Licence"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="driving_licence"
              id="driving_licence"
              onChange={(e) => setDrivingLicence(e.target.value)}
            />
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Passport <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Passport"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="passport"
              id="passport"
              onChange={(e) => setPassport(e.target.value)}
            />
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Address <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter address"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="address"
              id="address"
              onChange={(e) => setAddress(e.target.value)}
            />
         {errors.address && <p className="text-red-600 text-sm font-mono">{errors.address}</p>}
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">select state<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="enquiry_purpose"
            id="enquiry_purpose"
            value={state}
            onChange={(e) => setState(e.target.value)}
             >
            <option value="" disabled selected>select state</option>
         {
        stateList && stateList.length > 0 ? stateList.map((item,index)=>(
            <option value={item.abbreviation} key={index}>{item.state_name}</option>
          ))
          :""
         }
         </select>
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">select city<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_city"
            id="select_city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
             >
            <option value="" disabled selected>select city</option>
         {
        cityList && cityList.length > 0 ? cityList.map((item,index)=>(
            <option value={item.city_name} key={index}>{item.city_name}</option>
          ))
          :""
         }
         </select>
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Pin-Code <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter pin code"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="pin code"
              id="pin code"
              onChange={(e) => setPinCode(e.target.value)}
            />
              {errors.pinCode && <p className="text-red-600 text-sm font-mono">{errors.pinCode}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Nominee <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter nominee"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="nominee"
              id="nominee"
              onChange={(e) => setNominee(e.target.value)}
            />
             {errors.nominee && <p className="text-red-600 text-sm font-mono">{errors.nominee}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Select Relation<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_relation"
            id="select_relation"
            onChange={(e) => setSelectRelation(e.target.value)}
             >
            <option value="" disabled selected>Select Relation</option>
            <option value="F">Father</option>
            <option value="H">Husband</option>
            <option value="B">Brother</option>
            <option value="S">Son</option> 
            <option value="U">Son In Law</option>
            <option value="Y">Father In Law</option>
            <option value="O">Other</option> 
            <option value="M">Mother</option>                      
            <option value="W">Wife</option>
            <option value="C">Sister</option>
            <option value="D">Daughter</option>
            <option value="V">Daughter-In-Law</option>
            <option value="Z">Mother-In-Law</option>
            <option value="X">Sister-In Law</option>
            </select>
            {errors.selectRelation && <p className="text-red-600 text-sm font-mono">{errors.selectRelation}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">Enquiry Purpose<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="enquiry_purpose"
            id="enquiry_purpose"
            value={enquiryPurpose}
            onChange={(e) => setEnquiryPurpose(e.target.value)}
             >
            <option value="" disabled selected>enquiry purpose</option>
            {
              enquiryList && enquiryList.length > 0 ? enquiryList.map((item,index)=>(
                <option value={item.code} key={index}>{item.code}</option>
              ))
              : ""
            }
            </select>
            {errors.enquiryPurpose && <p className="text-red-600 text-sm font-mono">{errors.enquiryPurpose}</p>}
          </DivTag>
        
          <div className="flex flex-col">
            <button className='rounded-md p-1  bg-blue-700 text-white mt-6 mb-1'
            onClick={handlesubmit}
            >Submit</button>
        </div>
      </DivTag>
        {/* <p>{equifaxResponseData.InquiryResponseHeader.ClientID}</p> */}

        <div className='flex justify-center'>
        <div class="flex flex-col items-center bg-gray-200 rounded-md shadow-lg shadow-blue-800 md:w-3/4 w-full p-1">
  <h1 class="font-bold text-2xl text-red-600 underline mb-2">Instructions</h1>
  <h2 class="text-xl font-bold text-center text-gray-800 mb-2 underline">How to Best Credit Score & Financial Report?</h2>
  <p class="text-center text-gray-600 mb-4 font-semibold md:whitespace-nowrap ">
    Every individual or company is entitled to check their CIBIL score. Follow the steps below to get a better report:
  </p>
  <ul class="list-decimal list-inside text-left text-gray-700">
    <li>Enter the correct details to get the credit score.</li>
    <li>Minimum one valid ID number is required.</li>
    <li>Customer details are required to get the report.</li>
    <li>Credit Report may vary based on the identity proof provided.</li>
  </ul>
</div></div>

        
        <div className='w-full text-center'>
        {errors.identification && <p className='text-red-600 text-sm font-mono'>{errors.identification}</p>}
        </div>
         { 
     equifaxResponseData === "" ?  
    "" : 
        <div class="flex flex-col p-4 bg-white shadow-inner md:mt-1 shadow-blue-600 rounded-md">
        <span className='text-center font-bold md:text-xl'>Hey {equifaxResponseData?.InquiryRequestInfo?.FirstName}!</span>
  <h2 class="text-xl md:text-xl font-semibold text-center">Your Equifax Credit Score</h2>

    <div class="flex flex-col md:flex-row justify-center md:justify-center gap-4 md:gap-8 md:mt-2 mt-3 relative">
    <div class="w-full md:w-[400px] relative md:mt-10">
      
    <span class="text-red-500 md:font-bold absolute md:top-[20px] md:left-10 md:mt-16 -rotate-70
    top-[60px] left-5 
    ">Poor</span>
    <span class="text-yellow-400 md:font-bold absolute md:left-[104px] md:mt-1 -rotate-26
    left-[69px] top-[-7px] 
    ">Average</span>
    <span class="text-orange-600 md:font-bold absolute md:left-60 md:mt-0 md:rotate-20
    left-[12rem] mt-[-9px] rotate-20
    ">Good</span>
    <span class="text-green-800 md:font-bold absolute md:top-[20px] md:ml-[19.5rem] md:mt-16 rotate-70
    ml-[16rem] mt-[3.5rem]
    ">Excellent</span>

      <GaugeChart 
        id="gauge-chart1" 
        nrOfLevels={3} 
        percent={creditScore / maxScore}  // Normalized value for gauge
        needleColor={"#00008b"}
        textColor={"transparent"}  // Hides the percentage text
        needleBaseColor={"red"}
        arcsLength={[0.3, 0.3, 0.3, 0.3]} 
        colors={['#cc0000','#e6e600','#cc6600','#009900']}
      />
      <p class="text-black font-bold text-xl md:text-2xl absolute bottom-12 md:bottom-[5.5rem] 
      left-1/2 transform -translate-x-1/2"> 
        {creditScore}
      </p>
    </div>
   
    <div class="flex flex-col items-center md:items-start">
      <ul class="text-left text-sm md:text-base space-y-1 mt-4">
        <li><span class="font-bold">No of Accounts:</span>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.NoOfAccounts}</li>
        <li><span class="font-bold">Active Accounts:</span>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.NoOfActiveAccounts}</li>
        <li><span class="font-bold">Total Balance Amount:</span>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalBalanceAmount}</li>
        <li><span class="font-bold">Total Pass Due:</span>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalPastDue}</li>
        <li><span class="font-bold">Recent Account:</span>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.RecentAccount}</li>
        <li><span class="font-bold">Oldest Account:</span>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.OldestAccount}</li>
      </ul>
      <ReportModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}  equifaxResponseData={equifaxResponseData}/>
      <div class="flex space-x-2 mt-4">
        <button 
          onClick={() => setModalOpen(true)} 
          class="bg-blue-600 text-white text-sm md:text-base rounded-md px-2 py-1">
          View
        </button>
        <button 
          class="bg-red-600 text-white text-sm md:text-base rounded-md px-2 py-1"
          onClick={handleDownloadPDF}
          >
          Download
        </button>
      </div>
    </div>
    
  </div>

</div>
    } 
<>

<div id="CreditReport" className="hidden bg-white rounded-lg shadow-lg w-full md:max-w-3xl overflow-y-auto max-h-[100vh]">
                <div className="a4-page">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                        <div className="col-span-2">
                            <img 
                                src="https://imgs.search.brave.com/6iV5QNxEpERp5OAG16hROi1fGXw0TI7pe8Po1HzXnjI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9kMXB0/NncybXQyeHFzby5j/bG91ZGZyb250Lm5l/dC9BY3VDdXN0b20v/U2l0ZW5hbWUvREFN/LzAxOS9FcXVpZmF4/LUxvZ28uanBn"
                                className="w-20"
                                alt="Company Logo"
                            />
                        </div>
                        <div className="col-span-8 md:text-center text-center">
                            <h4 className="text-2xl font-bold">Credit Report</h4>
                        </div>
                        <div className="col-span-2 md:text-end text-end">
                        Consumer Credit Score: <strong className="text-xl">
                        {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.ScoreDetails[0]?.Value}
                        </strong>
                        </div>
                    </div>

                    <div className="flex justify-between items-start flex-wrap">
  {/* Start section */}
  <div className="">
    <p><i>Client ID:</i> <span className="client_id">{equifaxResponseData?.InquiryResponseHeader?.ClientID}</span></p>
    <p><i>Report Order No:</i> <span className="report_order_no">{equifaxResponseData?.InquiryResponseHeader?.ReportOrderNO}</span></p>
  </div>

  {/* End section */}
  <div className="text-right">
    <p><i>Date:</i> <span className="report_date">{equifaxResponseData?.InquiryResponseHeader?.Date}</span></p>
    <p><i>Time:</i> <span className="report_time">{equifaxResponseData?.InquiryResponseHeader?.Time}</span></p>
  </div>
</div>

<div className='flex justify-center'>
        <p className='font-bold text-white'>Personal Details</p>
    </div>
<div className="border border-blue-500 p-2 rounded-md">
   
  <div className="grid grid-cols-2 md:grid-cols-2  gap-1">
    {/* Consumer Name */}
    <div>
      <p><i>Consumer Name</i><span> : {equifaxResponseData?.InquiryRequestInfo?.FirstName}</span></p>
    </div>
    <div>
      <p><i>D.o.b</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.InquiryRequestInfo?.DOB}</span></p>
    </div>
    <div>
      <p><i>Age</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.PersonalInfo?.Age?.Age}</span></p>
    </div>
    <div>
      <p><i>Gender</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.PersonalInfo?.Gender}</span></p>
    </div>
    <div>
      <p><i>Mob</i><span> : {equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.InquiryRequestInfo?.InquiryPhones[0]?.Number}</span></p>
    </div>
  </div>
</div>

                    <hr className="my-2" />
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Address</div>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-2 py-1">Sn</th>
      <th className="border border-black px-2 py-1">Address</th>
    </tr>
  </thead>
  <tbody className="address-info">
  {Array.isArray(equifaxResponseData?.CCRResponse?.CIRReportDataLst) && equifaxResponseData.CCRResponse.CIRReportDataLst.length > 0 ? (
  equifaxResponseData.CCRResponse.CIRReportDataLst[0]?.CIRReportData?.IDAndContactInfo?.AddressInfo?.map((address, index) => (
    <tr key={address.Seq}>
      <td className="border border-black px-2 py-1 font-bold">Address: {address.Seq}</td>
      <td className="border border-black px-2 py-1">{address.Address}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="2" className="border border-black px-2 py-1 text-center">
      No address information available.
    </td>
  </tr>
)}

  </tbody>
</table>

                       {/* Credit Score Section */}
                    <div className=" bg-blue-900 md:text-white p-2 text-white mt-2">Equifax Score</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr className='text-left'>
      <th className="border border-black px-2 py-1">Score Name</th>
      <th className="border border-black px-2 py-1">Score</th>
      <th className="border border-black px-2 py-1">Scoring Elements</th>
    </tr>
  </thead>
  <tbody className="scoreDetailsInfo">
  {Array.isArray(equifaxResponseData?.CCRResponse?.CIRReportDataLst) && equifaxResponseData.CCRResponse.CIRReportDataLst.length > 0 && Array.isArray(equifaxResponseData.CCRResponse.CIRReportDataLst[0]?.CIRReportData?.ScoreDetails) ? (
  equifaxResponseData.CCRResponse.CIRReportDataLst[0].CIRReportData.ScoreDetails.map((scoreDetail, index) => (
    <tr key={index} className=''>
      <td className="border border-black px-2 py-1">{scoreDetail.Name}</td>
      <td className="border border-black px-2 py-1">{scoreDetail.Value}</td>
      <td className="border border-black px-2 py-1">
        <ul>
          {Array.isArray(scoreDetail.ScoringElements) ? (
            scoreDetail.ScoringElements.map((element, idx) => (
              <li key={idx}>
                <p>{element.Description}</p>
              </li>
            ))
          ) : (
            <li>No scoring elements available.</li>
          )}
        </ul>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="3" className="border border-black px-2 py-1 text-center">
      No score details available.
    </td>
  </tr>
)}

  </tbody>
</table>
                         {/* Accounts Section */}
                    <div className=" bg-blue-900 md:text-white text-white p-2 mt-4">Account Details</div>
                    <table className="min-w-full table-auto text-left mt-2 border-collapse">
                    <tbody className="mb-3">
  {Array.isArray(equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountDetails) && equifaxResponseData.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.length > 0 ? (
  equifaxResponseData.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((account, index) => (
    <React.Fragment key={index}>
      <tr className="bg-blue-800 md:text-white text-white">
        <td colSpan={6} className="px-2 py-1 border rounded-tr-md rounded-tl-md">
          RETAIL : {account.AccountType}
        </td>
      </tr>

      {/* This row will have borders around it */}
      <tr className="border">
        <td colSpan={6} className="px-2 py-1 border ">
          <div className="grid grid-cols-2 gap-2">
            <div><span className="font-semibold">Sanction Amount :</span> {account.SanctionAmount}</div>
            <div><span className="font-semibold">Balance :</span> {account.Balance}</div>
            <div><span className="font-semibold">OverDue :</span> {account.PastDueAmount}</div>
            <div><span className="font-semibold">Account Status :</span> {account.AccountStatus}</div>
            <div><span className="font-semibold">Date Opened :</span> {account.DateOpened}</div>
          </div>
        </td>
      </tr>

      {/* Additional rows with border */}
      <tr>
        <td colSpan={6} className="px-2 py-1 border">
          <div className="grid grid-cols-2 gap-2">
            <div><span className="font-semibold">Account Number:</span> {account?.AccountNumber}</div>
            <div><span className="font-semibold">Institution:</span> {account?.Institution}</div>
            <div><span className="font-semibold">Account Type:</span> {account?.AccountType}</div>
            <div><span className="font-semibold">Ownership Type:</span> {account?.OwnershipType}</div>
            <div><span className="font-semibold">Balance:</span> {account?.Balance}</div>
            <div><span className="font-semibold">Open:</span> {account?.Open}</div>
            <div><span className="font-semibold">Sanction Amount:</span> {account?.SanctionAmount}</div>
            <div><span className="font-semibold">Source:</span> {account?.source}</div>
            <div><span className="font-semibold">Interest Rate:</span> {account?.InterestRate}</div>
            <div><span className="font-semibold">Repayment Tenure:</span> {account?.RepaymentTenure}</div>
            <div><span className="font-semibold">Installment Amount:</span> {account?.InstallmentAmount}</div>
            <div><span className="font-semibold">Term Frequency:</span> {account?.TermFrequency}</div>
            <div><span className="font-semibold">Date Reported:</span> {account?.DateReported}</div>
            <div><span className="font-semibold">Date Opened:</span> {account?.DateOpened}</div>
            <div><span className="font-semibold">Dispute Code:</span> N/A</div>
            <div><span className="font-semibold">Account Status:</span> {account?.AccountStatus}</div>
            <div><span className="font-semibold">Asset Classification:</span> {account?.AssetClassification}</div>
          </div>
        </td>
      </tr>

      <tr className='my-2'>
        <td colSpan={6} className="px-1 py-0 border border-t-none">
          <h2 className="font-semibold">Payment history</h2>
          <div className="flex flex-wrap mb-4 mt-2">
            {Array.isArray(account.History48Months) && account.History48Months.length > 0 ? (
              account.History48Months.map((history, idx) => (
                <div key={idx} className="mx-1">
                  <span className={`flex items-center justify-center w-6 h-6 text-white text-xs rounded-full ${getPaymentStatusColor(history.PaymentStatus)}`}>
                    <p className='mt-[-12px]'>{idx + 1}</p>
                  </span>
                </div>
              ))
            ) : (
              <div>No payment history available.</div>
            )}
          </div>
        </td>
      </tr>
    </React.Fragment>
  ))
) : (
  <tr>
    <td colSpan={6} className="px-2 py-1 text-center border">
      No retail account details available.
    </td>
  </tr>
)}

  </tbody>
</table>

   <div className="flex flex-col space-y-2 p-4">
     <h2 className='font-bold'>Payment Status Legend</h2>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-green-500 mr-2"></div>
   <span>Current Account (000)</span>
 </div>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-yellow-500 mr-2"></div>
   <span>1-29/30-59 days past due (01+, 30+)</span>
 </div>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-orange-500 mr-2"></div>
   <span>60-89 days past due (60+)</span>
 </div>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-red-500 mr-2"></div>
   <span>90+ days past due (90+, 120+)</span>
 </div>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-gray-500 mr-2"></div>
   <span>Closed Account (CLSD)</span>
 </div>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-blue-500 mr-2"></div>
   <span>New Account (NEW), Settled (SET)</span>
 </div>
 <div className="flex items-center">
   <div className="w-4 h-4 bg-purple-500 mr-2"></div>
   <span>Restructured (RES, RNC)</span>
 </div>
</div>

                      {/* Recent Activity Section */}
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Recent Activity (Last 90 Days)</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-4 py-2">Total Inquiries</th>
      <th className="border border-black px-4 py-2">Accounts Opened</th>
      <th className="border border-black px-4 py-2">Accounts Updated</th>
      <th className="border border-black px-4 py-2">Accounts Delinquent</th>
    </tr>
  </thead>
  <tbody className="recentActivity">
    <tr className="border border-black text-center">
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.TotalInquiries}</td>
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsOpened}</td>
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsUpdated}</td>
      <td className="border border-black px-4 py-2">{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RecentActivities.AccountsDeliquent}</td>
    </tr>
  </tbody>
</table>
                     </div>
                          {/* Summary Section */}
                     <div className="  bg-blue-900 md:text-white text-white p-2 mt-2">Summary</div>
                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className='border border-black px-2 py-1'>Number of Accounts</th>
                <th className='border border-black px-2 py-1'>Total Balance Amount</th>
                <th className='border border-black px-2 py-1'>Recent Account</th>
              </tr>
            </thead>
            <tbody class="summary1">
                <tr>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.NoOfAccounts}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalBalanceAmount}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.RecentAccount}</td>
            </tr>
            </tbody>
                    </table>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
                 <tr>
                <th className="border border-black px-2 py-1">Number of Open Accounts</th>
                <th className="border border-black px-2 py-1">Total Past Due Amount</th>
                <th className="border border-black px-2 py-1">Oldest Account</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.NoOfActiveAccounts}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalPastDue}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.OldestAccount}</td>
            </tr>
            </tbody>
                     </table>

                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className="border border-black px-2 py-1">Total High Credit</th>
                <th className="border border-black px-2 py-1">Total Sanction Amount</th>
                <th className="border border-black px-2 py-1">Single Highest Sanction</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalHighCredit}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.TotalSanctionAmount}</td>
                <td className='border border-black px-2 py-1'>{equifaxResponseData?.CCRResponse?.CIRReportDataLst[0]?.CIRReportData?.RetailAccountsSummary?.SingleHighestSanctionAmount}</td>
            </tr>
            </tbody>
                         </table>
                 </div>
</>

  </>
  )
}

export default EquifaxCrScore


