import React, { useState } from 'react';
import { Button, DivTag, SelectField } from '../../Components/Tags/Tags';
import Header from '../../Components/Header';

const CommissionCharges = () => {


  return (
    <DivTag>
    <Header heading='Commission And Charges' />
    <DivTag className="flex justify-center items-center flex-col">
      {/* start selectfield */}
     <SelectField
        text="Select Service"
        id="select_service"
        name="select_service"
        className="w-1/2 p-2 border border-blue-900 rounded-md mt-10 text-center sm:w-1/6 md:1/6 lg:w-1/6 xl:w-1/6 2xl:w-1/6"
      >
        <option value="disable selected">Select Service</option>
        <option value="AEPS">AEPS</option>
        <option value="M ATM">M ATM</option>
      </SelectField>
      {/* end select filed */}
      {/* start table */}
      <table className='w-full flex-wrap overflow-auto mt-5  font-bold sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 justify-item-center'>
          <thead className='bg-blue-900 text-white border-spacing-1'>
            <tr>
              <th className='py-1 px-1'>From Amount</th>
              <th className='py-1 px-1'>To Amount</th>
              <th className='py-1 px-1'>Type</th>
              <th className='py-1 px-1'>Commission</th>
              <th className='py-1 px-1'>Changes</th>
            </tr>
          </thead>

          <tbody>
            <tr className='text-center border-1 border-black'>
              <td className='py-1 px-1'>64645</td>
              <td className='py-1 px-1'>75564</td>
              <td className='py-1 px-1'>online</td>
              <td className='py-1 px-1'>7592</td>
              <td className='py-1 px-1'>800</td>

            </tr>
          </tbody>
        </table>
    {/* start table */}
      </DivTag>
      
     </DivTag> 
  );
};

export default CommissionCharges;