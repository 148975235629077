import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SliderCrousel extends Component {
    render() {
        return (
            <Carousel autoPlay={true} infiniteLoop={true} transitionTime={500} showThumbs={false} showIndicators={true}>
                <div>
<img style={{width:'fit-content',height:'fit-content', maxHeight:'400px'}} src={process.env.PUBLIC_URL + '/media/slider_img/Aadhar Verification API for IT solution website.png'} />
                    {/* <p className="legend">New Product 1</p> */}
                </div>
                <div>
<img style={{width:'fit-content',height:'fit-content', maxHeight:'400px'}} src={process.env.PUBLIC_URL + '/media/slider_img/DL Verification API for IT Solution website.png'} />
                    {/* <p className="legend">New Product 2</p> */}
                </div>
                <div>
<img style={{width:'fit-content',height:'fit-content', maxHeight:'400px'}} src={process.env.PUBLIC_URL + '/media/slider_img/IFSC verification API for IT solution website.png'} />
                    {/* <p className="legend">New Product 3</p> */}
                </div>
<div>
<img style={{width:'fit-content',height:'fit-content', maxHeight:'400px'}} src={process.env.PUBLIC_URL + '/media/slider_img/Pan verification API for IT solution website.png'} />
{/* <p className="legend">New Product 3</p> */}
</div>
            </Carousel>
        );
    }
};

export default SliderCrousel;