import React, { useState, useEffect } from 'react';
import { DivTag, HeadTag, Button, ImgTag, SpanTag, FormTag, InputField } from '../../Components/Tags/Tags';
import SliderCrousel from '../../Components/SliderLoginPage/SliderCrousel';
import { RiLockPasswordFill } from "react-icons/ri";
import PinInput from '../../Components/PinInput';
import { Link } from 'react-router-dom';

const Auth2StepsPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [hasTyped, setHasTyped] = useState(false);

  useEffect(() => {
    if (hasTyped) {
      validatePassword();
    }
  }, [newPassword, confirmPassword]);

  const validatePassword = () => {
    const pattern = /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]+$/; // Alphanumeric, at least one alphabet and one number
    const isValidLength = newPassword.length >= 6; // Minimum length 6
    const isValidPattern = pattern.test(newPassword);
    const isMatched = newPassword === confirmPassword;

    if (!isValidLength) {
      setAlertMessage('Password must be at least 6 characters long.');
    } else if (!isValidPattern) {
      setAlertMessage('Password must contain at least one letter and one number.');
    } else if (!isMatched) {
      setAlertMessage('Passwords do not match.');
    } else {
      setAlertMessage('');
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setHasTyped(true);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setHasTyped(true);
  };

  const ChangePassword = () => {
    if (newPassword.trim() === '' || confirmPassword.trim() === '') {
      setAlertMessage('Please enter New Password and Confirm Password.');
      return false;
    }

    if (alertMessage === '') {
      // Passwords are valid and match
      setAlertMessage('Password changed successfully');
      return true;
    }
    
    return false;
  };

  return (
    <>
     {/* <PopupModal isOpen={isOpenLockScreen} onClose={handleCloseModalLock}>
        <FormTag method="">
          <DivTag className="flex justify-center mb-5">
            <Button
              className="bg-red-600 hover:bg-blue-500 rounded-lg text-white"
              onClick={handleCloseModalLock}
              icons={<IoMdClose size={24} />}
            />
          </DivTag>
         
        </FormTag>
      </PopupModal> */}
      <DivTag className="mx-auto bg-slate-300 min-h-screen flex flex-col md:flex-row mb-0 p-6 sm:p-0"> 
        <SliderCrousel className="hidden md:block w-1/3" />
        <DivTag className="flex flex-col items-center w-full sm:w-1/3 sm:mt-[10rem] sm:p-12 ">
          <ImgTag src="assets/images/IDSPayLogo.png" alt="logo" className="" />
          <DivTag className="text-center mt-5">
            <HeadTag className="text-black text-xl font-bold animate-bounce" text="2 Step Authentication" />
            <HeadTag className="text-blue-800 text-2xl font-semibold underline animate-pulse font-serif" text="Enter code and set a new password" />
          </DivTag>
        </DivTag>
        <FormTag className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3' method="post" autoComplete="off">
          <DivTag className="flex flex-col items-center w-full mt-2 sm:mt-[12rem] md:mt-[12rem] lg:mt-[12rem] xl:mt-[12rem] 2xl:mt-[12rem]">
            <SpanTag className="text-black mb-7 font-semibold text-md" text="Verification code has been successfully sent on 985XXXXX38" />
            <DivTag className="mb-5">
              <PinInput />
            </DivTag>
            <DivTag className="w-full sm:p-8">
              <div className="flex flex-row gap-1 bg-white/80 rounded-md py-2 px-1 w-full border-blue-500 shadow-md shadow-blue-700">
                <RiLockPasswordFill size={26} color='blue' />
                <InputField
                  className="bg-inherit focus:outline-none pl-1 w-full"
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  maxLength={12}
                  minLength={6}
                  placeholder="Enter New Password"
                  
                />
              </div>
              <div className="flex flex-row gap-1 bg-white/80 rounded-md py-2 px-1 w-full mt-4 border-blue-500 shadow-md shadow-blue-700">
                <RiLockPasswordFill size={26} color='blue' />
                <InputField
                  className="bg-inherit focus:outline-none pl-1 w-full"
                  type="password"
                  id="Confirmpassword"
                  name="Confirmpassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  maxLength={12}
                  minLength={6}
                  placeholder="Enter Confirm Password"
                  
                />
              </div>
              {alertMessage && <div className="alert text-red-500 text-sm mt-2">{alertMessage}</div>}
            </DivTag>
            <DivTag className="mt-2 flex flex-col items-center">
              {newPassword.trim() !== '' && confirmPassword.trim() !== '' && newPassword === confirmPassword ? (
                <Link to="/ConfirmAuth2Steps">
                  <Button
                    type="submit"
                    text="Change Password"
                    className="text-center text-white bg-blue-900 shadow-lg 
                    bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900
                      font-semibold rounded-md px-2 py-1 text-xl hover:from-blue-900 hover:to-blue-400 border-2"
                    id="changePassword"
                    name="changePassword"
                    onClick={ChangePassword}
                  />
                </Link>
              ) : (
                <Button
                  type="submit"
                  text="Change Password"
                  className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r
                  from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-3 py-1 
                  text-xl hover:from-blue-900 hover:to-blue-400 border-2"
                  id="changePassword"
                  name="changePassword"
                  onClick={ChangePassword}
                />
              )}
              <Link to="/LoginUserPage" className="mt-3 font-bold text-dark underline">Logout</Link>
            </DivTag>
          </DivTag>
        </FormTag>
      </DivTag>
    </>
  );
};

export default Auth2StepsPage;
