

import React, { useState } from 'react';
import PropTypes from 'prop-types';

//Div Tag
export const DivTag = ({ children, className, datasitekey, style, ariaValuenow, dataToggle, ariaHaspopup, ariaExpanded, id }) => {
  return (
    <div
      className={className}
      data-sitekey={datasitekey}
      data-toggle={dataToggle}
      aria-haspopup={ariaHaspopup}
      aria-expanded={ariaExpanded}
      style={style}
      aria-valuenow={ariaValuenow}
      id={id}
    >
      {children}
    </div>
  );
};



// h1  heading Tag
export const HeadTag = ({ className, text, children }) => {
  return (
    <h1
      className={className}
      text={text}
    >
      {children}
      {text}
    </h1>
  );
};



// Button Tag
export const Button = ({ type, text, children, 
  className = 'text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 rounded-md p-1 sm:px-2 py-1  text-xl hover:from-blue-900 hover:to-blue-400 border-1'
  , id, name, onClick, areaLabel, dataRippleLight, icons,onChange }) => {
  return (
    <button
      type={type}
      className={className}
      id={id}
      name={name}
      onClick={onClick}
      aria-label={areaLabel}
      data-ripple-light={dataRippleLight}
      onChange={onChange}

    >
      {text}
      {children}
      {icons}
    </button>
  );
};


// SelectField Tags
export const SelectField = ({ id, name, value, onChange, className, children,required= true }) => {
  return (
    <select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      required={required}
    >
      {children}
    </select>
  );
};

 //Input Field

export const InputField = ({
  ref,
  type = 'text',
  className,
  id,
  name,
  placeholder,
  onClick,
  onChange,
  required = true,
  onKeyDown,
  value,
  maxLength,
  minLength,
  autofocus = false,
  
}) => {
  return (
    <input
      ref={ref}
      type={type}
      className={className}
      id={id}
      name={name}
      placeholder={placeholder}
      onClick={onClick}
      onKeyDown={onKeyDown}
      value={value}
      onChange={onChange}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      autofocus={autofocus}
    />
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  onKeyDown: PropTypes.func,
  value: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  autofocus: PropTypes.bool
};


//Input Field With Label

export const InputWithLabel = ({ label, placeholder, name,autofocus, id, type,minLength,maxLength, value, onChange, DivclassName, onBlur, error, required ,requiredfield= true ,readOnly= false }) => {
  return (
    <div className={DivclassName}>
      <label htmlFor={id} className="block tracking-wide text-s font-bold mb-0">
        {label}  {required && <span className="text-red-500">*</span>}
      </label>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur} 
        className="appearance-none block w-full text-gray-700  border border-blue-600 rounded p-1 sm:py-1 sm:px-2
         leading-tight focus:outline-none focus:bg-white"
         readOnly={readOnly}  
         required={requiredfield}
         minLength={minLength}
         maxLength={maxLength}
         autofocus={autofocus}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>} 
    </div>
  );
};

 //Select Field

 export const SelectWithLabelOption = ({ id, name, value, onChange, onBlur, type, children, DivclassName, error, label, required , requiredField= true , readOnly= false }) => {
  return (
    <div className={DivclassName}>
      <label htmlFor={id} className="block tracking-wide text-s font-bold mb-0">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <select
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="appearance-none block w-full text-gray-700 rounded p-1 sm:py-1 sm:px-2
         leading-tight focus:outline-none focus:bg-white text-center border border-blue-600"
         readOnly={readOnly}
         required={requiredField}
      >
        {children}
      </select>
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};




//  TextArea Tag
export const TextArea = ({ ref, type, className, id, name, placeholder, onClick, onChange,required = true ,children }) => {
  return (
    <textarea
      ref={ref}
      type={type}
      className={className}
      id={id}
      name={name}
      placeholder={placeholder}
      onClick={onClick}
      onChange={onChange}
      required={required}
    >
      {children}
    </textarea>
  );
};


// Image Tag
export const ImgTag = ({ src, alt, className, id }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      id={id}
    />
  );
};

// Paragraph Tag
export const PTag = ({ className, text, id, name, children }) => {
  return (
    <p
      className={className}
      name={name}
      id={id}
    >
      {children}
      {text}
    </p>
  );
};

// Span Tag
export const SpanTag = ({ className, id, text, children }) => {
  return (
    <span
      className={className}
      id={id}
    >
      {children}
      {text}
    </span>
  );
};


// Label tag
export const LabelTag = ({ htmlFor, children, className, text }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={className}
    >
      {children}
      {text}
    </label>
  );
};


export const FormTag = ({ className, method, id, name, autoComplete = 'off', onClick, onSubmit, children }) => {
  return (
    <form
      className={className}
      method={method}
      id={id}
      name={name}
      autoComplete={autoComplete}
      onClick={onClick}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};


export const MarqueeTag = ({ children, className }) => {
  return (
    <marquee
      className={className}
    >
      {children}

    </marquee>
  );
};














