import React, { useState } from 'react';

const ReportModal = ({ isOpen, onClose,highmarkResponseData,submittedName,GenderCategory }) => {
  const [isPrinting, setIsPrinting] = useState(false);

  if (!isOpen) return null; // Do not render anything if not open

  const printDiv = () => {
    setIsPrinting(true);
    
    // Create a temporary window for printing
    const printWindow = window.open('', '', 'height=600,width=800');
    
    const printContents = document.getElementById("invoicedata").innerHTML;

    // Write the content to the new window
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">');
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    
    printWindow.document.close(); // Close the document for rendering
    printWindow.focus(); // Focus on the new window
    
    setTimeout(() => {
        printWindow.print(); // Trigger print
        setIsPrinting(false);
    }, 100); 
};

// Function to get the color based on payment status
const getPaymentStatusColor = (status) => {
  if (!status) return "bg-gray-500"; // Default for missing status

  switch (status.trim().toUpperCase()) {
    case "STD":  // Current Account (000)
      return "bg-green-500"; // Green for current account
    case "XXX":  // 90+ days past due (90+, 120+)
      return "bg-red-500";   // Red for overdue
    case "NEW":  // New account (NEW)
      return "bg-blue-500"; // Blue for new account
    case "SUB":  // 1-29/30-59 days past due (01+, 30+)
      return { style: { backgroundColor: '#b2951cd9' } }; // Custom inline color for substandard
    case "SMA":  // 60-89 days past due (60+)
      return "bg-orange-500"; // Orange for substandard
    case "LSS":  // Restructured (RES, RNC)
      return "bg-purple-500"; // Purple for restructured
    case "INV":  // Invoked (INV), 360+ days overdue
      return { style: { backgroundColor: '#8B0000' } }; // Custom inline dark red for invoked
    case "CLSD":  // Closed Account (CLSD)
      return "bg-gray-500"; // Gray for closed account
    default:
      return "bg-gray-500";  // Default for other cases
  }
};


function getPaymentStatusColor2(statusCode) {
  switch (statusCode) {
    case "000": // Current Account
      return "bg-green-500";
    case "01+": // 1-29/30-59 days past due
    case "30+":
      return "bg-[#b2951cd9]";
    case "60+": // 60-89 days past due
      return "bg-orange-500";
    case "90+": // 90+ days past due
    case "120+":
      return "bg-red-500";
    case "CLSD": // Closed Account
      return "bg-gray-500";
    case "NEW": // New Account
    case "SET": // Settled
      return "bg-blue-500";
    case "RES": // Restructured
    case "RNC":
      return "bg-purple-500";
    case "INV": // Invoked or 360+ days overdue
      return "bg-red-900";
     case "023":
      return "bg-slate-400"; 
    case "025": // Custom code
      return "bg-yellow-400"; // Assign a color specific to "025"
    case "026": // Custom code
      return "bg-yellow-600"; // Assign a color specific to "026"
    // Add more cases as needed for other custom codes
    default:
      return "bg-gray-300"; // Default color for unrecognized statuses
  }
}










const idTypeMap = {
  "ID07": "PAN :",
  "ID03": "Aadhaar :",
  "ID02": "Voter ID :",
  "ID05": "Ration Card :",
  "ID06": "Driving Licence :",
  "ID01": "Passport",
  "ID04": "Others",
  "ID012": "ID 012",
  "ID013": "ID 013"
};



const responses = highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"];
const responseArray = Array.isArray(responses) ? responses : [responses];


    return (
        <div className="fixed inset-0 flex  flex-col items-center justify-center z-50 backdrop-blur-sm bg-white bg-opacity-50 md:p-0 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full md:max-w-3xl overflow-y-auto max-h-[100vh]" id="invoicedata">
                <div className="a4-page">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                        <div className="col-span-2">
                            <img 
                                src="https://cdn.worldvectorlogo.com/logos/highmark-1.svg"
                                className="w-24 p-1"
                                alt="Company Logo"
                            />
                        </div>
                        <div className="col-span-8 md:text-center text-center">
                            <h4 className="text-2xl font-bold">Credit Report</h4>
                        </div>
                        <div className="col-span-2 md:text-end text-end">
                        Consumer Credit Score: <strong className="text-xl">
                        { highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"]}
                        </strong>
                        </div>
                    </div>

                    <div className="flex justify-between items-start flex-wrap">
  {/* Start section */}
  <div className="">
    <p><i>Client ID:</i> <span className="client_id">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["BATCH-ID"]}</span></p>
    <p><i>Report Order No:</i> <span className="report_order_no">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["REPORT-ID"]}</span></p>
  </div>

  {/* End section */}
  <div className="text-right">
    <p><i>Date:</i> <span className="report_date">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["DATE-OF-REQUEST"]}</span></p>
    {/* <p><i>Time:</i> <span className="report_time">{highmarkResponseData?.InquiryResponseHeader?.Time}</span></p> */}
  </div>
</div>

<div className='flex justify-center'>
        <p className='font-bold text-white'>Personal Details</p>
    </div>
<div className="border border-blue-500 p-2 rounded-md">
   
  <div className="grid grid-cols-2 md:grid-cols-2  gap-1">
    {/* Consumer Name */}
    <div>
    <p><i>Consumer Name</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"].length===0? submittedName: highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"]}</span></p>
      {/* <p><i>Consumer Name</i><span> : {submittedName}</span></p> */}
    </div>
    <div>
      <p><i>D.o.b</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["DOB"]}</span></p>
    </div>
    <div>
      <p><i>Age</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["AGE"]}</span></p>
    </div>
    <div>
      <p><i>Gender</i><span> : {GenderCategory}</span></p>
    </div>
    <div>
      <tbody>
  {(() => {
    const phoneVariations = highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["PERSONAL-INFO-VARIATION"]?.["PHONE-NUMBER-VARIATIONS"]?.["VARIATION"];

    // Check if phoneVariations is an array and has elements
    if (Array.isArray(phoneVariations) && phoneVariations.length > 0) {
      return phoneVariations.map((phoneDetail, index) => (
        <tr key={index}>
          <td className="px-2 py-1 text-sm">Mob {index + 1} : {phoneDetail?.["VALUE"]}</td>
        </tr>
      ));
    } 
    // Check if phoneVariations is a single object
    else if (phoneVariations && typeof phoneVariations === 'object') {
      return (
        <tr>
          <td className="px-2 py-1 text-sm">Mob 1 : {phoneVariations?.["VALUE"]}</td>
        </tr>
      );
    } 
    // If no phone variations are found
    else {
      return (
        <tr>
          <td colSpan={3} className="border px-2 py-1 text-sm text-center">No phone number variations found.</td>
        </tr>
      );
    }
  })()}
</tbody>

    </div>


{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"] && (
  <table className="table-auto w-full">
    <tbody>
      {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]) ? (
        highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.map((idDetail, index) => (
          <tr key={index}>
            <td className=" px-2 py-1 text-sm">{idTypeMap[idDetail?.["TYPE"]] || idDetail?.["TYPE"]}</td> {/* ID Type mapped to friendly name */}
            <td className=" px-2 py-1 text-sm">{idDetail?.["VALUE"]}</td> {/* ID Value */}
          </tr>
        ))
      ) : (
        <tr>
          <td className=" px-2 py-1 text-sm">{idTypeMap[highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.["TYPE"]] || highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.["TYPE"]}</td>
          <td className=" px-2 py-1 text-sm">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.["VALUE"]}</td>
        </tr>
      )}
    </tbody>
  </table>
)}

  </div>
</div>

                    <hr className="my-2" />
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2 rounded-tr-xl rounded-tl-xl">Address</div>
                    <table className="min-w-full table-auto border border-black text-left ">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-2 py-1">Sn</th>
      <th className="border border-black px-2 py-1">Address</th>
      <th className="border border-black px-2 py-1">Reported Date</th>
    </tr>
  </thead>
  <tbody className="address-info text-sm">
  {(() => {
    const variations = highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["PERSONAL-INFO-VARIATION"]?.["ADDRESS-VARIATIONS"]?.["VARIATION"];
    
    // Check if variations is an array
    if (Array.isArray(variations) && variations.length > 0) {
      return variations.map((address, index) => (
        <tr key={index}>
          <td className="border border-black px-1 font-bold text-xs">Address : {index + 1}</td> 
          <td className="border border-black px-1">{address?.VALUE}</td> 
          <td className="border border-black px-1">{address?.["REPORTED-DATE"]}</td> 
        </tr>
      ));
    } 
    // Check if variations is a single object
    else if (variations && typeof variations === 'object') {
      return (
        <tr>
          <td className="border border-black px-1 font-bold text-xs">Address : 1</td>
          <td className="border border-black px-1">{variations?.VALUE}</td>
          <td className="border border-black px-1">{variations?.["REPORTED-DATE"]}</td>
        </tr>
      );
    } 
    // If there are no variations found
    else {
      return (
        <tr>
          <td colSpan="3" className="border border-black px-2 py-1 text-center">No address variations found</td>
        </tr>
      );
    }
  })()}
</tbody>

</table>

                       {/* Credit Score Section */}
                    <div className=" bg-blue-900 md:text-white p-2 text-white mt-2 rounded-tr-xl rounded-tl-xl">HighMark Score</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr className='text-left'>
      <th className="border border-black px-2 py-1">Score Name</th>
      <th className="border border-black px-2 py-1">Score</th>
      <th className="border border-black px-2 py-1">Scoring Elements</th>
    </tr>
  </thead>
  <tbody className="scoreDetailsInfo">
  {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]) ? (
  highmarkResponseData["INDV-REPORTS"]["INDV-REPORT"]["SCORES"]["SCORE"].map((scoreDetail, index) => (
    <tr key={index} className=''>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-TYPE"]}</td>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-VALUE"]}</td>
      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-FACTORS"] || scoreDetail?.["SCORE-COMMENTS"]}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="3" className="text-center">No scores available</td>
  </tr>
)}
</tbody>

</table>
                         {/* Accounts Section */}
                         <div className=" bg-blue-900 md:text-white text-white p-2 mt-4 rounded-tr-xl rounded-tl-xl">Account Details</div>


      {/* 1st Retail */}
    <table className="min-w-full table-auto text-left mt-2 border-collapse">
      <tbody className="mb-3">
        {responseArray.map((response, index) => (
          <React.Fragment key={index}>
            {response?.["LOAN-DETAILS"] && (
              <>
                <tr className="bg-blue-800 md:text-white text-white">
                  <td colSpan={6} className="px-2 py-1 border rounded-tr-md rounded-tl-md">
                    RETAIL: {response?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                  </td>
                </tr>

                <tr className="border">
                  <td colSpan={6} className="px-2 py-1 border ">
                    <div className="grid grid-cols-3 gap-2">
                      <div><span className="font-semibold">Sanction Amount:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-AMT"]}</div>
                      <div><span className="font-semibold">Balance:</span> {response?.["LOAN-DETAILS"]?.["CURRENT-BAL"]}</div>
                      <div><span className="font-semibold">OverDue:</span> {response?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</div>
                      <div><span className="font-semibold">Account Status:</span> {response?.["LOAN-DETAILS"]?.["ACCOUNT-STATUS"]}</div>
                      <div><span className="font-semibold">Date Opened:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</div>
                    </div>
                  </td>
                </tr>

                {/* Detailed Information */}
                <tr>
                  <td colSpan={6} className="px-2 py-1 border-t">
                    <div className="grid grid-cols-3 gap-2">
                      <div><span className='font-semibold'>Account Number:</span> {response?.["LOAN-DETAILS"]?.["ACCT-NUMBER"]}</div>
                      <div><span className='font-semibold'>Institution:</span>{response?.[""] || "N/A"}</div>


                      {/* <div><span className='font-semibold'>Account Type:</span> {response?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}</div> */}


                      <div><span className='font-semibold'>Ownership Type:</span> {response?.["LOAN-DETAILS"]?.["OWNERSHIP-IND"]}</div>
                      <div><span className='font-semibold'>Balance:</span> {response?.["LOAN-DETAILS"]?.["CURRENT-BAL"]}</div>
                      <div><span className='font-semibold'>Open:</span></div>
                      <div><span className='font-semibold'>Sanction Amount:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-AMT"]}</div>
                      <div><span className='font-semibold'>Source:</span> {response?.["LOAN-DETAILS"]?.["OWNERSHIP-IND"]}</div>
                      <div><span className='font-semibold'>Interest Rate:</span> N/A</div>
                      <div><span className='font-semibold'>Repayment Tenure:</span>{response?.["LOAN-DETAILS"]?.["ORIGINAL-TERM"]}</div>
                      <div><span className='font-semibold'>Installment Amount:</span> {response?.["LOAN-DETAILS"]?.["INSTALLMENT-AMT"]?.split('/')[0]}</div>
                      <div><span className='font-semibold'>Term Frequency:</span> {response?.["LOAN-DETAILS"]?.["INSTALLMENT-AMT"]?.split('/')[1]}</div>
                     
                      <div><span className='font-semibold'>Date Opened:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</div>
                      
                      <div><span className='font-semibold'>Account Status:</span> {response?.["LOAN-DETAILS"]?.["ACCOUNT-STATUS"]}</div>

                    </div>
                    <div className="grid grid-cols-3 gap-2 border-t">
                      <div><span className='font-semibold'>Date Reported:</span> {response?.["LOAN-DETAILS"]?.["DATE-REPORTED"]}</div>
                      <div><span className='font-semibold'>Dispute Code:</span>{response?.[""]?.[""] || "N/A"} </div>
                      <div><span className='font-semibold'>Asset Classification:</span>{response?.[""]?.[""] || "N/A"}</div>
                      </div>
                  </td>
                </tr>

                {/* Payment History if available */}
                {response?.["LOAN-DETAILS"]?.["COMBINED-PAYMENT-HISTORY"] && (
                  <tr className="my-2">
                    <td colSpan={6} className="px-1 py-0 mb-1">
                      <h2 className="font-semibold">History</h2>
                      <div className="flex flex-wrap mb-1 mt-1 gap-0.5 border border-black p-0.5 rounded-md">
                        <div className='border p-1 border-black rounded-lg items-center text-center'>
                          <p className='text-xs mt-3'>Key Payment Status</p>
                        </div>
                        {Array.isArray(response["LOAN-DETAILS"]["COMBINED-PAYMENT-HISTORY"].split('|')) ? (
                          response["LOAN-DETAILS"]["COMBINED-PAYMENT-HISTORY"].split('|').map((history, idx) => {
                            const [monthYear, status] = history.split(',');
                            const statusCode = status?.split('/')[0]; 
                            return (
                              <div key={idx} className="mx-1 flex flex-col items-center border p-1 border-black rounded-lg">
                                {/* Colored circle */}
                                {/* <span className={`flex items-center justify-center w-6 h-6 text-white text-xs rounded-full ${getPaymentStatusColor(statusCode)}`}>
                                  <p className="mt-[-6px]">{statusCode}</p> 
                                </span> */}

                                <p className="text-xs mt-1">{monthYear}</p>
                                <p className="text-xs">{statusCode}</p> 
                              </div>
                            );
                          })
                        ) : (
                          <div>No payment history available.</div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
 {/* Linked Accounts Section */}
 {response?.["LOAN-DETAILS"]?.["LINKED-ACCOUNTS"]?.["ACCOUNT-DETAILS"] && (
  <tr className="my-2">
    <td colSpan={6} className="px-1 py-0 "> {/* Added border-b-2 for bottom border */}
      <h2 className="font-semibold bg-blue-800 text-white p-2 rounded-tr-xl rounded-tl-xl">Linked A/c Details</h2>
      <div className="flex flex-wrap mt-1"> {/* Added border-b-2 to this div for content separation */}
        {Array.isArray(response["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]) ? (
          response["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"].map((LinkedAC, idx) => (
            <div key={idx} className="grid grid-cols-4 gap-2 mb-2">
              <div><span className="font-semibold">Account Number:</span> {LinkedAC?.["ACCT-NUMBER"]}</div>
              <div><span className="font-semibold">Credit Guarantor:</span> {LinkedAC?.["CREDIT-GUARANTOR"]}</div>
              <div><span className="font-semibold">Account Type:</span> {LinkedAC?.["ACCT-TYPE"]}</div>
              <div><span className="font-semibold">Ownership Type:</span> {LinkedAC?.["OWNERSHIP-IND"]}</div>
              <div><span className="font-semibold">Date Reported:</span> {LinkedAC?.["DATE-REPORTED"]}</div>
              <div><span className="font-semibold">Account Status:</span> {LinkedAC?.["ACCOUNT-STATUS"]}</div>
              <div><span className="font-semibold">Disbursed Amount:</span> ₹{LinkedAC?.["DISBURSED-AMT"]}</div>
              <div><span className="font-semibold">Disbursed Date:</span> {LinkedAC?.["DISBURSED-DATE"]}</div>
              <div><span className="font-semibold">Last Payment Date:</span> {LinkedAC?.["LAST-PAYMENT-DATE"]}</div>
              <div><span className="font-semibold">Overdue Amount:</span> ₹{LinkedAC?.["OVERDUE-AMT"]}</div>
              <div><span className="font-semibold">Current Balance:</span> ₹{LinkedAC?.["CURRENT-BAL"]}</div>
            </div>
          ))
        ) : (
          <div className="grid grid-cols-3 gap-2 mb-2">
            <div><span className="font-semibold">Account Number:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["ACCT-NUMBER"]}</div>
            <div><span className="font-semibold">Credit Guarantor:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["CREDIT-GUARANTOR"]}</div>
            <div><span className="font-semibold">Account Type:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["ACCT-TYPE"]}</div>
            <div><span className="font-semibold">Ownership Type:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["OWNERSHIP-IND"]}</div>
            <div><span className="font-semibold">Date Reported:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["DATE-REPORTED"]}</div>
            <div><span className="font-semibold">Account Status:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["ACCOUNT-STATUS"]}</div>
            <div><span className="font-semibold">Disbursed Amount:</span> ₹{response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["DISBURSED-AMT"]}</div>
            <div><span className="font-semibold">Disbursed Date:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["DISBURSED-DATE"]}</div>
            <div><span className="font-semibold">Last Payment Date:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["LAST-PAYMENT-DATE"]}</div>
            <div><span className="font-semibold">Overdue Amount:</span> ₹{response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["OVERDUE-AMT"]}</div>
            <div><span className="font-semibold">Current Balance:</span> ₹{response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["CURRENT-BAL"]}</div>
          </div>
        )}
      </div>
    </td>
  </tr>
)}


              </>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
       {/* 1st ENd */}





                        {/* 2nd Microfinance */}
                        <table className="min-w-full table-auto text-left mt-2 border-collapse">
  <tbody className="mb-3">
    {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["INDV-RESPONSES"]?.["INDV-RESPONSE-LIST"]?.["INDV-RESPONSE"]) &&
      highmarkResponseData["INDV-REPORTS"]["INDV-REPORT"]["INDV-RESPONSES"]["INDV-RESPONSE-LIST"]["INDV-RESPONSE"].map((response, index) => (
        <React.Fragment key={index}>
          {response?.["LOAN-DETAIL"] && (
            <>
              <tr className="bg-blue-800 md:text-white text-white">
                <td colSpan={6} className="px-2 py-1 border rounded-tr-md rounded-tl-md mt-4 mb-4">
                  MICROFINANCE: {response?.["LOAN-DETAIL"]?.["ACCT-TYPE"]}
                </td>
              </tr>

              <tr className="border">
                <td colSpan={6} className="px-2 py-1 border ">
                  <div className="grid grid-cols-3 gap-2">
                    <div><span className="font-semibold">Sanction Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>
                    <div><span className="font-semibold">Balance:</span> {response?.["LOAN-DETAIL"]?.["CURRENT-BAL"]}</div>
                    <div><span className="font-semibold">OverDue:</span> {response?.["LOAN-DETAIL"]?.["OVERDUE-AMT"]}</div>
                    <div><span className="font-semibold">Account Status:</span> {response?.["LOAN-DETAIL"]?.["STATUS"]}</div>
                    <div><span className="font-semibold">Date Opened:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-DT"]}</div>
                  </div>
                </td>
              </tr>

              {/* Detailed Information */}
              <tr>
              <td colSpan={6} className="px-2 py-1 border">
  {/* Grid layout for details */}
  <div className="grid grid-cols-3 gap-2">
    {/* General Loan Details */}
    <div><span className='font-semibold'>Account Number:</span> {response?.["LOAN-DETAIL"]?.["ACCT-NUMBER"]}</div>
    <div><span className='font-semibold'>Institution:</span> {response?.["MFI"]}</div>
    <div><span className='font-semibold'>Balance:</span> {response?.["LOAN-DETAIL"]?.["CURRENT-BAL"]}</div>
    <div><span className='font-semibold'>Open:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-DT"]}</div>
    <div><span className='font-semibold'>Sanction Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>
    <div><span className='font-semibold'>Disbursed Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>

    <div><span className='font-semibold'>Loan Category:</span> {response?.["LOAN-DETAIL"]?.["ACCT-TYPE"]}</div>
    <div><span className='font-semibold'>Loan Purpose:</span> {response?.[""] || 'N/A'}</div>
    <div><span className='font-semibold'>Loan Cycle:</span>{response?.["LOAN-DETAIL"]?.["LOAN-CYCLE-ID"]}</div>
    <div><span className='font-semibold'>Installment Amount:</span> {response?.["LOAN-DETAIL"]?.["INSTALLMENT-AMT"]}</div>
    <div><span className='font-semibold'>No Of Installment:</span> {response?.[""] || "N/A"}</div>
    <div><span className='font-semibold'>Last Payment Date:</span> {response?.["LOAN-DETAIL"]?.["INFO-AS-ON"]}</div>
    <div><span className='font-semibold'>Date Reported:</span>{response?.["reportDt"] || "N/A"}</div>
    <div><span className='font-semibold'>Date Opened:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-DT"]}</div>
    <div><span className='font-semibold'>Repayment Tenure:</span> {response?.["LOAN-DETAIL"]?.["FREQ"]}</div>
  </div>

  {/* Section: Key Personal Details */}
  <h2 className="font-semibold mt-4 mb-2 border-t">Key Personal Details :</h2>
  <div className="grid grid-cols-2 gap-2">
    <div><span className='font-semibold'>Name:</span> {response?.["NAME"] || "N/A"}</div>
    <div><span className='font-semibold'>Relation Type:</span> {response?.["RELATIONS"]?.["RELATION"]?.[1]?.["TYPE"] || "N/A"}</div>
  </div>

  {/* Section: MFI Details */}
  <h2 className="font-semibold mt-4 mb-2 border-t">MFI Details :</h2>
  <div className="grid grid-cols-2 gap-2">
    <div><span className='font-semibold'>Mfi Client Full Name:</span> {response?.["NAME"] || "N/A"}</div>
    <div><span className='font-semibold'>Gender:</span> {response?.[""]?.[""] || "N/A"}</div>
    <div><span className='font-semibold'>Member ID:</span> {response?.["CNSMRMBRID"]}</div>
    <div><span className='font-semibold'>Sanction Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>
  </div>

  {/* Section: MFI Address */}
  <h2 className="font-semibold mt-4 mb-2 border-t">MFI Address :</h2>
  <div className="grid grid-cols-2 gap-2">
    <div><span className='font-semibold'>Address:</span> {response?.["ADDRESSES"]?.["ADDRESS"]?.[0] || "N/A"}</div>
    <div><span className='font-semibold'>Date Reported:</span> {response?.["reportDt"] || "N/A"}</div>
  </div>
</td>

              </tr>
             
              {/* Payment History if available */}
              {response?.["LOAN-DETAIL"]?.["COMBINED-PAYMENT-HISTORY"] && (
  <tr className="my-2">
    <td colSpan={6} className="px-1 py-0 border-b border-black rounded-2xl">
      <h2 className="font-semibold">History</h2>
      
      {/* Wrapper div that always includes border-bottom and margin-bottom */}
      <div className="flex flex-wrap mb-3 mt-1 gap-0.5 border border-black p-0.5 rounded-md">
        
        {/* Key Payment Status Box */}
        <div className='border p-1 border-black rounded-lg items-center text-center'>
          <p className='text-xs mt-3'>Key Payment Status</p>
        </div>

        {/* Payment History Mapping */}
        {Array.isArray(response?.["LOAN-DETAIL"]?.["COMBINED-PAYMENT-HISTORY"]?.split('|')) ? (
  response["LOAN-DETAIL"]["COMBINED-PAYMENT-HISTORY"].split('|').map((history, idx) => {
    const [monthYear, status] = history.split(','); // Extract the month and year, and status code
    const [month, year] = monthYear?.split(':'); // Separate month and year
    const formattedYear = year ? year.slice(-2) : ''; // Get the last two digits of the year (e.g., "24" for 2024)
    const statusCode = status; // Use the status directly, as we don't need to split it further

    return (
      <div key={idx} className="mx-1 flex flex-col items-center border p-1 border-black rounded-lg">
        <p className="text-xs mt-1">{month}:{formattedYear}</p>
        <p className="text-xs mb-1">{statusCode}</p>
        {/* <span className={`flex items-center justify-center w-6 h-6 text-white text-xs rounded-full ${getPaymentStatusColor2(statusCode)}`}>
          <p className="mt-[-6px]">{statusCode}</p>
        </span> */}
      </div>
    );
  })
) : (
  <div>No payment history available.</div>
)}

      </div>
    </td>
  </tr>
)}

            </>
          )}
        </React.Fragment>
      ))}
  </tbody>
                        </table>
                        {/* ENd Microfinance */}


{/* Color Pic for Retail */}
{/* <div className="flex flex-col space-y-2 p-4">
  <h3 className='font-bold'>Payment Status Legend</h3>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-green-500 mr-2"></div>
    <span>Current Account (000)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-[#b2951cd9] mr-2"></div> 
    <span>1-29/30-59 days past due (01+, 30+)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-orange-500 mr-2"></div>
    <span>60-89 days past due (60+)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-red-500 mr-2"></div>
    <span>90+ days past due (90+, 120+)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-gray-500 mr-2"></div>
    <span>Closed Account (CLSD)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-blue-500 mr-2"></div>
    <span>New Account (NEW), Settled (SET)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-purple-500 mr-2"></div>
    <span>Restructured (RES, RNC)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-red-900 mr-2"></div> 
    <span>Invoked (INV), 360+ days overdue</span>
  </div>
</div> */}

{/* Status Code */}
<div class="p-0">
  <h1 class="text-sm font-bold">Status Codes</h1>
  <div class="overflow-x-auto">
    <table class="w-full border border-black text-left text-sm">
      <thead>
        <tr class="bg-gray-200">
          <th class="border border-black">Code</th>
          <th class="border border-black">Description</th>
        </tr>
      </thead>
      <tbody className='text-xs'>
        <tr><td class="border border-black ">000</td><td class="border border-black ">Current Account</td></tr>
        <tr><td class="border border-black ">01+</td><td class="border border-black">1-29 days past due</td></tr>
        <tr><td class="border border-black ">30+</td><td class="border border-black ">30-59 days past due</td></tr>
        <tr><td class="border border-black ">60+</td><td class="border border-black">60-89 days past due</td></tr>
        <tr><td class="border border-black ">90+</td><td class="border border-black ">90-119 days past due</td></tr>
        <tr><td class="border border-black ">120+</td><td class="border border-black ">120-179 days past due</td></tr>
        <tr><td class="border border-black ">180+</td><td class="border border-black ">180-359 days past due</td></tr>
        <tr><td class="border border-black ">RES</td><td class="border border-black ">Restructured Loan</td></tr>
        <tr><td class="border border-black ">RGM</td><td class="border border-black ">Restructured Loan - Govt Mandate</td></tr>
        <tr><td class="border border-black ">CLSD</td><td class="border border-black ">Closed Account</td></tr>
        <tr><td class="border border-black ">PWOS</td><td class="border border-black ">Post Written Off Settled</td></tr>
        <tr><td class="border border-black ">360+</td><td class="border border-black ">360-539 days past due</td></tr>
        <tr><td class="border border-black ">540+</td><td class="border border-black ">540-719 days past due</td></tr>
        <tr><td class="border border-black ">*</td><td class="border border-black ">Data Not Reported</td></tr>
        <tr><td class="border border-black ">WDF</td><td class="border border-black ">Willful Default</td></tr>
        <tr><td class="border border-black ">720+</td><td class="border border-black ">720+ days past due</td></tr>
        <tr><td class="border border-black ">SF</td><td class="border border-black ">Suit Filed</td></tr>
        <tr><td class="border border-black ">SET</td><td class="border border-black ">Settled</td></tr>
        <tr><td class="border border-black ">INAC</td><td class="border border-black ">Account is Inactive</td></tr>
        <tr><td class="border border-black ">ADJ</td><td class="border border-black ">Adjustment Pending</td></tr>
        <tr><td class="border border-black ">STD</td><td class="border border-black ">Standard</td></tr>
        <tr><td class="border border-black ">LOSS</td><td class="border border-black ">Loss</td></tr>
        <tr><td class="border border-black ">SPM</td><td class="border border-black ">Special Mention</td></tr>
        <tr><td class="border border-black ">RNC</td><td class="border border-black ">Restructured Loan - Natural Calamity</td></tr>
        <tr><td class="border border-black ">LNSB</td><td class="border border-black ">Loan Submitted</td></tr>
        <tr><td class="border border-black ">FPD</td><td class="border border-black ">First Payment Default</td></tr>
        <tr><td class="border border-black ">SUB</td><td class="border border-black ">Sub-standard</td></tr>
        <tr><td class="border border-black ">DBT</td><td class="border border-black ">Doubtful</td></tr>
        <tr><td class="border border-black ">DIS</td><td class="border border-black ">Dispute Resolution Pending</td></tr>
        <tr><td class="border border-black ">LAND</td><td class="border border-black ">Loan Approved, Not Yet Disbursed</td></tr>
        <tr><td class="border border-black ">CUF</td><td class="border border-black ">Closed Account/Unpaid Final Bill</td></tr>
        <tr><td class="border border-black ">DEC</td><td class="border border-black ">Loan Declined</td></tr>
        <tr><td class="border border-black ">WOF</td><td class="border border-black ">Charge Off/Written Off</td></tr>
        <tr><td class="border border-black ">NEW</td><td class="border border-black ">New Account</td></tr>
        <tr><td class="border border-black ">BK</td><td class="border border-black ">Included in Bankruptcy</td></tr>
      </tbody>
    </table>
  </div>
</div>
{/* ENd */}




{/* Inquiry History Section */}
<div className="bg-blue-900 md:text-white text-white p-2 mt-4 rounded-tr-xl rounded-tl-xl">Inquiry History</div>
<table className="min-w-full table-auto text-left mt-2 border-collapse">
  <thead>
    <tr className="bg-gray-200">
      <th className="px-2 py-1 border">Member Name</th>
      <th className="px-2 py-1 border">Inquiry Date</th>
      <th className="px-2 py-1 border">Purpose</th>
      <th className="px-2 py-1 border">Amount</th>
      <th className="px-2 py-1 border">Remarks</th>
    </tr>
  </thead>
  <tbody>
  {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["INQUIRY-HISTORY"]?.["HISTORY"]) && 
  highmarkResponseData["INDV-REPORTS"]["INDV-REPORT"]["INQUIRY-HISTORY"]["HISTORY"].map((inquiry, index) => (
    <tr key={index} className="border">
      <td className="border">{inquiry?.["MEMBER-NAME"] || 'N/A'}</td>
      <td className="border">{inquiry?.["INQUIRY-DATE"] || 'N/A'}</td>
      <td className="border">{inquiry?.["PURPOSE"] || 'N/A'}</td>
      <td className="border">{inquiry?.["AMOUNT"] || 'N/A'}</td>
      <td className="border">{Array.isArray(inquiry?.["REMARK"]) && inquiry["REMARK"].length > 0 ? inquiry["REMARK"].join(', ') : 'N/A'}</td>
    </tr>
))}

  </tbody>
</table>


<div className="bg-blue-900 md:text-white text-white p-2 mt-4 rounded-tr-xl rounded-tl-xl">Account Summary</div>
<div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">

  {/* Primary Accounts Summary Card */}
  <div className="bg-gray-300 p-2 rounded-lg shadow-lg">
    <h2 className="font-semibold text-sm mb-2 bg-blue-700 rounded-sm text-white text-center">Primary Accounts Summary</h2>
    <div>
      <p className='border-b'><span className="font-semibold">Number of Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Active Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Overdue Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-OVERDUE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><spna className="font-semibold">Secured Accounts: </spna>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SECURED-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><spna className="font-semibold">Unsecured Accounts: </spna>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-UNSECURED-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Current Balance:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</p>
      <p className='border-b'><span className="font-semibold">Sanctioned Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SANCTIONED-AMOUNT"]}</p>
      <p className='border-b'><span className="font-semibold">Disbursed Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-DISBURSED-AMOUNT"]}</p>
    </div>
  </div>

  {/* Secondary Accounts Summary Card */}
  <div className="bg-gray-300  p-2 rounded-lg shadow-lg">
    <h2 className="font-semibold text-sm mb-2 bg-blue-700 rounded-sm text-white text-center">Secondary Accounts Summary</h2>
    <div>
      <p className='border-b'><span className="font-semibold">Number of Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Active Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Overdue Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-OVERDUE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Current Balance:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-CURRENT-BALANCE"]}</p>
      <p className='border-b'><span className="font-semibold">Sanctioned Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-SANCTIONED-AMOUNT"]}</p>
      <p className='border-b'><span className="font-semibold">Disbursed Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-DISBURSED-AMOUNT"]}</p>
    </div>
  </div>

  {/* Derived Attributes Card */}
  <div className="bg-gray-300  p-2 rounded-lg shadow-lg">
    <h2 className="font-semibold text-sm mb-2 bg-blue-700 rounded-sm text-white text-center">Derived Attributes</h2>
    <div>
      <p className='border-b'><span className="font-semibold">Inquiries in Last 6 Months:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["INQUIRIES-IN-LAST-SIX-MONTHS"]}</p>
      <p className='border-b'><span className="font-semibold">Length of Credit History:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["LENGTH-OF-CREDIT-HISTORY-YEAR"]} Year, {highmarkResponseData?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["LENGTH-OF-CREDIT-HISTORY-MONTH"]} Month</p>
      <p className='border-b'><span className="font-semibold">Average Account Age:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["AVERAGE-ACCOUNT-AGE-YEAR"]} Year, {highmarkResponseData?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["AVERAGE-ACCOUNT-AGE-MONTH"]} Month</p>
      <p className='border-b'><span className="font-semibold">New Accounts in Last 6 Months:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["NEW-ACCOUNTS-IN-LAST-SIX-MONTHS"]}</p>
      <p className='border-b'><span className="font-semibold">New Delinquent Accounts in Last 6 Months:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["NEW-DELINQ-ACCOUNT-IN-LAST-SIX-MONTHS"]}</p>
    </div>
  </div>
</div>


                      {/* Recent Activity Section */}
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2 rounded-tr-xl rounded-tl-xl">Recent Activity (Last 90 Days)</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-4 py-2">Total Inquiries</th>
      <th className="border border-black px-4 py-2">Accounts Opened</th>
      <th className="border border-black px-4 py-2">Accounts Updated</th>
      <th className="border border-black px-4 py-2">Accounts Delinquent</th>
    </tr>
  </thead>
  <tbody className="recentActivity">
    <tr className="border border-black text-center">
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
    </tr>
  </tbody>
</table>
                     </div>
                          {/* Summary Section */}
                     <div className="  bg-blue-900 md:text-white text-white p-2 mt-2 rounded-tr-xl rounded-tl-xl">Summary</div>
                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className='border border-black px-2 py-1'>Number of Accounts</th>
                <th className='border border-black px-2 py-1'>Total Balance Amount</th>
                <th className='border border-black px-2 py-1'>Recent Account</th>
              </tr>
            </thead>
            <tbody class="summary1">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</td>
                <td className='border border-black px-2 py-1'>
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}
                </td>
            </tr>
            </tbody>
                    </table>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
                 <tr>
                <th className="border border-black px-2 py-1">Number of Open Accounts</th>
                <th className="border border-black px-2 py-1">Total Past Due Amount</th>
                <th className="border border-black px-2 py-1">Oldest Account</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[5]?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</td>
            </tr>
            </tbody>
                     </table>

                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className="border border-black px-2 py-1">Total High Credit</th>
                <th className="border border-black px-2 py-1">Total Sanction Amount</th>
                <th className="border border-black px-2 py-1">Single Highest Sanction</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.[""]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SANCTIONED-AMOUNT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.[""]}</td>
            </tr>
            </tbody>
                         </table>
                 </div>
                 <div className="flex justify-center mt-4">
                <button
                    className="bg-green-500 text-white p-2 rounded hover:bg-green-700"
                    onClick={printDiv}
                    disabled={isPrinting}
                >
                    Print
                </button>
                <button
                    className="bg-red-500 text-white p-2 rounded hover:bg-red-700"
                    onClick={onClose} // This should close the modal
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ReportModal;
