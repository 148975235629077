import React, { useRef, useEffect } from 'react';

const PinInput = () => {
  const inputRefs = useRef([]);

  useEffect(() => {
    // Autofocus the first input field
    inputRefs.current[0].focus();
  }, []);

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleChange = (e, index) => {
    if (e.target.value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <div className="flex mb-5 gap-3">
      {Array.from({ length: 6 }).map((_, index) => (
        <React.Fragment key={index}>
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            className="w-8 h-11 text-center font-semibold border border-blue-400 shadow-md shadow-black"
            type="password"
            maxLength="1"
            onKeyDown={(e) => handleKeyDown(e, index)}
            onChange={(e) => handleChange(e, index)}
          />
          {index === 2 && <span className="text-black font-bold text-center">__</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PinInput;
