import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsMicFill } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VoiceControl = () => {
  const [isListening, setIsListening] = useState(false);
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();
  const navigate = useNavigate();

  recognition.continuous = false;
  recognition.interimResults = false;
  recognition.lang = 'en-US';

  // Start listening for voice commands
  const startListening = () => {
    setIsListening(true);
    recognition.start();

    recognition.onresult = (event) => {
      const command = event.results[0][0].transcript.trim().toLowerCase();
    //   alert(`You said: "${command}"`); // Show alert with spoken command
      handleVoiceCommand(command);
    };

    recognition.onerror = (event) => {
      console.error('Error in recognition:', event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  // Stop listening
  const stopListening = () => {
    setIsListening(false);
    recognition.stop();
  };

  // Map voice commands to routes
  const handleVoiceCommand = (command) => {
    if (command.includes('dashboard')) {
      navigate('/Dashboard'); // Navigate to dashboard
    } else if (command.includes('profile')) {
      navigate('/ProfileApiUser'); // Navigate to ProfileApiUser
    } else if (command.includes('add fund')) {
      navigate('/AddFund'); // Navigate to AddFund
    } else if (command.includes('equifax score')) {
      navigate('/EquifaxCrScore'); // Navigate to 
    } else if (command.includes('highmark score')) {
        navigate('/HighmarkCrScore');
    } else if (command.includes('credit Report')) {
    navigate('/EquifaxReport');
    }  else if (command.includes('developer api')) {
    navigate('/ServicePage');
    } else if (command.includes('Whitelist ip')) {
    navigate('/WhitelistIp');
    }  else if (command.includes('Whitelist Bank Account')) {
        navigate('/WhitelistYourBankAc');
        } else if (command.includes('wallet statement')) {
            navigate('/BusinessWalletStructure');
            } else if (command.includes('Commission and Charges')) {
                navigate('/CommissionCharges');
                }
    else {
      toast.error("Command not recognized.", {
        position: "top-center",
        className: "", // Apply transparent background and blur
        icon: "",
        closeButton: true,
        autoClose: 1000,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: true
      });


    }
  };

  return (
    <button 
      className={`mic-button ${isListening ? 'listening' : ''}`}
      onClick={isListening ? stopListening : startListening}
    >
      <BsMicFill size={20} />
      {isListening ? 'Listening...' : ''}
    </button>
  );
};

export default VoiceControl;
