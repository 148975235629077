import React from 'react';
import { Link } from 'react-router-dom';
import { FaCogs, FaDatabase } from 'react-icons/fa';

const PlanCard = ({ onButtonClick, serviceName, isAssigned, serviceId }) => {
  const handleButtonClick = () => {
    if (!isAssigned) {
      onButtonClick();
    } else {
      console.log('Service is already assigned, modal will not open.');
    }
  };

  return (
    <div className="w-full sm:w-4/5 md:w-4/5 lg:w-4/5 xl:w-4/5 2xl:w-4/5 p-2 bg-slate-300 rounded-lg shadow-md border border-blue-700 transition-transform transform hover:bg-blue-900 hover:text-white hover:scale-105">
    <div className="flex items-center justify-between mb-4">
      <div className="pricing-icon text-3xl">
        {isAssigned ? <FaCogs /> : <FaDatabase />}
      </div>
      {/* You can add additional icons or elements here if needed */}
    </div>
    <div className="mb-1">
      <h2 className="text-xl font-bold">{serviceName}</h2>
      <p className="text-sm">
        {isAssigned ? (
          <span className="text-green-600">(Assigned)</span>
        ) : (
          <span className="text-red-600">(Not Assigned)</span>
        )}
      </p>
    </div>
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
      <button
        className={`w-full sm:w-auto bg-blue-700 text-white px-1 py-1 text-xs rounded-lg hover:bg-purple-600 transition-colors duration-300 ${
          isAssigned
            ? 'opacity-50 cursor-not-allowed'
            : 'hover:opacity-90'
        }`}
        onClick={handleButtonClick}
        disabled={isAssigned}
      >
        {isAssigned ? 'Assigned' : 'Activate Request'}
      </button>
      <Link
        to={`/ApiLink?serviceId=${serviceId}`}
        className=" w-full sm:w-auto bg-blue-700 text-white px-1 py-0 rounded-lg hover:bg-purple-600 transition-colors duration-300 text-center"
      >
        ApiDoc
      </Link>
    </div>
  </div>
  );
};

export default PlanCard;
