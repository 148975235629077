import React, {useState, useEffect,useCallback} from 'react'
import { DivTag } from '../../../Components/Tags/Tags'
import Header from '../../../Components/Header'
import Seo from '../../../Components/SeoComponent/Seo'
import { HeadTag, InputField } from '../../../Components/Tags/Tags'
import { showSuccessToast, showErrorToast,showInfoToast,showWarningToast } from '../../../utils/toastUtils'
import GaugeChart from 'react-gauge-chart'
import { AddHighMarkData } from '../../../Services/EquifaxCScore/highmark'
import {fetchCityService, fetchEquifaxEQCodeService, fetchProfile, fetchStateService } from '../../../Services/EquifaxCScore/equifax'
import ReportModal from './ReportModal'
import html2pdf from 'html2pdf.js';
import { CreditData2 } from './data2'
import Loader from '../../../Loader'

const HighmarkCrScore = () => {

const [cusName, setCusName] = useState();
const [dob, setDob] = useState('');
const [mobile, setMobile] = useState('');
const [aadharNo, setAadharNo] = useState('');
const [panNo, setPanNo] = useState('');
const [voterID, setVoterId] = useState('');
const [rationCard, setRationCard] = useState('');
const [drivingLicence, setDrivingLicence] = useState('');
const [passport, setPassport] = useState('');
const [others, setOthers] = useState('');
const [address, setAddress] = useState('');
const [addressType, setAddressType] = useState([]);
const [state, setState] = useState('');
const [city, setCity] = useState('');
const [pinCode, setPinCode] = useState('');
const [nominee, setNominee] = useState('');
const [selectGender, setSelectGender] = useState('');
const [selectRelation, setSelectRelation] = useState('');
const [enquiryPurpose, setEnquiryPurpose] = useState('');
const [enquiryStage, setEnquiryStage] = useState('');
const [errors, setErrors] = useState({});
const [isLoading, setIsLoading] = useState(false);
const [creditScore, setCreditScore] = useState('');  
const [isModalOpen, setModalOpen] = useState(false);
const [stateList, setStateList] = useState([]);
const [cityList, setCityList] = useState([]);
const [enquiryList, setEnquiryList] = useState([]);
const [profileData, setProfileData] = useState([]); 
const [highmarkResponseData, setHighmarkResponseData] = useState('');
const [submittedName, setSubmittedName] = useState('');
const [submittedMobile, setSubmittedMobile] = useState('');
const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState({ state: false, city: false });
  const [error, setError] = useState({ state: null, city: null });

const maxScore = 900;

// Validate Form Input

const validateForm = () => {
    const newErrors = {};
    if (!cusName) newErrors.cusName = 'Cus Name is required.';
    if (!dob) newErrors.dob = 'DOB is required.';
    if (!mobile) newErrors.mobile = 'Mobile is required.' ;
    if (!address) newErrors.address = 'Address is required.';
    if (!addressType) newErrors.addressType = 'Address Type is required';
    if (!city) newErrors.city = 'City is required';
    if (!state) newErrors.state = 'State is required.';
    if (!pinCode) newErrors.pinCode = 'Pin Code is required.';
    if (!nominee) newErrors.nominee = 'Nominee is required.';
    if (!selectRelation) newErrors.selectRelation = 'Relation is required.';
    if (!enquiryPurpose) newErrors.enquiryPurpose = 'Enquiry Purpose is required';
    if (!selectGender) newErrors.selectGender = 'Gender is required';

    // Check if at least one of the ID fields is filled, otherwise show a common error
    if (
        !aadharNo && 
        !panNo && 
        !voterID && 
        !rationCard && 
        !drivingLicence && 
        !passport &&
        !others
    ) {
        newErrors.identification = 'At least one ID is required (Aadhar No., Pan No., Voter ID, Ration Card, Driving Licence, Passport, Others).';
    }

    setErrors(newErrors);
    // Return true if no errors, false if there are errors
    return Object.keys(newErrors).length === 0;
};

const handleDateChange = (event) => {
  const dateValue = event.target.value;
  setDob(dateValue);
};
   
const handlesubmit = async () => {
  try {
      // Validate the form first
      const isValid = validateForm();
      if (!isValid) {
          showErrorToast('Please correct the form errors.');
          return; // Exit if validation fails
      }
      // Call the AddEquifax function to submit data
      setIsLoading(true);
      await AddHighmark();
      
      setTimeout(() => {
        // Hide the loader after 1 second
        setIsLoading(false);
    }, 2000);
  } catch (error) {
    console.error('Error submitting data:', error);
    showErrorToast('Validate the Required Field');
    setIsLoading(false); // Hide loader in case of error
  }
};

const handleDownloadPDF = () => {
  setTimeout(() => {
    const element = document.getElementById("CreditReport");

    if (element) {
      element.classList.remove('hidden');
      const clonedElement = element.cloneNode(true);
      clonedElement.style.maxHeight = 'none';
      clonedElement.style.overflow = 'visible';
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.appendChild(clonedElement);
      document.body.appendChild(tempDiv);
      html2pdf()
        .from(clonedElement)
        .set({
          margin: [0.2, 0.2, 0.2, 0.2], 
          filename: 'Credit_Report.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, backgroundColor: 'white' }, 
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        })
        .save()
        .then(() => {
          document.body.removeChild(tempDiv);
          element.classList.add('hidden');
        })
        .catch(() => {
          showErrorToast("Failed to generate PDF");
          element.classList.add('hidden');
        });
    } else {
      showErrorToast("No data found to download");
    }
  }, 10);
};

const getPaymentStatusColor = (status) => {
  if (!status) return "bg-gray-500"; // Default for missing status

  switch (status.trim().toUpperCase()) {
    case "STD":  // Current Account (000)
      return "bg-green-500"; // Green for current account
    case "XXX":  // 90+ days past due (90+, 120+)
      return "bg-red-500";   // Red for overdue
    case "NEW":  // New account (NEW)
      return "bg-blue-500"; // Blue for new account
    case "SUB":  // 1-29/30-59 days past due (01+, 30+)
      return "bg-[#b2951cd9]"; // Custom color for substandard
    case "SMA":  // 60-89 days past due (60+)
      return "bg-orange-500"; // Orange for substandard
    case "LSS":  // Restructured (RES, RNC)
      return "bg-purple-500"; // Purple for restructured
    case "INV":  // Invoked (INV), 360+ days overdue
      return "bg-darkred"; // Custom dark red for invoked
    case "CLSD":  // Closed Account (CLSD)
      return "bg-gray-500"; // Gray for closed account
    default:
      return "bg-gray-500";  // Default for other cases
  }
};

const idTypeMap = {
  "ID07": "PAN :",
  "ID03": "Aadhaar :",
  "ID02": "Voter ID :",
  "ID05": "Ration Card :",
  "ID06": "Driving Licence :",
  "ID01": "Passport",
  "ID04": "Others",
  "ID012": "ID 012",
  "ID013": "ID 013"
};
  
// Add HighMark Data
async function AddHighmark() {
  try {
    const dobdate = new Date(dob).toLocaleDateString('en-CA'); // Format DOB to 'YYYY-MM-DD'
    setIsLoading(true);

    const formData = {
      api_id: profileData.user_id || "",  
      api_key: profileData.api_key || "",
      token_id: profileData.token_id || "",
      applicant_name: cusName ? [cusName] : [],  
      dob: dobdate || "",

      // Phones array (Ensure number is provided)
      phones: Array.isArray(mobile) && mobile[0]?.number
        ? [{ type: "MOB", number: mobile[0].number }]
        : [],

        ids: [
          panNo && { type: "ID07", value: panNo },
          aadharNo && { type: "ID03", value: aadharNo },
          voterID && { type: "ID02", value: voterID },
          rationCard && { type: "ID05", value: rationCard },
          drivingLicence && { type: "ID06", value: drivingLicence },
          passport && { type: "ID01", value: passport },
          others && { type: "ID04", value: others },
          { type: "ID012", value: ""},
          { type: "ID013", value: ""},
        ].filter(Boolean),  // Remove any undefined values
  

      // Address object (Ensure all fields are populated)
      address: {
        type: addressType || "",  
        address_1: address || "", 
        city: city || "",          
        state: state || "",        
        pin: pinCode || ""     
      },

      loan_details: enquiryPurpose && enquiryStage
        ? {
            inquiry_unique_ref_no: "1234567890",  // Provide a valid reference number
            cred_rpt_id: null,                    // Set as null
            cred_req_typ: "INDV",                 // Individual credit request type
            cred_rpt_trn_id: "1234567890TRN",     // Provide a valid transaction ID
            cred_inq_purps_typ: enquiryPurpose,   // Inquiry purpose type
            credit_inquiry_stage: enquiryStage,   // Credit inquiry stage
            loan_amount: 500000,                  // Provide a valid loan amount or default
            mbr_id: "MEMBER12345",                // Provide a valid member ID
            branch_id: "BRANCH12345",             // Provide a valid branch ID
            los_app_id: "LOS12345"                // Provide a valid loan application ID
          }
        : {
            inquiry_unique_ref_no: "MissingRefNo",  // Default values
            cred_rpt_trn_id: "MissingTrnID",
            loan_amount: 500000  // Default loan amount
          },

      // Other details if available
      aadhar_no: aadharNo || "",
      pan_no: panNo || "",
      voter_id: voterID || "",
      ration_no: rationCard || "",
      driving_licence: drivingLicence || "",
      passport: passport || "",
      nominee: nominee || "",
      Gender: selectGender || "",
    };

//console.log(formData)

    // Call the service and pass the formData
    const response = await AddHighMarkData(formData);

    if(response.status.code===422){
        showErrorToast(response.errors);
    }
   // console.log("frm",formData);
   // console.log(response);
 
if(response.status.code===200){
  setHighmarkResponseData(response.data);
   const creditScore = response?.data?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"];
   if (creditScore) {
     setCreditScore(creditScore);
   } 
   setSubmittedName(cusName);
 
   // Clear the form or reset necessary state variables (optional)
   setCusName('');
   setDob('');
   setMobile('');
   setAadharNo('');
   setPanNo('');
   setVoterId('');
   setRationCard('');
   setDrivingLicence('');
   setPassport('');
   setOthers('');
   setAddress('');
   setAddressType('');
   setState('');
   setCity('');
   setPinCode('');
   setNominee('');
   setSelectRelation('');
   setEnquiryPurpose('');
   setSelectGender('')
   setIsLoading(false);
   setShowLoader(true);
      showSuccessToast(response.message);
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);
      return;
};
    // Process the Highmark response data
    showErrorToast(response?.data?.message || "The token id field is required");
    setIsLoading(false);
    return;
  } catch (error) {
    console.error("Error in Highmark:", error);
    setIsLoading(false);
    showErrorToast("An unexpected error occurred.");
  }
}
   // ENd

  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await fetchProfile(); // Your API call for profile
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data', error); } }
    fetchProfileData();
  }, []);



 // Fetch States
 const fetchStates = useCallback(async () => {
  setLoading((prev) => ({ ...prev, state: true }));
  try {
    const response = await fetchStateService(); // Service function to fetch states
    setStateList(response.data);
    setError((prev) => ({ ...prev, state: null }));
  } catch (err) {
    setError((prev) => ({ ...prev, state: 'Failed to fetch states' }));
  } finally {
    setLoading((prev) => ({ ...prev, state: false }));
  }
}, []);



// Fetch Cities
const fetchCities = useCallback(async () => {
  if (!state) return;
  setLoading((prev) => ({ ...prev, city: true }));
  try {
    const response = await fetchCityService({ stateId: state }); // Service function to fetch cities
    setCityList(response.data);
    setError((prev) => ({ ...prev, city: null }));
  } catch (err) {
    setError((prev) => ({ ...prev, city: 'Failed to fetch cities' }));
  } finally {
    setLoading((prev) => ({ ...prev, city: false }));
  }
}, [state]);

useEffect(() => {
  fetchStates();
}, [fetchStates]);

useEffect(() => {
  fetchCities();
}, [fetchCities, state]);



// Fetch Enquiry List
const fetchenquiry = async () => {
  try {
    const response = await fetchEquifaxEQCodeService();
    setEnquiryList(response.data);
  } catch (error) {
    console.error("Error in Fetching", error);
  }
}
useEffect(() => {
  fetchenquiry();
},[])
// ENd


const responses = highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"];
const responseArray = Array.isArray(responses) ? responses : [responses];


  return (
    <>
    <Seo />
    {showLoader && <Loader />}
    
      <Header heading='HighMark Cr Score' />
      <DivTag className="container grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-1 md:gap-2 p-2 bg-white  md:mt-1 mt-0 shadow-blue-600 rounded-md">

      <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Customer Name<strong className='text-red-700'>*</strong></HeadTag>
            <input 
        type='text'
        id="cus_name"
        name="cus_name"
        value={cusName}
        className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
        placeholder="Enter Customer Name"
        onChange={(e) => setCusName(e.target.value)}
        />
         {errors.cusName && <p className="text-red-600 text-sm font-mono">{errors.cusName}</p>}
        </DivTag>

        <DivTag className="flex flex-col">
    <HeadTag className="text-md font-serif">  
        D.O.B <strong className='text-red-700'>*</strong>
    </HeadTag>
    <input
      type="date"
      name="dob"
      id="dob"
      value={dob}
      onChange={handleDateChange}
      className="w-full border border-blue-600 rounded-md py-1"
    />
    
    {errors.dob && <p className="text-red-600 text-sm font-mono">{errors.dob}</p>}
</DivTag>

        <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Mobile <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Mobile"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="mobile"
              id="mobile"
              onChange={(e) => setMobile(e.target.value)}
            />
             {errors.mobile && <p className="text-red-600 text-sm font-mono">{errors.mobile}</p>}
          </DivTag>
      
          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Aadhar No.<strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Aadhar No."
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="aadhar_no"
              id="aadhar_no"
              onChange={(e) => setAadharNo(e.target.value)}
            />
             {/* {errors.aadharNo && <p className="text-red-600 text-sm font-mono">{errors.aadharNo}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Pan No. <strong className='text-red-700'>*</strong></HeadTag>           
             <InputField
              type="text"
              placeholder="Enter Pan"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="pan_no"
              id="pan_no"
              onChange={(e) => setPanNo(e.target.value)}
            />
             {/* {errors.panNo && <p className="text-red-600 text-sm font-mono">{errors.panNo}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Voter ID <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Voter ID"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="voter_id"
              id="voter_id"
              onChange={(e) => setVoterId(e.target.value)}
            />
             {/* {errors.voterID && <p className="text-red-600 text-sm font-mono">{errors.voterID}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif" >Ration Card No<strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Ration Card No"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="ration_no"
              id="ration_no"
              onChange={(e) => setRationCard(e.target.value)}
            />
             {/* {errors.rationCard && <p className="text-red-600 text-sm font-mono">{errors.rationCard}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Driving Licence <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Driving Licence"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="driving_licence"
              id="driving_licence"
              onChange={(e) => setDrivingLicence(e.target.value)}
            />
             {/* {errors.drivingLicence && <p className="text-red-600 text-sm font-mono">{errors.drivingLicence}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Passport <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter Passport"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="passport"
              id="passport"
              onChange={(e) => setPassport(e.target.value)}
            />
             {/* {errors.passport && <p className="text-red-600 text-sm font-mono">{errors.passport}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Others Document <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Other Document"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="others"
              id="others"
              onChange={(e) => setOthers(e.target.value)}
            />
             {/* {errors.passport && <p className="text-red-600 text-sm font-mono">{errors.passport}</p>} */}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Address <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter address"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="address"
              id="address"
              onChange={(e) => setAddress(e.target.value)}  
            />
         {errors.address && <p className="text-red-600 text-sm font-mono">{errors.address}</p>}
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">Address Type<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="address_type"
            id="address_type"
            onChange={(e) => setAddressType(e.target.value)}
             >
              <option value="" disabled selected>select address Type</option>
            <option value="D01">Residence</option>
            <option value="D02">Company</option>
            <option value="D03">Res Cum Off</option>
            <option value="D04">Permanent</option>
            <option value="D05">Current</option>
            <option value="D06">Foreign</option>
            <option value="D07">Military</option>
            <option value="D08">Other</option>
            <option value="D12">Mortgage property address</option>
         </select>
         {errors.addressType && <p className="text-red-600 text-sm font-mono">{errors.addressType}</p>}
          </DivTag>



          <DivTag className="flex flex-col">
  <label htmlFor="stateSelect" className="text-md font-serif">
    Select State <strong className="text-red-700">*</strong>
  </label>
  <select
    className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
    name="state"
    id="stateSelect"
    value={state || ""}
    onChange={(e) => setState(e.target.value)}
  >
    <option value="" disabled>Select state</option>
    {stateList && stateList.length > 0 ? (
      stateList.map((item, index) => (
        <option value={item.abbreviation} key={index}>
          {item.state_name}
        </option>
      ))
    ) : (
      <option disabled>Loading states...</option>
    )}
  </select>
</DivTag>

<DivTag className="flex flex-col">
  <label htmlFor="citySelect" className="text-md font-serif">
    Select City <strong className="text-red-700">*</strong>
  </label>
  <select
    className="md:w-full p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-500"
    name="city"
    id="citySelect"
    value={city || ""}
    onChange={(e) => setCity(e.target.value)}
    disabled={!state} // Disable if no state is selected
  >
    <option value="" disabled>Select city</option>
    {cityList && cityList.length > 0 ? (
      cityList.map((item, index) => (
        <option value={item.city_name} key={index}>
          {item.city_name}
        </option>
      ))
    ) : (
      <option disabled>{state ? "Loading cities..." : "Select a state first"}</option>
    )}
  </select>
</DivTag>



          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Pin-Code <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter pin code"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="pin code"
              id="pin code"
              onChange={(e) => setPinCode(e.target.value)}
            />
              {errors.pinCode && <p className="text-red-600 text-sm font-mono">{errors.pinCode}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Nominee <strong className='text-red-700'>*</strong></HeadTag>
            <InputField
              type="text"
              placeholder="Enter nominee"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="nominee"
              id="nominee"
              onChange={(e) => setNominee(e.target.value)}
            />
             {errors.nominee && <p className="text-red-600 text-sm font-mono">{errors.nominee}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Select Gender<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 rounded text-center shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_Gender"
            id="select_Gender"
            onChange={(e) => setSelectGender(e.target.value)}
             >
            <option value="" disabled selected>Select Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
            </select>
            {errors.selectGender && <p className="text-red-600 text-sm font-mono">{errors.selectGender}</p>}
          </DivTag>

          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif"> Select Relation<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 text-center rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_relation"
            id="select_relation"
            onChange={(e) => setSelectRelation(e.target.value)}
             >
            <option value="" disabled selected>Select Relation</option>
            <option value="F">Father</option>
            <option value="H">Husband</option>
            <option value="B">Brother</option>
            <option value="S">Son</option> 
            <option value="U">Son In Law</option>
            <option value="Y">Father In Law</option>
            <option value="O">Other</option> 
            <option value="M">Mother</option>                      
            <option value="W">Wife</option>
            <option value="C">Sister</option>
            <option value="D">Daughter</option>
            <option value="V">Daughter-In-Law</option>
            <option value="Z">Mother-In-Law</option>
            <option value="X">Sister-In Law</option>
            </select>
            {errors.selectRelation && <p className="text-red-600 text-sm font-mono">{errors.selectRelation}</p>}
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">Enquiry Purpose<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 text-center rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="select_relation"
            id="select_relation"
            onChange={(e) => setEnquiryPurpose(e.target.value)}
             >
            <option value="" disabled selected>Enquiry Purpose</option>
            <option value="ACCT-ORIG">ACCT-ORIG</option>
            <option value="ACCT-MAINT">ACCT-MAINT</option>
            <option value="OTHER">OTHER</option>
            </select>
            {errors.enquiryPurpose && <p className="text-red-600 text-sm font-mono">{errors.enquiryPurpose}</p>}
          </DivTag>


          <DivTag className="flex flex-col">
            <HeadTag className="text-md font-serif">Enquiry Stage<strong className='text-red-700'>*</strong></HeadTag>
            <select
            className="md:w-full p-1 text-center rounded shadow-lg shadow-blue-500/50 border border-blue-500"
            name="enquiry_stage"
            id="enquiry_stage"
            onChange={(e) => setEnquiryStage(e.target.value)}
             >
            <option value="" disabled selected>Enquiry Stage</option>
            <option value="PRE-DISB">ACCT-ORIG</option>
            <option value="PRE-SCREEN">ACCT-MAINT</option>
            <option value="UW-REVIEW">UW-REVIEW</option>
            <option value="COLLECTION">COLLECTION</option>
            <option value="RENEWAL">RENEWAL</option>
            </select>
            {errors.enquiryStage && <p className="text-red-600 text-sm font-mono">{errors.enquiryStage}</p>}
          </DivTag>

        {/* <p>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"]}</p> */}
        
        </DivTag>
        <div className="flex justify-center">
            <button className='rounded-md p-1  bg-blue-700 text-white mt-2 mb-1'
            onClick={handlesubmit}
            >Submit</button>
        </div>

<div className='flex justify-center'>
        <div class="flex flex-col items-center bg-gray-200 rounded-md shadow-lg shadow-blue-800 md:w-3/4 w-full p-1">
  <h1 class="font-bold text-2xl text-red-600 underline mb-2">Instructions</h1>
  <h2 class="text-xl font-bold text-center text-gray-800 mb-2 underline">How to Best Credit Score & Financial Report?</h2>
  <p class="text-center text-gray-600 mb-4 font-semibold md:whitespace-nowrap ">
    Every individual or company is entitled to check their CIBIL score. Follow the steps below to get a better report:
  </p>
  <ul class="list-decimal list-inside text-left text-gray-700">
    <li>Enter the correct details to get the credit score.</li>
    <li>Minimum one valid ID number is required.</li>
    <li>Customer details are required to get the report.</li>
    <li>Credit Report may vary based on the identity proof provided.</li>
  </ul>
</div></div>



        <div className='w-full text-center'>
        {errors.identification && <p className='text-red-600 text-sm font-mono'>{errors.identification}</p>}
        </div>
      
        { 
      highmarkResponseData === "" ?  
    "" : 
        <div class="flex flex-col p-4 bg-white shadow-inner md:mt-1 shadow-blue-600 rounded-md">
        <span className='text-center font-bold md:text-xl'>Hey {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"].length===0? submittedName: highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"]}!</span>
  <h2 class="text-xl md:text-xl font-semibold text-center">Your Highmark Credit Score</h2>

    <div class="flex flex-col md:flex-row justify-center md:justify-center gap-4 md:gap-8 md:mt-2 mt-3 relative">
    <div class="w-full md:w-[400px] relative md:mt-10">
      
    <span class="text-red-500 md:font-bold absolute md:top-[20px] md:left-10 md:mt-16 -rotate-70
    top-[60px] left-5 
    ">Poor</span>
    <span class="text-yellow-400 md:font-bold absolute md:left-[104px] md:mt-1 -rotate-26
    left-[69px] top-[-7px] 
    ">Average</span>
    <span class="text-orange-600 md:font-bold absolute md:left-60 md:mt-0 md:rotate-20
    left-[12rem] mt-[-9px] rotate-20
    ">Good</span>
    <span class="text-green-800 md:font-bold absolute md:top-[20px] md:ml-[19.5rem] md:mt-16 rotate-70
    ml-[16rem] mt-[3.5rem]
    ">Excellent</span>

      <GaugeChart 
        id="gauge-chart1" 
        nrOfLevels={3} 
        percent={creditScore / maxScore}  // Normalized value for gauge
        needleColor={"#00008b"}
        textColor={"transparent"}  // Hides the percentage text
        needleBaseColor={"red"}
        arcsLength={[0.3, 0.3, 0.3, 0.3]} 
        colors={['#cc0000','#e6e600','#cc6600','#009900']}
      />
      <p class="text-black font-bold text-xl md:text-2xl absolute bottom-12 md:bottom-[5.5rem] 
      left-1/2 transform -translate-x-1/2"> 
        {creditScore}
      </p>
    </div>
   
    <div class="flex flex-col items-center md:items-start">
      <ul class="text-left text-sm md:text-base space-y-1 mt-4">
     
        <li><span class="font-bold">No of Accounts:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</li>
        <li><span class="font-bold">Active Accounts:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</li>
        <li><span class="font-bold">Total Balance Amount:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</li>
        <li><span class="font-bold">Total Pass Due:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[5]?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</li>
        <li><span class="font-bold">Recent Account:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
        {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</li>
        <li className=''><span class="font-bold">Oldest Account:</span>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
        {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}
        </li>
      </ul>
      <ReportModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}  highmarkResponseData={highmarkResponseData} submittedName={submittedName} submittedMobile={submittedMobile}/>
      <div class="flex space-x-2 mt-4">
        <button 
          onClick={() => setModalOpen(true)} 
          class="bg-blue-600 text-white text-sm md:text-base rounded-md px-2 py-1">
          View
        </button>
        <button 
          class="bg-red-600 text-white text-sm md:text-base rounded-md px-2 py-1"
          onClick={handleDownloadPDF}
          >
          Download
        </button>
      </div>
    </div>
    
  </div>

        </div>
    }  

<>
<div id="CreditReport" className="hidden bg-white rounded-lg shadow-lg w-full md:max-w-3xl overflow-y-auto max-h-[100vh]">
<div className="a4-page">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                        <div className="col-span-2">
                            <img 
                                src="https://cdn.worldvectorlogo.com/logos/highmark-1.svg"
                                className="w-24 p-1"
                                alt="Company Logo"
                            />
                        </div>
                        <div className="col-span-8 md:text-center text-center">
                            <h4 className="text-2xl font-bold">Credit Report</h4>
                        </div>
                        <div className="col-span-2 md:text-end text-end">
                        Consumer Credit Score: <strong className="text-xl">
                        { highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]?.[0]?.["SCORE-VALUE"]}
                        </strong>
                        </div>
                    </div>

                    <div className="flex justify-between items-start flex-wrap">
  {/* Start section */}
  <div className="">
    <p><i>Client ID:</i> <span className="client_id">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["BATCH-ID"]}</span></p>
    <p><i>Report Order No:</i> <span className="report_order_no">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["REPORT-ID"]}</span></p>
  </div>

  {/* End section */}
  <div className="text-right">
    <p><i>Date:</i> <span className="report_date">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["HEADER"]?.["DATE-OF-REQUEST"]}</span></p>
    {/* <p><i>Time:</i> <span className="report_time">{highmarkResponseData?.InquiryResponseHeader?.Time}</span></p> */}
  </div>
</div>

<div className='flex justify-center'>
        <p className='font-bold text-white'>Personal Details</p>
    </div>
<div className="border border-blue-500 p-2 rounded-md">
   
  <div className="grid grid-cols-2 md:grid-cols-2  gap-1">
    {/* Consumer Name */}
    <div>
    <p><i>Consumer Name</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"].length===0? submittedName: highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["NAME"]}</span></p>
      {/* <p><i>Consumer Name</i><span> : {submittedName}</span></p> */}
    </div>
    <div>
      <p><i>D.o.b</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["DOB"]}</span></p>
    </div>
    <div>
      <p><i>Age</i><span> : {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["AGE"]}</span></p>
    </div>
    <div>
      <p><i>Gender</i><span> : ""</span></p>
    </div>
    <div>
      <tbody>
  {(() => {
    const phoneVariations = highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["PERSONAL-INFO-VARIATION"]?.["PHONE-NUMBER-VARIATIONS"]?.["VARIATION"];

    // Check if phoneVariations is an array and has elements
    if (Array.isArray(phoneVariations) && phoneVariations.length > 0) {
      return phoneVariations.map((phoneDetail, index) => (
        <tr key={index}>
          <td className="px-2 py-1 text-sm">Mob {index + 1} : {phoneDetail?.["VALUE"]}</td>
        </tr>
      ));
    } 
    // Check if phoneVariations is a single object
    else if (phoneVariations && typeof phoneVariations === 'object') {
      return (
        <tr>
          <td className="px-2 py-1 text-sm">Mob 1 : {phoneVariations?.["VALUE"]}</td>
        </tr>
      );
    } 
    // If no phone variations are found
    else {
      return (
        <tr>
          <td colSpan={3} className="border px-2 py-1 text-sm text-center">No phone number variations found.</td>
        </tr>
      );
    }
  })()}
</tbody>

    </div>


{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"] && (
  <table className="table-auto w-full">
    <tbody>
      {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]) ? (
        highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.map((idDetail, index) => (
          <tr key={index}>
            <td className=" px-2 py-1 text-sm">{idTypeMap[idDetail?.["TYPE"]] || idDetail?.["TYPE"]}</td> {/* ID Type mapped to friendly name */}
            <td className=" px-2 py-1 text-sm">{idDetail?.["VALUE"]}</td> {/* ID Value */}
          </tr>
        ))
      ) : (
        <tr>
          <td className=" px-2 py-1 text-sm">{idTypeMap[highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.["TYPE"]] || highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.["TYPE"]}</td>
          <td className=" px-2 py-1 text-sm">{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["REQUEST"]?.["IDS"]?.["ID"]?.["VALUE"]}</td>
        </tr>
      )}
    </tbody>
  </table>
)}

  </div>
</div>

                    <hr className="my-2" />
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2 rounded-tr-xl rounded-tl-xl">Address</div>
                    <table className="min-w-full table-auto border border-black text-left ">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-2 py-1">Sn</th>
      <th className="border border-black px-2 py-1">Address</th>
      <th className="border border-black px-2 py-1">Reported Date</th>
    </tr>
  </thead>
  <tbody className="address-info text-sm">
  {(() => {
    const variations = highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["PERSONAL-INFO-VARIATION"]?.["ADDRESS-VARIATIONS"]?.["VARIATION"];
    
    // Check if variations is an array
    if (Array.isArray(variations) && variations.length > 0) {
      return variations.map((address, index) => (
        <tr key={index}>
          <td className="border border-black px-1 font-bold text-xs">Address : {index + 1}</td> 
          <td className="border border-black px-1">{address?.VALUE}</td> 
          <td className="border border-black px-1">{address?.["REPORTED-DATE"]}</td> 
        </tr>
      ));
    } 
    // Check if variations is a single object
    else if (variations && typeof variations === 'object') {
      return (
        <tr>
          <td className="border border-black px-1 font-bold text-xs">Address : 1</td>
          <td className="border border-black px-1">{variations?.VALUE}</td>
          <td className="border border-black px-1">{variations?.["REPORTED-DATE"]}</td>
        </tr>
      );
    } 
    // If there are no variations found
    else {
      return (
        <tr>
          <td colSpan="3" className="border border-black px-2 py-1 text-center">No address variations found</td>
        </tr>
      );
    }
  })()}
</tbody>

</table>

                   {/* Credit Score Section */}
                <div className=" bg-blue-900 md:text-white p-2 text-white mt-2 rounded-tr-xl rounded-tl-xl">HighMark Score</div>
                    <table className="min-w-full table-auto border border-black text-left">
                      <thead className="bg-gray-200">
                       <tr className='text-left'>
                         <th className="border border-black px-2 py-1">Score Name</th>
                         <th className="border border-black px-2 py-1">Score</th>
                         <th className="border border-black px-2 py-1">Scoring Elements</th>
                       </tr>
                      </thead>
                   <tbody className="scoreDetailsInfo">
                    {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["SCORES"]?.["SCORE"]) ? (
                     highmarkResponseData["INDV-REPORTS"]["INDV-REPORT"]["SCORES"]["SCORE"].map((scoreDetail, index) => (
                     <tr key={index} className=''>
                      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-TYPE"]}</td>
                      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-VALUE"]}</td>
                      <td className="border border-black px-2 py-1">{scoreDetail?.["SCORE-FACTORS"] || scoreDetail?.["SCORE-COMMENTS"]}</td>
                     </tr>
                      ))
                     ) : (
                    <tr>
                  <td colSpan="3" className="text-center">No scores available</td>
                    </tr>
                      )}
                  </tbody>
                 </table>

            {/* Accounts Section */}
           <div className=" bg-blue-900 md:text-white text-white p-2 mt-4 rounded-tr-xl rounded-tl-xl">Account Details</div>
                {/* 1st Retail */}
               <table className="min-w-full table-auto text-left mt-2 border-collapse">
      <tbody className="mb-3">
        {responseArray.map((response, index) => (
          <React.Fragment key={index}>
            {response?.["LOAN-DETAILS"] && (
              <>
                <tr className="bg-blue-800 md:text-white text-white">
                  <td colSpan={6} className="px-2 py-1 border rounded-tr-md rounded-tl-md">
                    RETAIL: {response?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                  </td>
                </tr>

                <tr className="border">
                  <td colSpan={6} className="px-2 py-1 border ">
                    <div className="grid grid-cols-3 gap-2">
                      <div><span className="font-semibold">Sanction Amount:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-AMT"]}</div>
                      <div><span className="font-semibold">Balance:</span> {response?.["LOAN-DETAILS"]?.["CURRENT-BAL"]}</div>
                      <div><span className="font-semibold">OverDue:</span> {response?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</div>
                      <div><span className="font-semibold">Account Status:</span> {response?.["LOAN-DETAILS"]?.["ACCOUNT-STATUS"]}</div>
                      <div><span className="font-semibold">Date Opened:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</div>
                    </div>
                  </td>
                </tr>

                {/* Detailed Information */}
                <tr>
                  <td colSpan={6} className="px-2 py-1 border-t">
                    <div className="grid grid-cols-3 gap-2">
                      <div><span className='font-semibold'>Account Number:</span> {response?.["LOAN-DETAILS"]?.["ACCT-NUMBER"]}</div>
                      <div><span className='font-semibold'>Institution:</span>{response?.[""] || "N/A"}</div>


                      {/* <div><span className='font-semibold'>Account Type:</span> {response?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}</div> */}


                      <div><span className='font-semibold'>Ownership Type:</span> {response?.["LOAN-DETAILS"]?.["OWNERSHIP-IND"]}</div>
                      <div><span className='font-semibold'>Balance:</span> {response?.["LOAN-DETAILS"]?.["CURRENT-BAL"]}</div>
                      <div><span className='font-semibold'>Open:</span></div>
                      <div><span className='font-semibold'>Sanction Amount:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-AMT"]}</div>
                      <div><span className='font-semibold'>Source:</span> {response?.["LOAN-DETAILS"]?.["OWNERSHIP-IND"]}</div>
                      <div><span className='font-semibold'>Interest Rate:</span> N/A</div>
                      <div><span className='font-semibold'>Repayment Tenure:</span>{response?.["LOAN-DETAILS"]?.["ORIGINAL-TERM"]}</div>
                      <div><span className='font-semibold'>Installment Amount:</span> {response?.["LOAN-DETAILS"]?.["INSTALLMENT-AMT"]?.split('/')[0]}</div>
                      <div><span className='font-semibold'>Term Frequency:</span> {response?.["LOAN-DETAILS"]?.["INSTALLMENT-AMT"]?.split('/')[1]}</div>
                     
                      <div><span className='font-semibold'>Date Opened:</span> {response?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</div>
                      
                      <div><span className='font-semibold'>Account Status:</span> {response?.["LOAN-DETAILS"]?.["ACCOUNT-STATUS"]}</div>

                    </div>
                    <div className="grid grid-cols-3 gap-2 border-t">
                      <div><span className='font-semibold'>Date Reported:</span> {response?.["LOAN-DETAILS"]?.["DATE-REPORTED"]}</div>
                      <div><span className='font-semibold'>Dispute Code:</span>{response?.[""]?.[""] || "N/A"} </div>
                      <div><span className='font-semibold'>Asset Classification:</span>{response?.[""]?.[""] || "N/A"}</div>
                      </div>
                  </td>
                </tr>

                {/* Payment History if available */}
                {response?.["LOAN-DETAILS"]?.["COMBINED-PAYMENT-HISTORY"] && (
                  <tr className="my-2">
                    <td colSpan={6} className="px-1 py-0 mb-1">
                      <h2 className="font-semibold">History</h2>
                      <div className="flex flex-wrap mb-1 mt-1 gap-0.5 border border-black p-0.5 rounded-md">
                        <div className='border p-1 border-black rounded-lg items-center text-center'>
                          <p className='text-xs mt-3'>Key Payment Status</p>
                        </div>
                        {Array.isArray(response["LOAN-DETAILS"]["COMBINED-PAYMENT-HISTORY"].split('|')) ? (
                          response["LOAN-DETAILS"]["COMBINED-PAYMENT-HISTORY"].split('|').map((history, idx) => {
                            const [monthYear, status] = history.split(',');
                            const statusCode = status?.split('/')[0]; 
                            return (
                              <div key={idx} className="mx-1 flex flex-col items-center border p-1 border-black rounded-lg">
                                {/* Colored circle */}
                                {/* <span className={`flex items-center justify-center w-6 h-6 text-white text-xs rounded-full ${getPaymentStatusColor(statusCode)}`}>
                                  <p className="mt-[-6px]">{statusCode}</p> 
                                </span> */}

                                <p className="text-xs mt-1">{monthYear}</p>
                                <p className="text-xs">{statusCode}</p> 
                              </div>
                            );
                          })
                        ) : (
                          <div>No payment history available.</div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
 {/* Linked Accounts Section */}
 {response?.["LOAN-DETAILS"]?.["LINKED-ACCOUNTS"]?.["ACCOUNT-DETAILS"] && (
  <tr className="my-2">
    <td colSpan={6} className="px-1 py-0 "> {/* Added border-b-2 for bottom border */}
      <h2 className="font-semibold bg-blue-800 text-white p-2 rounded-tr-xl rounded-tl-xl">Linked A/c Details</h2>
      <div className="flex flex-wrap mt-1"> {/* Added border-b-2 to this div for content separation */}
        {Array.isArray(response["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]) ? (
          response["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"].map((LinkedAC, idx) => (
            <div key={idx} className="grid grid-cols-4 gap-2 mb-2">
              <div><span className="font-semibold">Account Number:</span> {LinkedAC?.["ACCT-NUMBER"]}</div>
              <div><span className="font-semibold">Credit Guarantor:</span> {LinkedAC?.["CREDIT-GUARANTOR"]}</div>
              <div><span className="font-semibold">Account Type:</span> {LinkedAC?.["ACCT-TYPE"]}</div>
              <div><span className="font-semibold">Ownership Type:</span> {LinkedAC?.["OWNERSHIP-IND"]}</div>
              <div><span className="font-semibold">Date Reported:</span> {LinkedAC?.["DATE-REPORTED"]}</div>
              <div><span className="font-semibold">Account Status:</span> {LinkedAC?.["ACCOUNT-STATUS"]}</div>
              <div><span className="font-semibold">Disbursed Amount:</span> ₹{LinkedAC?.["DISBURSED-AMT"]}</div>
              <div><span className="font-semibold">Disbursed Date:</span> {LinkedAC?.["DISBURSED-DATE"]}</div>
              <div><span className="font-semibold">Last Payment Date:</span> {LinkedAC?.["LAST-PAYMENT-DATE"]}</div>
              <div><span className="font-semibold">Overdue Amount:</span> ₹{LinkedAC?.["OVERDUE-AMT"]}</div>
              <div><span className="font-semibold">Current Balance:</span> ₹{LinkedAC?.["CURRENT-BAL"]}</div>
            </div>
          ))
        ) : (
          <div className="grid grid-cols-3 gap-2 mb-2">
            <div><span className="font-semibold">Account Number:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["ACCT-NUMBER"]}</div>
            <div><span className="font-semibold">Credit Guarantor:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["CREDIT-GUARANTOR"]}</div>
            <div><span className="font-semibold">Account Type:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["ACCT-TYPE"]}</div>
            <div><span className="font-semibold">Ownership Type:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["OWNERSHIP-IND"]}</div>
            <div><span className="font-semibold">Date Reported:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["DATE-REPORTED"]}</div>
            <div><span className="font-semibold">Account Status:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["ACCOUNT-STATUS"]}</div>
            <div><span className="font-semibold">Disbursed Amount:</span> ₹{response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["DISBURSED-AMT"]}</div>
            <div><span className="font-semibold">Disbursed Date:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["DISBURSED-DATE"]}</div>
            <div><span className="font-semibold">Last Payment Date:</span> {response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["LAST-PAYMENT-DATE"]}</div>
            <div><span className="font-semibold">Overdue Amount:</span> ₹{response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["OVERDUE-AMT"]}</div>
            <div><span className="font-semibold">Current Balance:</span> ₹{response?.["LOAN-DETAILS"]["LINKED-ACCOUNTS"]["ACCOUNT-DETAILS"]["CURRENT-BAL"]}</div>
          </div>
        )}
      </div>
    </td>
  </tr>
)}


              </>
            )}
          </React.Fragment>
        ))}
      </tbody>
               </table>
                {/* 1st ENd */}



             {/* 2nd Microfinance */}
           <table className="min-w-full table-auto text-left mt-2 border-collapse">
              <tbody className="mb-3">
               {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["INDV-RESPONSES"]?.["INDV-RESPONSE-LIST"]?.["INDV-RESPONSE"]) &&
               highmarkResponseData["INDV-REPORTS"]["INDV-REPORT"]["INDV-RESPONSES"]["INDV-RESPONSE-LIST"]["INDV-RESPONSE"].map((response, index) => (
                 <React.Fragment key={index}>
                 {response?.["LOAN-DETAIL"] && (
                 <>
                   <tr className="bg-blue-800 md:text-white text-white">
                    <td colSpan={6} className="px-2 py-1 border rounded-tr-md rounded-tl-md">
                     MICROFINANCE: {response?.["LOAN-DETAIL"]?.["ACCT-TYPE"]}
                    </td>
                   </tr>

              <tr className="border">
                <td colSpan={6} className="px-2 py-1 border ">
                  <div className="grid grid-cols-3 gap-2">
                    <div><span className="font-semibold">Sanction Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>
                    <div><span className="font-semibold">Balance:</span> {response?.["LOAN-DETAIL"]?.["CURRENT-BAL"]}</div>
                    <div><span className="font-semibold">OverDue:</span> {response?.["LOAN-DETAIL"]?.["OVERDUE-AMT"]}</div>
                    <div><span className="font-semibold">Account Status:</span> {response?.["LOAN-DETAIL"]?.["STATUS"]}</div>
                    <div><span className="font-semibold">Date Opened:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-DT"]}</div>
                  </div>
                </td>
              </tr>
              {/* Detailed Information */}
              <tr>
               <td colSpan={6} className="px-2 py-1 border">
                {/* Grid layout for details */}
                 <div className="grid grid-cols-3 gap-2">
    {/* General Loan Details */}
    <div><span className='font-semibold'>Account Number:</span> {response?.["LOAN-DETAIL"]?.["ACCT-NUMBER"]}</div>
    <div><span className='font-semibold'>Institution:</span> {response?.["MFI"]}</div>
    <div><span className='font-semibold'>Balance:</span> {response?.["LOAN-DETAIL"]?.["CURRENT-BAL"]}</div>
    <div><span className='font-semibold'>Open:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-DT"]}</div>
    <div><span className='font-semibold'>Sanction Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>
    <div><span className='font-semibold'>Disbursed Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>

    <div><span className='font-semibold'>Loan Category:</span> {response?.["LOAN-DETAIL"]?.["ACCT-TYPE"]}</div>
    <div><span className='font-semibold'>Loan Purpose:</span> {response?.[""] || 'N/A'}</div>
    <div><span className='font-semibold'>Loan Cycle:</span>{response?.["LOAN-DETAIL"]?.["LOAN-CYCLE-ID"]}</div>
    <div><span className='font-semibold'>Installment Amount:</span> {response?.["LOAN-DETAIL"]?.["INSTALLMENT-AMT"]}</div>
    <div><span className='font-semibold'>No Of Installment:</span> {response?.[""] || "N/A"}</div>
    <div><span className='font-semibold'>Last Payment Date:</span> {response?.["LOAN-DETAIL"]?.["INFO-AS-ON"]}</div>
    <div><span className='font-semibold'>Date Reported:</span>{response?.["reportDt"] || "N/A"}</div>
    <div><span className='font-semibold'>Date Opened:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-DT"]}</div>
    <div><span className='font-semibold'>Repayment Tenure:</span> {response?.["LOAN-DETAIL"]?.["FREQ"]}</div>
                </div>

  {/* Section: Key Personal Details */}
  <h2 className="font-semibold mt-4 mb-2 border-t">Key Personal Details :</h2>
  <div className="grid grid-cols-2 gap-2">
    <div><span className='font-semibold'>Name:</span> {response?.["NAME"] || "N/A"}</div>
    <div><span className='font-semibold'>Relation Type:</span> {response?.["RELATIONS"]?.["RELATION"]?.[1]?.["TYPE"] || "N/A"}</div>
  </div>

  {/* Section: MFI Details */}
  <h2 className="font-semibold mt-4 mb-2 border-t">MFI Details :</h2>
  <div className="grid grid-cols-2 gap-2">
    <div><span className='font-semibold'>Mfi Client Full Name:</span> {response?.["NAME"] || "N/A"}</div>
    <div><span className='font-semibold'>Gender:</span> {response?.[""]?.[""] || "N/A"}</div>
    <div><span className='font-semibold'>Member ID:</span> {response?.["CNSMRMBRID"]}</div>
    <div><span className='font-semibold'>Sanction Amount:</span> {response?.["LOAN-DETAIL"]?.["DISBURSED-AMT"]}</div>
  </div>

  {/* Section: MFI Address */}
  <h2 className="font-semibold mt-4 mb-2 border-t">MFI Address :</h2>
  <div className="grid grid-cols-2 gap-2">
    <div><span className='font-semibold'>Address:</span> {response?.["ADDRESSES"]?.["ADDRESS"]?.[0] || "N/A"}</div>
    <div><span className='font-semibold'>Date Reported:</span> {response?.["reportDt"] || "N/A"}</div>
  </div>
</td>
</tr>

              {/* Payment History if available */}
              {response?.["LOAN-DETAIL"]?.["COMBINED-PAYMENT-HISTORY"] && (
               <tr className="my-2">
                <td colSpan={6} className="px-1 py-0 mb-1">
                 <h2 className="font-semibold">History</h2>
      
                 {/* Wrapper div that always includes border-bottom and margin-bottom */}
           <div className="flex flex-wrap mb-1 mt-1 gap-0.5 border border-black p-0.5 rounded-md">
        
        {/* Key Payment Status Box */}
        <div className='border p-1 border-black rounded-lg items-center text-center'>
          <p className='text-xs mt-3'>Key Payment Status</p>
        </div>

        {/* Payment History Mapping */}
        {Array.isArray(response?.["LOAN-DETAIL"]?.["COMBINED-PAYMENT-HISTORY"]?.split('|')) ? (
  response["LOAN-DETAIL"]["COMBINED-PAYMENT-HISTORY"].split('|').map((history, idx) => {
    const [monthYear, status] = history.split(','); // Extract the month and year, and status code
    const [month, year] = monthYear?.split(':'); // Separate month and year
    const formattedYear = year ? year.slice(-2) : ''; // Get the last two digits of the year (e.g., "24" for 2024)
    const statusCode = status; // Use the status directly, as we don't need to split it further

    return (
      <div key={idx} className="mx-1 flex flex-col items-center border p-1 border-black rounded-lg">
        <p className="text-xs mt-1">{month}:{formattedYear}</p>
        <p className="text-xs mb-1">{statusCode}</p>
        {/* <span className={`flex items-center justify-center w-6 h-6 text-white text-xs rounded-full ${getPaymentStatusColor2(statusCode)}`}>
          <p className="mt-[-6px]">{statusCode}</p>
        </span> */}
      </div>
    );
  })
) : (
  <div>No payment history available.</div>
)}



      </div>
    </td>
  </tr>
)}

            </>
          )}
        </React.Fragment>
      ))}
  </tbody>
                        </table>
                        {/* ENd Microfinance */}


{/* Color Pic for Retail */}
{/* <div className="flex flex-col space-y-2 p-4">
  <h3 className='font-bold'>Payment Status Legend</h3>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-green-500 mr-2"></div>
    <span>Current Account (000)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-[#b2951cd9] mr-2"></div> 
    <span>1-29/30-59 days past due (01+, 30+)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-orange-500 mr-2"></div>
    <span>60-89 days past due (60+)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-red-500 mr-2"></div>
    <span>90+ days past due (90+, 120+)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-gray-500 mr-2"></div>
    <span>Closed Account (CLSD)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-blue-500 mr-2"></div>
    <span>New Account (NEW), Settled (SET)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-purple-500 mr-2"></div>
    <span>Restructured (RES, RNC)</span>
  </div>
  
  <div className="flex items-center">
    <div className="w-4 h-4 bg-red-900 mr-2"></div> 
    <span>Invoked (INV), 360+ days overdue</span>
  </div>
</div> */}


  {/* Status  Code */}
<div class="p-0">
  <h1 class="text-sm font-bold">Status Codes</h1>
  <div class="overflow-x-auto">
    <table class="w-full border border-black text-left text-sm">
      <thead>
        <tr class="bg-gray-200">
          <th class="border border-black">Code</th>
          <th class="border border-black">Description</th>
        </tr>
      </thead>
      <tbody className='text-xs'>
        <tr><td class="border border-black ">000</td><td class="border border-black ">Current Account</td></tr>
        <tr><td class="border border-black ">01+</td><td class="border border-black">1-29 days past due</td></tr>
        <tr><td class="border border-black ">30+</td><td class="border border-black ">30-59 days past due</td></tr>
        <tr><td class="border border-black ">60+</td><td class="border border-black">60-89 days past due</td></tr>
        <tr><td class="border border-black ">90+</td><td class="border border-black ">90-119 days past due</td></tr>
        <tr><td class="border border-black ">120+</td><td class="border border-black ">120-179 days past due</td></tr>
        <tr><td class="border border-black ">180+</td><td class="border border-black ">180-359 days past due</td></tr>
        <tr><td class="border border-black ">RES</td><td class="border border-black ">Restructured Loan</td></tr>
        <tr><td class="border border-black ">RGM</td><td class="border border-black ">Restructured Loan - Govt Mandate</td></tr>
        <tr><td class="border border-black ">CLSD</td><td class="border border-black ">Closed Account</td></tr>
        <tr><td class="border border-black ">PWOS</td><td class="border border-black ">Post Written Off Settled</td></tr>
        <tr><td class="border border-black ">360+</td><td class="border border-black ">360-539 days past due</td></tr>
        <tr><td class="border border-black ">540+</td><td class="border border-black ">540-719 days past due</td></tr>
        <tr><td class="border border-black ">*</td><td class="border border-black ">Data Not Reported</td></tr>
        <tr><td class="border border-black ">WDF</td><td class="border border-black ">Willful Default</td></tr>
        <tr><td class="border border-black ">720+</td><td class="border border-black ">720+ days past due</td></tr>
        <tr><td class="border border-black ">SF</td><td class="border border-black ">Suit Filed</td></tr>
        <tr><td class="border border-black ">SET</td><td class="border border-black ">Settled</td></tr>
        <tr><td class="border border-black ">INAC</td><td class="border border-black ">Account is Inactive</td></tr>
        <tr><td class="border border-black ">ADJ</td><td class="border border-black ">Adjustment Pending</td></tr>
        <tr><td class="border border-black ">STD</td><td class="border border-black ">Standard</td></tr>
        <tr><td class="border border-black ">LOSS</td><td class="border border-black ">Loss</td></tr>
        <tr><td class="border border-black ">SPM</td><td class="border border-black ">Special Mention</td></tr>
        <tr><td class="border border-black ">RNC</td><td class="border border-black ">Restructured Loan - Natural Calamity</td></tr>
        <tr><td class="border border-black ">LNSB</td><td class="border border-black ">Loan Submitted</td></tr>
        <tr><td class="border border-black ">FPD</td><td class="border border-black ">First Payment Default</td></tr>
        <tr><td class="border border-black ">SUB</td><td class="border border-black ">Sub-standard</td></tr>
        <tr><td class="border border-black ">DBT</td><td class="border border-black ">Doubtful</td></tr>
        <tr><td class="border border-black ">DIS</td><td class="border border-black ">Dispute Resolution Pending</td></tr>
        <tr><td class="border border-black ">LAND</td><td class="border border-black ">Loan Approved, Not Yet Disbursed</td></tr>
        <tr><td class="border border-black ">CUF</td><td class="border border-black ">Closed Account/Unpaid Final Bill</td></tr>
        <tr><td class="border border-black ">DEC</td><td class="border border-black ">Loan Declined</td></tr>
        <tr><td class="border border-black ">WOF</td><td class="border border-black ">Charge Off/Written Off</td></tr>
        <tr><td class="border border-black ">NEW</td><td class="border border-black ">New Account</td></tr>
        <tr><td class="border border-black ">BK</td><td class="border border-black ">Included in Bankruptcy</td></tr>
      </tbody>
    </table>
  </div>
</div>
{/* ENd */}





{/* Inquiry History Section */}
<div className="bg-blue-900 md:text-white text-white p-2 mt-4 rounded-tr-xl rounded-tl-xl">Inquiry History</div>
<table className="min-w-full table-auto text-left mt-2 border-collapse">
  <thead>
    <tr className="bg-gray-200">
      <th className="px-2 py-1 border">Member Name</th>
      <th className="px-2 py-1 border">Inquiry Date</th>
      <th className="px-2 py-1 border">Purpose</th>
      <th className="px-2 py-1 border">Amount</th>
      <th className="px-2 py-1 border">Remarks</th>
    </tr>
  </thead>
  <tbody>
  {Array.isArray(highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["INQUIRY-HISTORY"]?.["HISTORY"]) && 
  highmarkResponseData["INDV-REPORTS"]["INDV-REPORT"]["INQUIRY-HISTORY"]["HISTORY"].map((inquiry, index) => (
    <tr key={index} className="border">
      <td className="border">{inquiry?.["MEMBER-NAME"] || 'N/A'}</td>
      <td className="border">{inquiry?.["INQUIRY-DATE"] || 'N/A'}</td>
      <td className="border">{inquiry?.["PURPOSE"] || 'N/A'}</td>
      <td className="border">{inquiry?.["AMOUNT"] || 'N/A'}</td>
      <td className="border">{Array.isArray(inquiry?.["REMARK"]) && inquiry["REMARK"].length > 0 ? inquiry["REMARK"].join(', ') : 'N/A'}</td>
    </tr>
))}

  </tbody>
</table>


<div className="bg-blue-900 md:text-white text-white p-2 mt-4 rounded-tr-xl rounded-tl-xl">Account Summary</div>
<div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 mt-2">

  {/* Primary Accounts Summary Card */}
  <div className="bg-gray-300 p-2 rounded-lg shadow-lg">
    <h2 className="font-semibold text-sm mb-2 bg-blue-700 rounded-sm text-white text-center">Primary Accounts Summary</h2>
    <div>
      <p className='border-b'><span className="font-semibold">Number of Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Active Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Overdue Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-OVERDUE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><spna className="font-semibold">Secured Accounts: </spna>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SECURED-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><spna className="font-semibold">Unsecured Accounts: </spna>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-UNSECURED-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Current Balance:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</p>
      <p className='border-b'><span className="font-semibold">Sanctioned Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SANCTIONED-AMOUNT"]}</p>
      <p className='border-b'><span className="font-semibold">Disbursed Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-DISBURSED-AMOUNT"]}</p>
    </div>
  </div>

  {/* Secondary Accounts Summary Card */}
  <div className="bg-gray-300  p-2 rounded-lg shadow-lg">
    <h2 className="font-semibold text-sm mb-2 bg-blue-700 rounded-sm text-white text-center">Secondary Accounts Summary</h2>
    <div>
      <p className='border-b'><span className="font-semibold">Number of Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Active Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Overdue Accounts:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-OVERDUE-NUMBER-OF-ACCOUNTS"]}</p>
      <p className='border-b'><span className="font-semibold">Current Balance:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-CURRENT-BALANCE"]}</p>
      <p className='border-b'><span className="font-semibold">Sanctioned Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-SANCTIONED-AMOUNT"]}</p>
      <p className='border-b'><span className="font-semibold">Disbursed Amount:</span> ₹{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["SECONDARY-ACCOUNTS-SUMMARY"]?.["SECONDARY-DISBURSED-AMOUNT"]}</p>
    </div>
  </div>

  {/* Derived Attributes Card */}
  <div className="bg-gray-300  p-2 rounded-lg shadow-lg">
    <h2 className="font-semibold text-sm mb-2 bg-blue-700 rounded-sm text-white text-center">Derived Attributes</h2>
    <div>
      <p className='border-b'><span className="font-semibold">Inquiries in Last 6 Months:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["INQUIRIES-IN-LAST-SIX-MONTHS"]}</p>
      <p className='border-b'><span className="font-semibold">Length of Credit History:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["LENGTH-OF-CREDIT-HISTORY-YEAR"]} Year, {highmarkResponseData?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["LENGTH-OF-CREDIT-HISTORY-MONTH"]} Month</p>
      <p className='border-b'><span className="font-semibold">Average Account Age:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["AVERAGE-ACCOUNT-AGE-YEAR"]} Year, {highmarkResponseData?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["AVERAGE-ACCOUNT-AGE-MONTH"]} Month</p>
      <p className='border-b'><span className="font-semibold">New Accounts in Last 6 Months:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["NEW-ACCOUNTS-IN-LAST-SIX-MONTHS"]}</p>
      <p className='border-b'><span className="font-semibold">New Delinquent Accounts in Last 6 Months:</span> {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["DERIVED-ATTRIBUTES"]?.["NEW-DELINQ-ACCOUNT-IN-LAST-SIX-MONTHS"]}</p>
    </div>
  </div>
</div>


                      {/* Recent Activity Section */}
                    <div className="  bg-blue-900 md:text-white text-white p-2 mt-2 rounded-tr-xl rounded-tl-xl">Recent Activity (Last 90 Days)</div>
                    <table className="min-w-full table-auto border border-black text-left">
  <thead className="bg-gray-200">
    <tr>
      <th className="border border-black px-4 py-2">Total Inquiries</th>
      <th className="border border-black px-4 py-2">Accounts Opened</th>
      <th className="border border-black px-4 py-2">Accounts Updated</th>
      <th className="border border-black px-4 py-2">Accounts Delinquent</th>
    </tr>
  </thead>
  <tbody className="recentActivity">
    <tr className="border border-black text-center">
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
      <td className="border border-black px-4 py-2">{highmarkResponseData?.[""]}</td>
    </tr>
  </tbody>
</table>
                     </div>
                          {/* Summary Section */}
                     <div className="  bg-blue-900 md:text-white text-white p-2 mt-2 rounded-tr-xl rounded-tl-xl">Summary</div>
                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className='border border-black px-2 py-1'>Number of Accounts</th>
                <th className='border border-black px-2 py-1'>Total Balance Amount</th>
                <th className='border border-black px-2 py-1'>Recent Account</th>
              </tr>
            </thead>
            <tbody class="summary1">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-CURRENT-BALANCE"]}</td>
                <td className='border border-black px-2 py-1'>
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[1]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}
                </td>
            </tr>
            </tbody>
                    </table>
                    <table className="min-w-full table-auto border border-black text-left">
                    <thead className="bg-gray-200">
                 <tr>
                <th className="border border-black px-2 py-1">Number of Open Accounts</th>
                <th className="border border-black px-2 py-1">Total Past Due Amount</th>
                <th className="border border-black px-2 py-1">Oldest Account</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-ACTIVE-NUMBER-OF-ACCOUNTS"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[5]?.["LOAN-DETAILS"]?.["OVERDUE-AMT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["ACCT-TYPE"]}
                {highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["RESPONSES"]?.["RESPONSE"]?.[4]?.["LOAN-DETAILS"]?.["DISBURSED-DATE"]}</td>
            </tr>
            </tbody>
                     </table>

                     <table className="min-w-full table-auto border border-black text-left">
                     <thead className="bg-gray-200">
              <tr>
                <th className="border border-black px-2 py-1">Total High Credit</th>
                <th className="border border-black px-2 py-1">Total Sanction Amount</th>
                <th className="border border-black px-2 py-1">Single Highest Sanction</th>
              </tr>
            </thead>
            <tbody class="summary2">
                <tr>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.[""]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.["INDV-REPORTS"]?.["INDV-REPORT"]?.["ACCOUNTS-SUMMARY"]?.["PRIMARY-ACCOUNTS-SUMMARY"]?.["PRIMARY-SANCTIONED-AMOUNT"]}</td>
                <td className='border border-black px-2 py-1'>{highmarkResponseData?.[""]}</td>
            </tr>
            </tbody>
                         </table>
                 </div>
</>

  </>
  )
}

export default HighmarkCrScore















