// Centralized URL configuration for API endpoints

const BASE_URL = 'https://javabackend.idspay.in/api/v1/';  

const API_URLS = {
    BASE_URL,
    ADMIN: BASE_URL + "admin/",
    CUSTOMER: BASE_URL + "customer/"
};

export default API_URLS;  
