import { useEffect, useState } from 'react';
import {logout} from "../Services/authService";

const useInactivityLogout = (timeout = 600000) => { // 10 minute = 600000 ms
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsInactive(true), timeout);
    };

    const handleActivity = () => {
      if (isInactive) {
        setIsInactive(false);
      }
      resetTimer();
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    // Start the initial timer
    resetTimer();

    // Clean up event listeners and timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [isInactive, timeout]);

  useEffect(() => {
    if (isInactive) {
        logout(); // Trigger the logout function from authService
    }
  }, [isInactive]);
};





export default useInactivityLogout;
